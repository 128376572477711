import { format } from "date-fns";

const downloadItem = (item, name) => {
  // Create a temporary URL from the blob
  const url = URL.createObjectURL(item);

  // Create a temporary anchor element
  const downloadLink = Object.assign(document.createElement("a"), {
    href: url,
    download: name,
    style: "display: none",
  });
  // Append the anchor to the document and trigger a click
  document.body.appendChild(downloadLink);
  downloadLink.click();

  // Clean up the URL and the anchor
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(url); // Free up memory
};

export const downloadFile = (blobToDownload, file) => {
  downloadItem(blobToDownload, file.name);
};

export const downloadZip = (blobToDownload, folders, files) => {
  // Format current date and time
  const formattedDate = format(new Date(), "yyyyMMdd'T'HHmmss'_Proxima'");

  // Determine name based on items count
  const allItemsCount = (files?.length || 0) + (folders?.length || 0);
  const name =
    allItemsCount === 1
      ? files?.[0]?.name || folders?.[0]?.folder_name || ""
      : "";

  // Construct the filename and download
  const fileName = name
    ? `${name}-${formattedDate}.zip`
    : `${formattedDate}.zip`;
  downloadItem(blobToDownload, fileName);
};
