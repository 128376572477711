export const SORT_OPTIONS = {
  DEFAULT: "Default",
  NAME: "Name",
  LAST_MODIFIED: "Last Modified",
};

export const sortItems = (items, sortBy, isAscending, nameField, dateField) => {
  if (!items) {
    return [];
  }

  const sortedItems = [...items];

  if (sortBy === SORT_OPTIONS.DEFAULT) {
    // Reverse the order if isAscending is false
    if (!isAscending) {
      sortedItems.reverse();
    }
  } else {
    sortedItems.sort((a, b) => {
      if (sortBy === SORT_OPTIONS.NAME) {
        // Check if alias is present and use alias for sorting, otherwise fallback to name
        const aName = a.alias || a[nameField]; // Use alias if available, otherwise use name
        const bName = b.alias || b[nameField]; // Same for b

        return isAscending
          ? aName.localeCompare(bName)
          : bName.localeCompare(aName);
      } else if (sortBy === SORT_OPTIONS.LAST_MODIFIED) {
        // Compare dateModified fields
        return isAscending
          ? new Date(a[dateField]) - new Date(b[dateField])
          : new Date(b[dateField]) - new Date(a[dateField]);
      }
      return 0; // Default: no sorting
    });
  }

  return sortedItems;
};
