/**
 * TagsFilter Component
 *
 * This component renders a dropdown for filtering by "Tags".
 * It optionally synchronizes with:
 *   - A `initialSelectedItem` prop from the parent, which controls the selected tags.
 *   - The `onItemSelect` callback, if provided, to notify the parent of selection changes.
 *
 * If `initialSelectedItem` or `onItemSelect` is not provided, TagsFilter functions independently,
 * defaulting to using URL query parameters (`tags`) to set or clear filters.
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../layout/routes/routes";
import {
  fetchTagsAction,
  getTags,
} from "../../../redux/slices/organization-master-slice";
import FilterDropdown from "./filter-dropdown";

const TagsFilter = ({ onItemSelect, initialSelectedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const orgId = useSelector((state) => state.user.org_id);
  const tags = useSelector(getTags);

  // Extract 'tags' parameter from the URL query string
  const queryParams = new URLSearchParams(location.search);
  const tagsParam = queryParams.get("tags");

  // Define the route for search navigation
  const searchRoute = routes.find((route) => route.id === "SEARCH");

  // Internal state to manage the selected tags, initializing with an empty array.
  // This state can be overridden by `initialSelectedItem` prop or URL parameter (`tags`)
  const [selected, setSelected] = useState([]);

  const [tagsSuggestions, setTagsSuggestions] = useState([]);

  useEffect(() => {
    if (orgId) {
      dispatch(fetchTagsAction(orgId));
    }
  }, [orgId]);

  useEffect(() => {
    // Use JSON.stringify to compare arrays as strings
    // Doing this as a workaround. For some reason tags useEffect is getting called 4 times.
    // So created another local state to avoid re-renders.
    if (JSON.stringify(tags) !== JSON.stringify(tagsSuggestions)) {
      setTagsSuggestions(tags);
    }
  }, [tags]);

  // Synchronize `selected` with the `initialSelectedItem` prop when it changes.
  // `initialSelectedItem` is an optional prop allowing the parent to control the selected tags.
  // OR
  // If `tags` exists in the URL, set it as the initial selected tags.
  // This effect will update `selected` whenever `tags` in the URL changes.
  useEffect(() => {
    // If `initialSelectedItem` is passed as prop give it high priority than typeParam
    if (initialSelectedItem !== undefined) {
      setSelected(
        initialSelectedItem.filter((item) => tagsSuggestions.includes(item))
      );
    } else {
      if (tagsParam?.length > 0) {
        const validTags = tagsParam
          .split(",")
          .filter((tag) => tagsSuggestions.includes(tag));
        setSelected(validTags);
        // Also udpate parent
        if (onItemSelect) onItemSelect(validTags);
      } else {
        setSelected([]);
        // Also udpate parent
        if (onItemSelect) onItemSelect([]);
      }
    }
  }, [tagsParam, tagsSuggestions, initialSelectedItem]);

  // Handle the selection of tags in the dropdown
  const handleTagSelect = (selectedTags) => {
    setSelected(selectedTags); // Update the internal state to reflect the selected tags
    if (onItemSelect) {
      // If a callback `onItemSelect` is provided by the parent, do not apply filter immediately
      // and notify the parent of the selection and let parent decide what to do with the selection
      onItemSelect(selectedTags);
    } else {
      // By default, apply filter immediately on item select
      if (selectedTags.length > 0)
        // Set selected tags as a comma-separated list
        queryParams.set("tags", selectedTags.join(","));
      else queryParams.delete("tags"); // Clear the tags filter from query parameters
      navigate(
        `${searchRoute.layout}${searchRoute.path}?${queryParams.toString()}`
      );
      // In case if we decide to provide filtering inside the folder, use this -
      // navigate(`${location.pathname}?${queryParams.toString()}`);
    }
  };

  const handleClearTags = () => {
    setSelected([]); // Clear the internal selected state
    // If a callback `onItemSelect` is provided, notify the parent that the selection is cleared.
    if (onItemSelect) {
      onItemSelect([]);
    } else {
      // If no callback, remove the 'tags' parameter from the URL
      queryParams.delete("tags"); // Clear the tags filter from query parameters
      navigate(`${location.pathname}?${queryParams.toString()}`);
    }
  };

  return (
    <>
      <FilterDropdown
        title="Tags"
        items={tags}
        isMultiSelect={true}
        showSearch={true}
        onItemSelect={handleTagSelect}
        onClearSelection={handleClearTags}
        initialSelectedItems={selected?.length > 0 ? selected : []}
      />
    </>
  );
};

export default TagsFilter;
