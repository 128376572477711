import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import EmptyState from "../components/common/empty-state";
import FolderContent from "../components/folders/folder-content";
import StorageNavbar from "../components/storage-navbar/storage-navbar";
import {
  getSearchResultFiles,
  getSearchResultfolders,
  searchFilesAction,
} from "../redux/slices/search-slice";

const SearchView = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const user = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);
  const searchFiles = useSelector(getSearchResultFiles);
  const searchFolders = useSelector(getSearchResultfolders);
  const totalFilesCount = useSelector((state) => state.search.totalFiles);
  const [searchState, setSearchState] = useState({
    page: 1,
    params: {},
  });

  const limit = 40;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("q");
    const type = queryParams.get("type") ? queryParams.get("type") : "all";
    const tags = queryParams.get("tags")?.split(",") || [];
    const after = queryParams.get("after");
    const before = queryParams.get("before");

    const params = {
      searchKey: query,
      type,
      tags,
      startDate: after,
      endDate: before,
    };

    // Batch update page and params in a single state object
    setSearchState({ page: 1, params });
  }, [location]);

  // Trigger fetch whenever page or search params change
  useEffect(() => {
    if (organization.id != null) {
      fetchSearchResults(searchState.page, searchState.params);
    }
  }, [organization.id, searchState]);

  const fetchSearchResults = (pageNumber, params) => {
    if (pageNumber === 1) setIsLoading(true);

    dispatch(
      searchFilesAction({
        filters: {
          ...params,
          org_id: organization.id,
          language: "marathi",
          page: pageNumber,
          limit,
        },
        userId: user.id,
        successHandler: () => {
          setIsLoading(false);
          setIsLoadingMore(false);
        },
      })
    );
  };

  return (
    <>
      <StorageNavbar filters={["tags", "type", "date"]} />
      <FolderContent
        folders={searchFolders}
        files={searchFiles}
        isLoading={isLoading}
        emptyStateComponent={<EmptyState text="No matching results" />}
        pagination={true}
        currentCount={searchFiles.length}
        totalCount={totalFilesCount}
        onLoadMore={() => {
          setIsLoadingMore(true);
          setSearchState((prevState) => ({
            ...prevState,
            page: prevState.page + 1,
          }));
        }}
        isLoadingMore={isLoadingMore}
      />
    </>
  );
};

export default SearchView;
