import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteNotification,
  fetchNotifications,
  hasNewNotifications,
  readAllNotification,
  readNotification,
} from "../services/notification-service";

const initialState = {
  notifications: [],
  hasUnread: false,
};

export const fetchAllNotifications = createAsyncThunk(
  "notifications/get",
  async ({ userId, orgId, successHandler }, { dispatch }) => {
    const data = await fetchNotifications(userId, orgId, successHandler);
    return data;
  }
);

export const readGivenNotification = createAsyncThunk(
  "notifications/read",
  async ({ userId, notificationId, successHandler }, { dispatch }) => {
    const data = await readNotification(
      { userId, notificationId },
      successHandler
    );
    return data;
  }
);

export const readAllGivenNotification = createAsyncThunk(
  "notifications/read-all",
  async ({ userId, orgId, successHandler }, { dispatch }) => {
    const data = await readAllNotification({ userId, orgId }, successHandler);
    return data;
  }
);

export const deleteGivenNotification = createAsyncThunk(
  "notifications/delete",
  async ({ notificationId, successHandler }, { dispatch }) => {
    const data = await deleteNotification(notificationId, successHandler);
    return data;
  }
);

export const hasNewUnreadNotification = createAsyncThunk(
  "notifications/has-unread",
  async ({ userId, orgId, successHandler }, { dispatch }) => {
    const data = await hasNewNotifications(userId, orgId);
    return data;
  }
);

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {  setNewUnreadNotificationBySSE: (state, action) => {
    return {
      ...state,
      hasUnread:true,
    };
  },},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllNotifications.fulfilled, (state, action) => {
        const { notifications } = action.payload;
        state.notifications = [...notifications];

        // If any notification is unread, set the hasUnread flag to true.
        state.hasUnread = notifications.some(
          (notification) => !notification.is_read
        );
      })
      .addCase(readGivenNotification.fulfilled, (state, action) => {
        state.notifications = state.notifications.map((notification) =>
          notification._id === action.payload.notification_id
            ? { ...notification, is_read: true }
            : notification
        );

        // If any notification is unread, set the hasUnread flag to true.
        state.hasUnread = state.notifications.some(
          (notification) => !notification.is_read
        );
      })
      .addCase(readAllGivenNotification.fulfilled, (state, action) => {
        state.notifications = state.notifications.map((notification) => ({
          ...notification,
          is_read: true,
        }));
        state.hasUnread = false;
      })
      .addCase(deleteGivenNotification.fulfilled, (state, action) => {
        state.notifications = state.notifications.filter(
          (notification) => notification._id !== action.payload.notification_id
        );

        // If any notification is unread, set the hasUnread flag to true.
        state.hasUnread = state.notifications.some(
          (notification) => !notification.is_read
        );
      })
      .addCase(hasNewUnreadNotification.fulfilled, (state, action) => {
        state.hasUnread = action.payload.hasUnreadNotifications;
      });
  },
});

export const {setNewUnreadNotificationBySSE} = notificationSlice.actions;

export const getNotifications = (state) => state.notifications.notifications;

export default notificationSlice.reducer;
