import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { NOTIFICATION_TYPES } from "../../constants/notification-types";
import { createNotification } from "../../redux/services/notification-service";
import UserSelectionComponent from "./user-selection-component";

const ShareModal = ({ isOpen, toggle, files, folders }) => {
  const [message, setMessage] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const organization = useSelector((state) => state.organization);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    setMessage("");
    setSelectedUsers([]);
  }, [isOpen]);

  const handleShare = async () => {
    let notification = {
      title: "",
      description: "",
      message,
      to: selectedUsers?.map((user) => user._id),
      from: user?.id,
      type: NOTIFICATION_TYPES.SHARED_WITH_YOU,
      is_group: false,
      file_ids: files?.map((file) => file._id),
      folder_ids: folders?.map((folder) => folder._id),
      org_id: organization.id,
    };

    if (files?.length > 0 || folders?.length > 0)
      await createNotification(
        notification,
        () => {
          // Success Handler
          toggle();
        },
        () => {
          // Error Handler
        }
      );
  };

  const renderTitle = () => {
    const foldersCount = folders?.length || 0;
    const filesCount = files?.length || 0;
    if (foldersCount + filesCount > 1) {
      return (
        <div className="share-primary-text">
          {foldersCount + filesCount} items
        </div>
      );
    } else {
      let primaryText = "";
      let secondaryText = "";
      if (foldersCount === 1) {
        primaryText = folders[0]?.folder_name;
      } else if (filesCount === 1) {
        if (files[0].alias?.trim().length > 0) {
          primaryText = files[0].alias;
          secondaryText = files[0].name;
        } else {
          primaryText = files[0].name;
        }
      }
      return (
        <div>
          <div className="share-primary-text">{primaryText}</div>
          {secondaryText.trim().length > 0 && (
            <div className="share-secondary-text">{secondaryText}</div>
          )}
        </div>
      );
    }
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={toggle}
      className="custom-modal no-select"
    >
      <ModalHeader className="border-bottom-0" toggle={toggle}>
        Share items
      </ModalHeader>
      <ModalBody>
        <div className="share-modal-content">
          {renderTitle()}
          <UserSelectionComponent
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
          <FormGroup>
            <Input
              type="textarea"
              placeholder="Message"
              value={message}
              maxLength={120}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              style={{ resize: "none" }}
            />
            <div className="input-characters-label">
              {message.length}/120 characters
            </div>
          </FormGroup>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="link"
          className="color-light font-weight-bold"
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleShare}
          disabled={!selectedUsers.length > 0}
        >
          Share
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ShareModal;
