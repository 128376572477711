import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import TagsFilter from "../storage-navbar/filters/tags-filter";
import TypeFilter from "../storage-navbar/filters/type-filter";
import { isValid, parse } from "date-fns";

const AdvancedSearchModal = ({
  isOpen,
  toggle,
  onSearch,
  searchInput,
  selectedTypeFilter,
  selectedTagsFilter,
  selectedAfterDate,
  selectedBeforeDate,
}) => {
  const [hasWords, setHasWords] = useState("");
  const [containsOption, setContainsOption] = useState("containsAll");
  const [itemName, setItemName] = useState("");
  const [afterDate, setAfterDate] = useState("");
  const [beforeDate, setBeforeDate] = useState("");
  const [type, setType] = useState("");
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (isOpen) {
      let newHasWords = "";
      let newContainsOption = "containsAll";
      let newItemName = "";

      if (searchInput) {
        // Split the input by semicolon (;) to separate fields
        searchInput.split(";").forEach((field) => {
          field = field.trim(); // Remove extra spaces

          if (field.startsWith("any:")) {
            // Handle the "any" field
            newHasWords = field.slice(4).trim(); // Remove "any:" and trim
            newContainsOption = "containsAny";
          } else if (field.startsWith("name:")) {
            // Handle the "name" field
            newItemName = field.slice(5).trim(); // Remove "name:" and trim
          } else {
            // Handle the default case (appending to `hasWords`)
            const words = field.split(",").map((word) => word.trim()); // Split by comma and trim
            newHasWords =
              newHasWords.length > 0
                ? newHasWords + ", " + words.join(", ")
                : words.join(", ");
          }
        });
      }

      setHasWords(newHasWords);
      setContainsOption(newContainsOption);
      setItemName(newItemName);
      setType(selectedTypeFilter || "");
      setTags(selectedTagsFilter || []);
      setAfterDate(
        isValid(parse(selectedAfterDate, "yyyy-MM-dd", new Date()))
          ? selectedAfterDate
          : ""
      );
      setBeforeDate(
        isValid(parse(selectedBeforeDate, "yyyy-MM-dd", new Date()))
          ? selectedBeforeDate
          : ""
      );
    }
  }, [
    isOpen,
    searchInput,
    selectedTypeFilter,
    selectedTagsFilter,
    selectedAfterDate,
    selectedBeforeDate,
  ]);

  const handleResetClick = () => {
    setHasWords("");
    setContainsOption("containsAll");
    setItemName("");
    setAfterDate("");
    setBeforeDate("");
    setType("");
    setTags([]);
  };

  const handleSearchClick = () => {
    let finalSearchInput = "";
    if (hasWords.trim().length > 0) {
      if (containsOption === "containsAny") {
        finalSearchInput = "any: " + hasWords;
      } else {
        finalSearchInput = hasWords;
      }
    }
    if (itemName.trim().length > 0) {
      finalSearchInput =
        finalSearchInput.trim().length > 0
          ? finalSearchInput + "; name: " + itemName
          : "name: " + itemName;
    }

    onSearch(finalSearchInput, type, tags, afterDate, beforeDate);
    toggle();
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={toggle}
      className="custom-modal advanced-search-modal no-select"
    >
      <ModalHeader className="border-bottom-0" toggle={toggle}>
        Advanced Search
      </ModalHeader>
      <ModalBody>
        <div className="input-group">
          <div className="label">Item Type</div>
          <div className="input-container">
            <TypeFilter
              onItemSelect={(type) => {
                setType(type);
              }}
              initialSelectedItem={type}
            />
          </div>
        </div>

        <div className="input-group">
          <div className="label">Has the words</div>
          <div className="input-container">
            <Input
              type="text"
              placeholder="Enter words separated by comma"
              value={hasWords}
              onChange={(e) => setHasWords(e.target.value)}
            />
            <div>
              <FormGroup check inline>
                <Label className="radio-button-container" check>
                  <Input
                    type="radio"
                    value="containsAll"
                    checked={containsOption === "containsAll"}
                    onChange={() => setContainsOption("containsAll")}
                  />
                  Contains all
                </Label>
              </FormGroup>
              <FormGroup check inline className="ml-3">
                <Label className="radio-button-container" check>
                  <Input
                    type="radio"
                    value="containsAny"
                    checked={containsOption === "containsAny"}
                    onChange={() => setContainsOption("containsAny")}
                  />
                  Contains any
                </Label>
              </FormGroup>
            </div>
          </div>
        </div>

        <div className="input-group">
          <div className="label">Item name</div>
          <div className="input-container">
            <Input
              type="text"
              placeholder="Enter item name"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
            />
          </div>
        </div>

        <div className="input-group">
          <div className="label">Tags</div>
          <div className="input-container">
            <TagsFilter
              onItemSelect={(tags) => {
                setTags(tags);
              }}
              initialSelectedItem={tags}
            />
          </div>
        </div>

        <div className="input-group">
          <div className="label">Modified</div>
          <div className="input-container">
            <div className="dates-container">
              <div className="flex-grow-1">
                <Input
                  type="date"
                  value={afterDate}
                  onChange={(e) => setAfterDate(e.target.value)}
                />
                After date
              </div>
              <div className="flex-grow-1">
                <Input
                  type="date"
                  value={beforeDate}
                  onChange={(e) => setBeforeDate(e.target.value)}
                />
                Before date
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="link"
          className="color-light font-weight-bold"
          onClick={handleResetClick}
        >
          Reset
        </Button>
        <Button color="primary" onClick={handleSearchClick}>
          Search
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AdvancedSearchModal;
