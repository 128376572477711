import { format, formatDistanceToNow, parseISO } from "date-fns";

export function getRelativeTime(dateString) {
  if (!dateString) return "";
  const date = new Date(dateString);
  return formatDistanceToNow(date, { addSuffix: true });
}

export function formatDateToReadableString(date, showTime = true) {
  if (!date) return "";
  return format(
    new Date(date),
    showTime ? "MMM dd, yyyy, h:mm a" : "MMM dd, yyyy"
  );
}

export const formatDateForInput = (date) => {
  if (!date) return "";
  try {
    return format(parseISO(date), "yyyy-MM-dd"); // Convert ISO to yyyy-MM-dd
  } catch {
    return "";
  }
};
