import { createHeaders, handleErrors, parseJSON } from "./api-middleware";
import { API_BASE_URL } from "./base-url";

export const moveTo = async (
  orgId,
  userId,
  folderIds,
  fileIds,
  targetFolderId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/bulk-operations/move`, {
      method: "POST",
      headers: createHeaders(),
      body: JSON.stringify({
        orgId,
        userId,
        folderIds,
        imageIds: fileIds,
        targetFolderId,
      }),
    });

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const downloadAll = async (
  orgId,
  userId,
  folderIds,
  fileIds,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/bulk-operations/download`,
      {
        method: "POST",
        headers: createHeaders(),
        body: JSON.stringify({
          orgId,
          userId,
          folderIds,
          imageIds: fileIds,
        }),
      }
    );

    await handleErrors(response, errorHandler);
    const blob = await response.blob();
    if (blob && successHandler) {
      successHandler(blob);
    }
    return blob;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};
