import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback,
} from "reactstrap";
import {
  renameFile,
  renameFolder,
} from "../../../redux/slices/active-folder-slice";

const RenameModal = ({ isOpen, toggle, item, isFile }) => {
  const dispatch = useDispatch();
  const [newName, setNewName] = useState("");
  const [extension, setExtension] = useState("");

  const activeFolder = useSelector((state) => state.activeFolder);
  const folders = useSelector((state) => state.folders.folders);

  useEffect(() => {
    if (item) {
      if (isFile) {
        const fileName = item.name || item.image_name;
        const lastDotIndex = fileName?.lastIndexOf(".");
        if (lastDotIndex !== -1) {
          setNewName(fileName.substring(0, lastDotIndex));
          setExtension(fileName.substring(lastDotIndex));
        } else {
          setNewName(fileName);
        }
      } else {
        setNewName(item.folder_name);
      }
    }
  }, [item, isFile]);

  const checkNameExists = (name) => {
    const items = isFile
      ? activeFolder.files
      : item.parent_folder_id
      ? activeFolder.folders
      : folders;
    return items?.some(
      (i) =>
        i._id !== item._id &&
        i[isFile ? "name" : "folder_name"] ===
          (isFile ? name + extension : name)
    );
  };

  const handleNameChange = (e) => setNewName(e.target.value);

  const handleRename = () => {
    const action = isFile ? renameFile : renameFolder;
    dispatch(
      action({
        [isFile ? "file" : "folder"]: item,
        newName: isFile ? newName + extension : newName,
        successHandler: toggle,
      })
    );
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && !isRenameDisabled) {
      e.preventDefault();
      handleRename();
    }
  };

  const isRenameDisabled = newName?.trim() === "" || checkNameExists(newName);

  const handleDoubleClick = (event) => {
    // Preventing event bubbling which was causing any double clicks on this modal trigger double click of parent folder/file item
    event.stopPropagation();
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={toggle}
      className="custom-modal"
      onDoubleClick={handleDoubleClick}
    >
      <ModalHeader className="border-bottom-0" toggle={toggle}>
        Rename
      </ModalHeader>
      <ModalBody>
        <InputGroup>
          <Input
            type="text"
            name="newName"
            id="newName"
            placeholder="Enter new name"
            value={newName}
            onKeyDown={handleInputKeyPress}
            onChange={handleNameChange}
            invalid={checkNameExists(newName)}
          />
          {isFile && <InputGroupText>{extension}</InputGroupText>}
          <FormFeedback>{`A ${
            isFile ? "file" : "folder"
          } with this name already exists.`}</FormFeedback>
        </InputGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          color="link"
          className="color-light font-weight-bold"
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleRename}
          disabled={isRenameDisabled}
        >
          Rename
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RenameModal;
