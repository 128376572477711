import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import routes from "../../../layout/routes/routes";

const ItemsCountView = () => {
  const location = useLocation();

  const currentPath = location.pathname;
  const currentRoute = routes.find(
    (route) => route.layout + route.path === currentPath
  );

  const [count, setCount] = useState(null);

  const homeRoute = routes.find((route) => route.id === "HOME");
  const storageRoute = routes.find((route) => route.id === "SHARED_STORAGE");
  const favouritesRoute = routes.find((route) => route.id === "FAVOURITES");
  const trashRoute = routes.find((route) => route.id === "TRASH");
  const searchRoute = routes.find((route) => route.id === "SEARCH");

  const activeFolder = useSelector((state) => state.activeFolder);
  const search = useSelector((state) => state.search);
  const favorites = useSelector((state) => state.favorites);
  const trash = useSelector((state) => state.trash);
  const rootFolder = useSelector((state) => state.folders);

  useEffect(() => {
    let currentCount = null;
    let totalCount = null;
    if (currentRoute === favouritesRoute) {
      if (
        favorites.favorites?.folders &&
        favorites.favorites?.files &&
        !favorites.isLoadingFetchFavorites
      ) {
        currentCount =
          favorites.favorites.folders.length + favorites.favorites.files.length;
        totalCount =
          favorites.favorites.folders.length + favorites.favorites.files.length;
      }
    } else if (currentRoute === trashRoute) {
      if (trash.folders && trash.files && !trash.isLoadingFetchTrash) {
        currentCount = trash.folders.length + trash.files.length;
        totalCount = trash.folders.length + trash.files.length;
      }
    } else if (currentRoute === storageRoute) {
      let isRoot = false;
      const searchParams = new URLSearchParams(location.search);
      if (!searchParams.has("folderId")) {
        isRoot = true;
      }

      if (isRoot && rootFolder.folders && !rootFolder.isLoadingFetchFolders) {
        currentCount = rootFolder.folders.length;
        totalCount = rootFolder.folders.length;
      } else if (
        activeFolder.folders &&
        activeFolder.files &&
        !activeFolder.isLoadingFetchFolders &&
        !activeFolder.isLoadingFetchFiles
      ) {
        currentCount = activeFolder.folders.length + activeFolder.files.length;
        totalCount = activeFolder.folders.length + activeFolder.files.length;
      }
    } else if (currentRoute === homeRoute || currentRoute === searchRoute) {
      currentCount = search.files.length + search.folders.length;
      totalCount = search.totalFiles + search.totalFolders;
    }

    if (currentCount != null) {
      if (totalCount && currentCount < totalCount) {
        setCount(
          currentCount +
            " of " +
            `${totalCount} item${totalCount === 1 ? "" : "s"}`
        );
      } else {
        setCount(`${currentCount} item${currentCount === 1 ? "" : "s"}`);
      }
    } else {
      setCount("");
    }
  }, [rootFolder, activeFolder, search, trash, favorites, currentRoute]);

  return (
    <>
      <div className="label text-muted">{count}</div>
    </>
  );
};

export default ItemsCountView;

// BKP @Pankaj
// const itemsCount = useSelector((state) => state.itemsCount);
// useEffect(() => {
//   if (itemsCount.currentItems != null) {
//     const total = itemsCount.totalItems || null;
//     if (total && itemsCount.currentItems < total) {
//       setCount(
//         itemsCount.currentItems +
//           " of " +
//           `${total} item${total === 1 ? "" : "s"}`
//       );
//     } else {
//       setCount(
//         `${itemsCount.currentItems} item${
//           itemsCount.currentItems === 1 ? "" : "s"
//         }`
//       );
//     }
//   } else {
//     setCount(null);
//   }
// }, [itemsCount.currentItems, itemsCount.totalItems]);
