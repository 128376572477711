import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveFolderDEtails, getActiveFolderId } from "../../../redux/slices/active-folder-slice";
import {
  getSelectedFilesToUpload,
  removeSelectedFile,
  setSelectedFilesToUpload,
  startUpload,
  updateProgress,
  uploadFileAction,
} from "../../../redux/slices/upload-slice";
import UploadFileCard from "../cards/upload-file-card";
import ProximaDropZone from "../dropzone";
import UploadFolderGroupCard from "../cards/upload-folder-group-card";

const FilesToUpload = () => {
  const dispatch = useDispatch();

  const [selectedFiles, setSelectedFiles] = useState([]);

  const selectedFilesToUpload = useSelector(getSelectedFilesToUpload);
  const activeFolderId = useSelector(getActiveFolderId);
  const organization = useSelector((state) => state.organization);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    setFilesWithFolderId(selectedFiles);
  }, [selectedFiles]);

  const setFilesWithFolderId = (newFiles) => {
    const filesWithFolderId = newFiles.map((file) => ({
      file,
      folderId: activeFolderId
    }));
   
    dispatch(setSelectedFilesToUpload(filesWithFolderId));
  };

  const handleRemoveFile = (file) => {
    dispatch(removeSelectedFile(file));
  };

  // Grouping files by folderId
  const groupedFiles = selectedFilesToUpload.reduce((acc, fileObj) => {
    const folderId = fileObj.folderId;

    if (!acc[folderId]) {
      acc[folderId] = [];
    }

    acc[folderId].push(fileObj);
    return acc;
  }, {});

  useEffect(() => {
    // console.log(selectedFilesToUpload);
    let uploadingFiles = selectedFilesToUpload.filter(
      (file) => file.isUploading
    );
    if (uploadingFiles.length==0) {
      let fileToProcess = selectedFilesToUpload.filter(
        (file) => file.isInQueue && !file.isUploading
      );

      if (fileToProcess.length) {
        let file = fileToProcess[0];
        console.log(file)
        dispatch(startUpload({ id: file.id }));
        dispatch(
          uploadFileAction({
            orgId: organization.id,
            folderId: file.folderId,
            file: file.file,
            userId: user.id,
            handleProgess: (progress) => {
              //dispatch(updateProgress({ id: file.id, progress }));
              console.log(progress)
            },
            fileId: file.id,
          })
        );
      }
    }
  }, [selectedFilesToUpload]);

  return (
    <>
      {activeFolderId && (
        <ProximaDropZone setSelectedFiles={setSelectedFiles} />
      )}
      {Object.keys(groupedFiles).map((folderId) => (
        <React.Fragment>
          {/* Display the folder */}
          <UploadFolderGroupCard folderId={folderId} orgId={organization.id}/>
          {/* Render each file inside the folder */}
          {groupedFiles[folderId].map((file, index) => (
            <UploadFileCard
              key={index}
              file={file}
              onCancelUpload={() => handleRemoveFile(file)}
            />
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

export default FilesToUpload;
