import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteForeverService,
  emptyTrashService,
  fetchTrashContents,
  moveToTrashService,
  restoreFromTrashService,
} from "../services/trash-service";
import { clearSelection } from "./multi-select-slice";

const initialState = {
  folders: [],
  files: [],
  isLoadingFetchTrash: true,
};

export const fetchTrashContentsAction = createAsyncThunk(
  "folder/fetchTrashContents",
  async ({ orgId }, { dispatch }) => {
    const data = await fetchTrashContents(orgId);
    return data;
  }
);

export const moveToTrashAction = createAsyncThunk(
  "folder/moveToTrash",
  async ({ orgId, userId, folderIds, fileIds }, { dispatch }) => {
    const data = await moveToTrashService(orgId, userId, folderIds, fileIds);
    dispatch(clearSelection()); // Clear multi selected items if any
    return { data: data, fileIds: fileIds, folderIds: folderIds };
  }
);

export const restoreFromTrashAction = createAsyncThunk(
  "folder/restoreFromTrash",
  async ({ orgId, userId, folderIds, fileIds }, { dispatch }) => {
    const data = await restoreFromTrashService(
      orgId,
      userId,
      folderIds,
      fileIds
    );
    dispatch(clearSelection()); // Clear multi selected items if any
    return { data: data, fileIds: fileIds, folderIds: folderIds };
  }
);

export const deleteForeverAction = createAsyncThunk(
  "folder/deleteForever",
  async ({ folderIds, fileIds, successHandler }, { dispatch }) => {
    const data = await deleteForeverService(folderIds, fileIds, successHandler);
    dispatch(clearSelection()); // Clear multi selected items if any
    return { data: data, folderIds: folderIds, fileIds: fileIds };
  }
);

export const emptyTrashAction = createAsyncThunk(
  "folder/emptyTrash",
  async ({ orgId, successHandler }, { dispatch }) => {
    const data = await emptyTrashService(orgId, successHandler);
    return data;
  }
);

const trashSlice = createSlice({
  name: "trash",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrashContentsAction.pending, (state) => {
        state.isLoadingFetchTrash = true;
      })
      .addCase(fetchTrashContentsAction.fulfilled, (state, action) => {
        state.folders = action.payload.trashed_folders;
        state.files = action.payload.trashed_images;
        state.isLoadingFetchTrash = false;
      })
      .addCase(moveToTrashAction.fulfilled, (state, action) => {
        // This is not actually required here as this action will never happen inside trash.
        // So this is handled in other slices
      })
      .addCase(restoreFromTrashAction.fulfilled, (state, action) => {
        const { folderIds, fileIds } = action.payload;

        // Remove from folders
        state.folders = state.folders.filter(
          (folder) => !folderIds.includes(folder._id)
        );
        // Remove from files
        state.files = state.files.filter((file) => !fileIds.includes(file._id));
      })
      .addCase(deleteForeverAction.fulfilled, (state, action) => {
        const { folderIds, fileIds } = action.payload;

        // Remove from folders
        state.folders = state.folders.filter(
          (folder) => !folderIds.includes(folder._id)
        );
        // Remove from files
        state.files = state.files.filter((file) => !fileIds.includes(file._id));
      })
      .addCase(emptyTrashAction.fulfilled, (state, action) => {
        state.folders = [];
        state.files = [];
      });
  },
});

export const {} = trashSlice.actions;

export const getTrashFiles = (state) => state.trash.files;
export const getTrashFolders = (state) => state.trash.folders;

export default trashSlice.reducer;
