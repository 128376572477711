import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import EmptyState from "../components/common/empty-state";
import FolderContent from "../components/folders/folder-content";
import StorageNavbar from "../components/storage-navbar/storage-navbar";
import {
  getSearchResultFiles,
  getSearchResultfolders,
  searchFilesAction,
} from "../redux/slices/search-slice";
import { formatDateForFilter } from "../utils/filter-date-utils";

const HomeView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const user = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);
  const searchFiles = useSelector(getSearchResultFiles);
  const searchFolders = useSelector(getSearchResultfolders);
  const totalFilesCount = useSelector((state) => state.search.totalFiles);
  const [searchState, setSearchState] = useState({
    page: 1,
    params: {},
  });

  const limit = 30;

  useEffect(() => {
    // By default, set a date to display suggestions
    const queryParams = new URLSearchParams(location.search);
    const today = new Date();
    // const sevenDaysAgo = new Date(today);
    // sevenDaysAgo.setDate(today.getDate() - 7);
    queryParams.set("after", formatDateForFilter(today));
    navigate(`${location.pathname}?${queryParams.toString()}`);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const afterParam = queryParams.get("after");

    if (afterParam) {
      const params = {
        searchKey: "",
        type: "all",
        tags: [],
        startDate: afterParam,
        endDate: null,
      };

      // Batch update page and params in a single state object
      setSearchState({ page: 1, params });
    }
  }, [location]);

  // Trigger fetch whenever page or search params change
  useEffect(() => {
    if (organization.id != null && Object.keys(searchState.params).length > 0) {
      fetchSearchResults(searchState.page, searchState.params);
    }
  }, [organization.id, searchState]);

  const fetchSearchResults = (pageNumber, params) => {
    if (pageNumber === 1) setIsLoading(true);

    dispatch(
      searchFilesAction({
        filters: {
          ...params,
          org_id: organization.id,
          language: "marathi",
          page: pageNumber,
          limit,
        },
        userId: user.id,
        successHandler: () => {
          setIsLoading(false);
          setIsLoadingMore(false);
        },
      })
    );
  };

  return (
    <>
      <StorageNavbar filters={["tags", "type", "date"]} />
      <FolderContent
        folders={searchFolders}
        files={searchFiles}
        isLoading={isLoading}
        emptyStateComponent={<EmptyState text="No matching results" />}
        pagination={true}
        currentCount={searchFiles.length}
        totalCount={totalFilesCount}
        onLoadMore={() => {
          setIsLoadingMore(true);
          setSearchState((prevState) => ({
            ...prevState,
            page: prevState.page + 1,
          }));
        }}
        isLoadingMore={isLoadingMore}
      />
    </>
  );
};

export default HomeView;
