// store.js
import { configureStore } from "@reduxjs/toolkit";
import activeFolderSlice from "./slices/active-folder-slice";
import favoritesSlice from "./slices/favorites-slice";
import foldersSlice from "./slices/folders-slice";
import multiSelectSlice from "./slices/multi-select-slice";
import notificationSlice from "./slices/notification-slice";
import ocrReaderSlice from "./slices/ocr-reader-slice";
import organizationSlice from "./slices/organization-slice";
import organizationMasterSlice from "./slices/organization-master-slice";
import searchSlice from "./slices/search-slice";
import shortcutSlice from "./slices/shortcut-slice";
import trashSlice from "./slices/trash-slice";
import uploadSlice from "./slices/upload-slice";
import userPreferenceSlice from "./slices/user-preference-slice";
import userSlice from "./slices/user-slice";

const store = configureStore({
  reducer: {
    user: userSlice,
    organization: organizationSlice,
    organizationMasterData:organizationMasterSlice,
    activeFolder: activeFolderSlice,
    folders: foldersSlice,
    userPreference: userPreferenceSlice,
    upload: uploadSlice,
    search: searchSlice,
    favorites: favoritesSlice,
    trash: trashSlice,
    shortcut: shortcutSlice,
    ocrReader: ocrReaderSlice,
    notifications: notificationSlice,
    multiSelect: multiSelectSlice,
  },
});

export default store;
