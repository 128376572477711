import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SHORTCUTS } from "../../../../constants/shortcuts";
import { updateFileDetailsAction } from "../../../../redux/slices/active-folder-slice";
import EditableTextCard from "../../../common/editable-text-card";

const NoteComponent = ({ file }) => {
  const dispatch = useDispatch();

  const [version, setVersion] = useState(0); // Version counter to force re-render in case saving note fails

  const user = useSelector((state) => state.user);

  const handleSave = (note) => {
    updateNote(note);
    setVersion((prevVersion) => prevVersion + 1);
  };

  const updateNote = (note) => {
    if (file && file._id) {
      dispatch(
        updateFileDetailsAction({
          file: file,
          userId: user.id,
          updates: { notes: note },
        })
      );
    }
  };

  useEffect(() => {
    setVersion((prevVersion) => prevVersion + 1); // Trigger re-render on initialNote change
  }, [file]);

  return (
    <EditableTextCard
      key={version} // Use version to force component recreation
      title="Note"
      initialText={file?.notes || ""}
      editable={true}
      onSave={handleSave}
      placeholder="Type your note here..."
      shortcutKey={SHORTCUTS.EDIT_NOTE}
    />
  );
};

export default NoteComponent;
