import React from "react";
import { useLocation } from "react-router-dom";
import routes from "../../layout/routes/routes";
import EmptyTrashButton from "./empty-trash-button";
import Filters from "./filters/filters";
import NewItemButton from "./new-item-button";
import PageTitle from "./page-title";
import ReadAllNotificationsButton from "./read-all-notifications-button";
import ReaderModeButton from "./reader-mode-button";
import ReaderNoteButton from "./reader-note-button";
import StorageBreadcrumb from "./storage-breadcrumb";
import ViewModeToggle from "./view-mode-toggle";
import SelectAndSortToolbar from "./select-and-sort/select-and-sort-toolbar";

const StorageNavbar = ({ filters = [] }) => {
  const location = useLocation();

  const currentPath = location.pathname;
  const currentRoute = routes.find(
    (route) => route.layout + route.path === currentPath
  );

  const homeRoute = routes.find((route) => route.id === "HOME");
  const storageRoute = routes.find((route) => route.id === "SHARED_STORAGE");
  const favouritesRoute = routes.find((route) => route.id === "FAVOURITES");
  const trashRoute = routes.find((route) => route.id === "TRASH");
  const searchRoute = routes.find((route) => route.id === "SEARCH");
  const notificationsRoute = routes.find(
    (route) => route.id === "NOTIFICATIONS"
  );

  return (
    <div className="storage-navbar border-bottom">
      <div className="storage-navbar-navigation">
        {currentRoute == storageRoute ? (
          <StorageBreadcrumb />
        ) : (
          <PageTitle
            title={currentRoute == homeRoute ? "Suggested" : currentRoute?.name}
          />
        )}
        {/* <ReaderNoteButton /> */}
        <ReaderModeButton />
        {currentRoute == homeRoute ||
        currentRoute == storageRoute ||
        currentRoute == favouritesRoute ||
        currentRoute == trashRoute ||
        currentRoute == searchRoute ? (
          <ViewModeToggle />
        ) : (
          <></>
        )}
        {currentRoute == storageRoute && <NewItemButton />}
        {currentRoute == trashRoute && <EmptyTrashButton />}
        {currentRoute == notificationsRoute && <ReadAllNotificationsButton />}
      </div>
      {filters.length > 0 && (
        <div className="storage-navbar-filters">
          <Filters filters={filters} />
        </div>
      )}
      {(currentRoute == homeRoute ||
        currentRoute == storageRoute ||
        currentRoute == favouritesRoute ||
        currentRoute == trashRoute ||
        currentRoute == searchRoute) && <SelectAndSortToolbar />}
    </div>
  );
};

export default StorageNavbar;
