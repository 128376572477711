import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyState from "../components/common/empty-state";
import FolderContent from "../components/folders/folder-content";
import StorageNavbar from "../components/storage-navbar/storage-navbar";
import {
  getAllFavoritesAction,
  getFavorites,
} from "../redux/slices/favorites-slice";

const FavouritesView = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const organization = useSelector((state) => state.organization);
  const user = useSelector((state) => state.user);
  const favorites = useSelector(getFavorites);

  useEffect(() => {
    if (organization && organization.id && user && user.id) {
      setIsLoading(true);
      dispatch(
        getAllFavoritesAction({ orgId: organization.id, userId: user.id })
      )
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          // ToDo: Error handling
        });
    }
  }, [organization.id, user.id, dispatch]);

  return (
    <>
      <StorageNavbar />
      <FolderContent
        folders={favorites?.folders}
        files={favorites?.files}
        isLoading={isLoading}
        emptyStateComponent={
          <EmptyState text="You do not have any favourites" />
        }
      />
    </>
  );
};

export default FavouritesView;
