import { API_BASE_URL } from "./base-url";

// src/services/sseService.js
class SseService {
   

    constructor(userId) {
        this.userId = userId;
        this.eventSource = null;
        this.listeners = [];
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 5; // Limit the number of reconnection attempts
        this.isConnected = false;
    }

    connect(onMessage, onError) {
        this.eventSource = new EventSource(`${API_BASE_URL}/api/sse/${this.userId}`);

      
        this.eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            onMessage(data);
            this.isConnected = true;
            this.reconnectAttempts = 0; // Reset attempts on successful connection
        };

        this.eventSource.onerror = (error) => {
            console.error('EventSource failed:', error);
            this.isConnected = false;
            this.eventSource.close(); // Close the connection on error
            this.handleReconnect(onMessage, onError); // Attempt to reconnect
        };
    }

    handleReconnect(onMessage, onError) {
        if (!this.isConnected && this.reconnectAttempts < this.maxReconnectAttempts) {
            setTimeout(() => {
                console.log('Attempting to reconnect...');
                this.reconnectAttempts++;
                this.connect(onMessage, onError); // Try to reconnect
            }, 3000); // Wait 3 seconds before attempting to reconnect
        } else if (this.reconnectAttempts >= this.maxReconnectAttempts) {
            console.error('Max reconnect attempts reached. Please check the server.');
            setTimeout(() => {
                console.log('Attempting to reconnect...');
                this.reconnectAttempts = 0;
                this.connect(onMessage, onError); // Try to reconnect
            }, 60000); // Wait 3 seconds before attempting to reconnect
            onError(new Error('Max reconnect attempts reached.'));
        }
    }

    disconnect() {
        if (this.eventSource) {
            this.eventSource.close();
            this.isConnected = false;
        }
    }
}





export default SseService;