import { createHeaders, handleErrors, parseJSON } from "./api-middleware";
import { API_BASE_URL } from "./base-url";



export const fetchTags = async (orgId, successHandler, errorHandler) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/tags/fetch?org_id=${orgId}`,
      {
        method: "GET",
        headers: createHeaders(),
      }
    );

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data.tags);
    }
    return data.tags;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};


export const fetchSources = async (orgId, successHandler, errorHandler) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/sources/all_sources/${orgId}`,
      {
        method: "GET",
        headers: createHeaders(),
      }
    );

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data.sources);
    }
    console.log(data)
    return data.sources;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};
