import { createSlice } from "@reduxjs/toolkit";
import { VIEW_MODES } from "../../constants/view-modes";
import { SORT_OPTIONS } from "../../utils/sort-utils";

const initialState = {
  viewMode: VIEW_MODES.THUMBNAILS,
  isAscending: true,
  sortBy: SORT_OPTIONS.DEFAULT,
};

const userPreferenceSlice = createSlice({
  name: "userPreference",
  initialState,
  reducers: {
    setViewMode: (state, action) => {
      state.viewMode = action.payload;
    },
    setIsAscending: (state, action) => {
      state.isAscending = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
  },
});

export const { setViewMode, setIsAscending, setSortBy } =
  userPreferenceSlice.actions;
export default userPreferenceSlice.reducer;
