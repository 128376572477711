import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { readAllGivenNotification } from "../../redux/slices/notification-slice";

const ReadAllNotificationsButton = () => {
  const dispatch = useDispatch();

  const organization = useSelector((state) => state.organization);
  const user = useSelector((state) => state.user);
  const hasUnread = useSelector((state) => state.notifications.hasUnread);

  const handleReadAllNotifications = () => {
    dispatch(
      readAllGivenNotification({
        userId: user.id,
        orgId: organization.id,
      })
    );
  };

  return (
    <>
      <Button
        color="primary"
        onClick={handleReadAllNotifications}
        disabled={!hasUnread}
      >
        Mark all as read
      </Button>
    </>
  );
};

export default ReadAllNotificationsButton;
