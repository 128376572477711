import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "../../../redux/services/base-url";
import { downloadFileAction } from "../../../redux/slices/active-folder-slice";
import {
  addToFavoritesAction,
  removeFromFavoritesAction,
} from "../../../redux/slices/favorites-slice";
import {
  deleteForeverAction,
  moveToTrashAction,
  restoreFromTrashAction,
} from "../../../redux/slices/trash-slice";
import ConfirmationModal from "../../common/confirmation-modal";
import ContextMenu from "../../common/context-menu";
import FolderTreeSelectionModal, {
  FOLDER_TREE_SELECTION_MODES,
} from "../../folder-tree/folder-tree-selection-modal";
import ImageEditor from "../../image-editor/image-editor";
import ShareModal from "../../share/share-modal";
import RenameModal from "../modals/rename-modal";

const FileContextMenu = ({ file, onFileOpen, disabled }) => {
  const dispatch = useDispatch();

  const [renameModal, setRenameModal] = useState(false);
  const [folderTreeSelectionModal, setFolderTreeSelectionModal] =
    useState(false);
  const [deleteForeverConfirmationModal, setdeleteForeverConfirmationModal] =
    useState(false);
  const [imageEditorModal, setImageEditorModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);

  const user = useSelector((state) => state.user);

  const toggleRenameModal = () => {
    setRenameModal(!renameModal);
  };
  const toggleFolderTreeSelectionModal = () => {
    setFolderTreeSelectionModal(!folderTreeSelectionModal);
  };
  const toggleDeleteForeverConfirmationModal = () => {
    setdeleteForeverConfirmationModal(!deleteForeverConfirmationModal);
  };
  const toggleImageEditorModal = () => {
    setImageEditorModal(!imageEditorModal);
  };
  const toggleShareModal = () => setShareModal(!shareModal);

  const handleAnnotateClick = () => {
    toggleImageEditorModal();
  };

  const handleShareClick = () => {
    toggleShareModal();
  };

  const handleClicks = () => {
    /* Dummy method. ToDo: Handle all clicks */
  };

  const handleRenameClick = () => {
    toggleRenameModal();
  };

  const handleAddToFavoritesClick = () => {
    dispatch(
      addToFavoritesAction({
        orgId: file.org_id,
        userId: user.id,
        fileIds: [file._id],
        folderIds: [],
      })
    );
  };

  const handleRemoveFromFavoritesClick = () => {
    dispatch(
      removeFromFavoritesAction({
        orgId: file.org_id,
        userId: user.id,
        folderIds: [],
        fileIds: [file._id],
      })
    );
  };

  const handleDownloadClick = () => {
    dispatch(
      downloadFileAction({
        file: file,
      })
    );
  };

  const handleMoveToClick = () => {
    setFolderTreeSelectionModal(true);
  };

  const handleMoveToTrashClick = () => {
    dispatch(
      moveToTrashAction({
        orgId: file.org_id,
        userId: user.id,
        fileIds: [file._id],
        folderIds: [],
      })
    );
  };

  const handleRestoreFromTrashClick = () => {
    dispatch(
      restoreFromTrashAction({
        orgId: file.org_id,
        userId: user.id,
        fileIds: [file._id],
        folderIds: [],
      })
    );
  };

  const handleDeleteForeverClick = () => {
    toggleDeleteForeverConfirmationModal();
  };

  const handleDeleteForever = () => {
    dispatch(
      deleteForeverAction({
        folderIds: [],
        fileIds: [file._id],
        successHandler: () => {
          toggleDeleteForeverConfirmationModal();
        },
      })
    );
  };

  const menuItems = [
    {
      label: "Preview",
      icon: "visibility",
      onClick: onFileOpen,
    },
    {
      divider: true, // This will add a divider
    },
    {
      label: "Download",
      icon: "download",
      onClick: handleDownloadClick,
    },
    {
      label: "Rename",
      icon: "edit",
      onClick: handleRenameClick,
    },
    {
      label: "Annotate",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -860 960 960"
          width="20px"
          fill="#212529"
        >
          <path d="m499-287 335-335-52-52-335 335 52 52Zm-261 87q-100-5-149-42T40-349q0-65 53.5-105.5T242-503q39-3 58.5-12.5T320-542q0-26-29.5-39T193-600l7-80q103 8 151.5 41.5T400-542q0 53-38.5 83T248-423q-64 5-96 23.5T120-349q0 35 28 50.5t94 18.5l-4 80Zm280 7L353-358l382-382q20-20 47.5-20t47.5 20l70 70q20 20 20 47.5T900-575L518-193Zm-159 33q-17 4-30-9t-9-30l33-159 165 165-159 33Z" />
        </svg>
      ),
      onClick: handleAnnotateClick,
    },
    {
      divider: true, // This will add a divider
    },
    // {
    //   label: "Copy to...",
    //   icon: "copy",
    //   onClick: handleClicks,
    // },
    {
      label: "Move to...",
      icon: "drive_file_move",
      onClick: handleMoveToClick,
    },
    {
      divider: true, // This will add a divider
    },
    {
      label: "Share",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -860 960 960"
          width="20px"
          fill="#212529"
        >
          <path d="M680-80q-50 0-85-35t-35-85q0-6 3-28L282-392q-16 15-37 23.5t-45 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q24 0 45 8.5t37 23.5l281-164q-2-7-2.5-13.5T560-760q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-24 0-45-8.5T598-672L317-508q2 7 2.5 13.5t.5 14.5q0 8-.5 14.5T317-452l281 164q16-15 37-23.5t45-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T720-200q0-17-11.5-28.5T680-240q-17 0-28.5 11.5T640-200q0 17 11.5 28.5T680-160ZM200-440q17 0 28.5-11.5T240-480q0-17-11.5-28.5T200-520q-17 0-28.5 11.5T160-480q0 17 11.5 28.5T200-440Zm480-280q17 0 28.5-11.5T720-760q0-17-11.5-28.5T680-800q-17 0-28.5 11.5T640-760q0 17 11.5 28.5T680-720Zm0 520ZM200-480Zm480-280Z" />
        </svg>
      ),
      onClick: handleShareClick,
    },
    file.is_favorite
      ? {
          label: "Remove from favourites",
          icon: "star",
          filledIcon: true,
          onClick: handleRemoveFromFavoritesClick,
        }
      : {
          label: "Add to favourites",
          icon: "grade",
          onClick: handleAddToFavoritesClick,
        },
    {
      divider: true, // This will add a divider
    },
    {
      label: "Move to trash",
      icon: "delete",
      onClick: handleMoveToTrashClick,
    },
  ];

  const trashMenuItems = [
    {
      label: "Restore",
      icon: "restore",
      onClick: handleRestoreFromTrashClick,
    },
    {
      label: "Delete Forever",
      icon: "delete_forever",
      onClick: handleDeleteForeverClick,
    },
  ];

  return (
    <>
      <ContextMenu
        menuText="New"
        menuItems={file.is_bin ? trashMenuItems : menuItems}
        type="more"
        disabled={disabled}
      />
      {renameModal && (
        <RenameModal
          isOpen={renameModal}
          toggle={toggleRenameModal}
          item={file}
          isFile={true}
        />
      )}
      <ConfirmationModal
        isOpen={deleteForeverConfirmationModal}
        toggle={toggleDeleteForeverConfirmationModal}
        title="Delete file forever?"
        description={`"${file.name}" will be deleted permanently and you won't be able to restore it again.`}
        confirmButtonText="Delete forever"
        onConfirm={handleDeleteForever}
        isDanger={true}
      />
      <FolderTreeSelectionModal
        isOpen={folderTreeSelectionModal}
        toggle={toggleFolderTreeSelectionModal}
        type={FOLDER_TREE_SELECTION_MODES.MOVE}
        folders={[]}
        files={[file]}
      />
      <ImageEditor
        isOpen={imageEditorModal}
        toggle={toggleImageEditorModal}
        imageUrl={`${API_BASE_URL}/api/images/image/${file.name}/${file.org_id}`}
      />
      <ShareModal
        isOpen={shareModal}
        toggle={toggleShareModal}
        files={[file]}
      />
    </>
  );
};

export default FileContextMenu;
