import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { fetchFolderDetailsById } from "../../../redux/services/folder-service";

const UploadFolderGroupCard = ({ folderId,orgId }) => {
  const [folderName,setFolderName] = useState("")
  useEffect(()=>{
    if(folderId && orgId){
      fetchFolderName(folderId)
    }
  },[folderId ,orgId])

  const fetchFolderName = async(folderId)=>{
    const data = await fetchFolderDetailsById(orgId, folderId);
    if(data && data.folder && data.folder.folder_name){
      setFolderName(data.folder.folder_name)
    }
   
  }
  return (
    <>
      <Card className="file-upload-folder-group">
        <CardBody className="d-flex align-items-center">
          {/* File Icon */}
          <i className="material-icons folder-icon">folder</i>
          <div className="file-details">
            {/* Folder Name */}
            <CardTitle className="mb-0 folder-name">{folderName}</CardTitle>
          </div>
          {/* <i className="material-icons">chevron_right</i> */}
        </CardBody>
      </Card>
    </>
  );
};

export default UploadFolderGroupCard;
