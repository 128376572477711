export const FILTER_DATES = [
  "Today",
  "Yesterday",
  "Last 7 days",
  "Last 30 days",
  "This year",
  "Last year",
  // "Custom date range",
];

export function formatDateForFilter(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function getDatesFromFilterLabel(dateLabel) {
  const today = new Date();
  let afterDate = null;
  let beforeDate = null;

  switch (dateLabel) {
    case "Today":
      afterDate = formatDateForFilter(today);
      break;
    case "Yesterday":
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      afterDate = formatDateForFilter(yesterday);
      beforeDate = formatDateForFilter(today);
      break;
    case "Last 7 days":
      const last7Days = new Date(today);
      last7Days.setDate(today.getDate() - 7);
      afterDate = formatDateForFilter(last7Days);
      break;
    case "Last 30 days":
      const last30Days = new Date(today);
      last30Days.setDate(today.getDate() - 30);
      afterDate = formatDateForFilter(last30Days);
      break;
    case "This year":
      afterDate = `${today.getFullYear()}-01-01`;
      break;
    case "Last year":
      const lastYear = new Date(today);
      lastYear.setFullYear(today.getFullYear() - 1);
      afterDate = `${lastYear.getFullYear()}-01-01`;
      beforeDate = `${lastYear.getFullYear()}-12-31`;
      break;
    default:
      break;
  }

  return { after: afterDate, before: beforeDate };
}

export function mapFilterDatesToLabel({ after, before }) {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (after === formatDateForFilter(today) && !before) return "Today";
  if (
    after === formatDateForFilter(yesterday) &&
    before === formatDateForFilter(today)
  )
    return "Yesterday";

  const last7Days = new Date(today);
  last7Days.setDate(today.getDate() - 7);
  if (after === formatDateForFilter(last7Days) && !before) return "Last 7 days";

  const last30Days = new Date(today);
  last30Days.setDate(today.getDate() - 30);
  if (after === formatDateForFilter(last30Days) && !before)
    return "Last 30 days";

  if (after === `${today.getFullYear()}-01-01` && !before) return "This year";

  const lastYear = new Date(today);
  lastYear.setFullYear(today.getFullYear() - 1);
  if (
    after === `${lastYear.getFullYear()}-01-01` &&
    before === `${lastYear.getFullYear()}-12-31`
  )
    return "Last year";

  // If comes here, fallback to custom date range.
  // if (after || before) return "Custom date range";
  // But instead of "Custom date range" label, show readable date ranges.
  if (after && before) {
    const afterDate = new Date(after);
    const beforeDate = new Date(before);
    return `${afterDate.toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
      year: "numeric",
    })} - ${beforeDate.toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
      year: "numeric",
    })}`;
  } else if (before) {
    const beforeDate = new Date(before);
    return `Before ${beforeDate.toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
      year: "numeric",
    })}`;
  } else if (after) {
    const afterDate = new Date(after);
    return `After ${afterDate.toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
      year: "numeric",
    })}`;
  }

  return null;
}
