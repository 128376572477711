import { createHeaders, handleErrors, parseJSON } from "./api-middleware";
import { API_BASE_URL } from "./base-url";

export const createNotification = async (
    notification,
    successHandler,
    errorHandler
  ) => {
    try {
      // let dataFromCache = retriveFromCache(orgId);
      // if (dataFromCache) {
      //   return dataFromCache;
      // }
      const response = await fetch(`${API_BASE_URL}/api/notification/create`, {
        method: "POST",
        headers: createHeaders(), // Use the headers function
        body:JSON.stringify(notification)
      });
  
      // Apply middleware functions
      await handleErrors(response, errorHandler); // Check for HTTP errors
      const data = await parseJSON(response); // Parse JSON
  
      if (data && data.notification_id) {
        if (successHandler) {
          successHandler(data);
          // localStorage.setItem("@antridge-organizaion/user", JSON.stringify(data.user));
        }
      }
      return data;
    } catch (error) {
      // Handle or log errors if needed
      throw error;
    }
  };

  export const readNotification = async (
    notification,
    successHandler,
    errorHandler
  ) => {
    try {
      // let dataFromCache = retriveFromCache(orgId);
      // if (dataFromCache) {
      //   return dataFromCache;
      // }
      const response = await fetch(`${API_BASE_URL}/api/notification/read`, {
        method: "POST",
        headers: createHeaders(), // Use the headers function
        body:JSON.stringify(notification)
      });
  
      // Apply middleware functions
      await handleErrors(response, errorHandler); // Check for HTTP errors
      const data = await parseJSON(response); // Parse JSON
  
      if (data && data.notification_id) {
        if (successHandler) {
          successHandler(data);
          // localStorage.setItem("@antridge-organizaion/user", JSON.stringify(data.user));
        }
      }
      return data;
    } catch (error) {
      // Handle or log errors if needed
      throw error;
    }
  };

  export const readAllNotification = async (
    notification,
    successHandler,
    errorHandler
  ) => {
    try {
      // let dataFromCache = retriveFromCache(orgId);
      // if (dataFromCache) {
      //   return dataFromCache;
      // }
      const response = await fetch(`${API_BASE_URL}/api/notification/read-all`, {
        method: "POST",
        headers: createHeaders(), // Use the headers function
        body:JSON.stringify(notification)
      });
  
      // Apply middleware functions
      await handleErrors(response, errorHandler); // Check for HTTP errors
      const data = await parseJSON(response); // Parse JSON
  
      if (data && data.userId) {
        if (successHandler) {
          successHandler(data);
          // localStorage.setItem("@antridge-organizaion/user", JSON.stringify(data.user));
        }
      }
      return data;
    } catch (error) {
      // Handle or log errors if needed
      throw error;
    }
  };

  export const deleteNotification = async (
    notificationId,
    successHandler,
    errorHandler
  ) => {
    try {
      // let dataFromCache = retriveFromCache(orgId);
      // if (dataFromCache) {
      //   return dataFromCache;
      // }
      const response = await fetch(`${API_BASE_URL}/api/notification/delete/${notificationId}`, {
        method: "DELETE",
        headers: createHeaders(), // Use the headers function
      });
  
      // Apply middleware functions
      await handleErrors(response, errorHandler); // Check for HTTP errors
      const data = await parseJSON(response); // Parse JSON
  
      if (data && data.notification_id) {
        if (successHandler) {
          successHandler(data);
          // localStorage.setItem("@antridge-organizaion/user", JSON.stringify(data.user));
        }
      }
      return data;
    } catch (error) {
      // Handle or log errors if needed
      throw error;
    }
  };

  export const fetchNotifications = async (
    userId,
    orgId,
    successHandler,
    errorHandler
  ) => {
    try {
      // let dataFromCache = retriveFromCache(orgId);
      // if (dataFromCache) {
      //   return dataFromCache;
      // }
      const response = await fetch(`${API_BASE_URL}/api/notification/view-all-notifications/${orgId}/${userId}`, {
        method: "GET",
        headers: createHeaders(), // Use the headers function
      });
  
      // Apply middleware functions
      await handleErrors(response, errorHandler); // Check for HTTP errors
      const data = await parseJSON(response); // Parse JSON
  
      if (data && data.notifications) {
        if (successHandler) {
          successHandler(data.notifications);
          // localStorage.setItem("@antridge-organizaion/user", JSON.stringify(data.user));
        }
      }
      return data;
    } catch (error) {
      // Handle or log errors if needed
      throw error;
    }
  };

  export const hasNewNotifications = async (
    userId,
    orgId,
    successHandler,
    errorHandler
  ) => {
    try {
      // let dataFromCache = retriveFromCache(orgId);
      // if (dataFromCache) {
      //   return dataFromCache;
      // }
      const response = await fetch(`${API_BASE_URL}/api/notification/has-new-notifications/${orgId}/${userId}`, {
        method: "GET",
        headers: createHeaders(), // Use the headers function
      });
  
      // Apply middleware functions
      await handleErrors(response, errorHandler); // Check for HTTP errors
      const data = await parseJSON(response); // Parse JSON
  
      if (data && data.hasUnreadNotifications) {
        if (successHandler) {
          successHandler(data.hasUnreadNotifications);
          // localStorage.setItem("@antridge-organizaion/user", JSON.stringify(data.user));
        }
      }
      return data;
    } catch (error) {
      // Handle or log errors if needed
      throw error;
    }
  };