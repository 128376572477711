// apiService.js

import {
  createHeaders,
  handleErrors,
  parseJSON,
  retriveFromCache,
  storeInCache,
} from "./api-middleware";
import { API_BASE_URL } from "./base-url";

// Function to perform a POST request for login
export const fetchOrganizationUsers = async (
  orgId,
  successHandler,
  errorHandler
) => {
  try {
    // let dataFromCache = retriveFromCache(orgId);
    // if (dataFromCache) {
    //   return dataFromCache;
    // }
    const response = await fetch(`${API_BASE_URL}/api/users/${orgId}`, {
      method: "GET",
      headers: createHeaders(), // Use the headers function
    });

    // Apply middleware functions
    await handleErrors(response, errorHandler); // Check for HTTP errors
    const data = await parseJSON(response); // Parse JSON

    if (data && data.users) {
      if (successHandler) {
        successHandler(data.users);
        // localStorage.setItem("@antridge-organizaion/user", JSON.stringify(data.user));
      }
    }
    return data;
  } catch (error) {
    // Handle or log errors if needed
    throw error;
  }
};

export const addUser = async (
  user,
  successHandler,
  errorHandler
) => {
  try {
    // let dataFromCache = retriveFromCache(orgId);
    // if (dataFromCache) {
    //   return dataFromCache;
    // }
    const response = await fetch(`${API_BASE_URL}/api/users/create`, {
      method: "POST",
      headers: createHeaders(),
      body:JSON.stringify({
        ...user
      }) // Use the headers function
    });

    // Apply middleware functions
    await handleErrors(response, errorHandler); // Check for HTTP errors
    const data = await parseJSON(response); // Parse JSON

    if (data && data.user_id) {
      if (successHandler) {
        successHandler(data.user_id);
        // localStorage.setItem("@antridge-organizaion/user", JSON.stringify(data.user));
      }
    }
    return data;
  } catch (error) {
    // Handle or log errors if needed
    throw error;
  }
};

export const editUser = async (
  user,
  successHandler,
  errorHandler
) => {
  try {
    // let dataFromCache = retriveFromCache(orgId);
    // if (dataFromCache) {
    //   return dataFromCache;
    // }
    const response = await fetch(`${API_BASE_URL}/api/users/${user._id}`, {
      method: "PUT",
      headers: createHeaders(), // Use the headers function
      body:JSON.stringify({
        ...user
      }) // Use
    });

    // Apply middleware functions
    await handleErrors(response, errorHandler); // Check for HTTP errors
    const data = await parseJSON(response); // Parse JSON

    if (data && data.message) {
      if (successHandler) {
        successHandler(data.message);
        // localStorage.setItem("@antridge-organizaion/user", JSON.stringify(data.user));
      }
    }
    return data;
  } catch (error) {
    // Handle or log errors if needed
    throw error;
  }
};

export const deleteUser = async (
  userId,
  successHandler,
  errorHandler
) => {
  try {
    // let dataFromCache = retriveFromCache(orgId);
    // if (dataFromCache) {
    //   return dataFromCache;
    // }
    const response = await fetch(`${API_BASE_URL}/api/users/${userId}`, {
      method: "DELETE",
      headers: createHeaders(), // Use the headers function
      
    });

    // Apply middleware functions
    await handleErrors(response, errorHandler); // Check for HTTP errors
    const data = await parseJSON(response); // Parse JSON

    if (data && data.message) {
      if (successHandler) {
        successHandler(data.message);
        // localStorage.setItem("@antridge-organizaion/user", JSON.stringify(data.user));
      }
    }
    return data;
  } catch (error) {
    // Handle or log errors if needed
    throw error;
  }
};