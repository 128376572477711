import classnames from "classnames"; // Used to toggle active class
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { SHORTCUTS } from "../../../constants/shortcuts";
import FileInformationTab from "./file-information/file-information-tab";
import HistoryTab from "./history/history-tab";
import InsightsTab from "./insights/insights-tab";
import OcrTextTab from "./ocr-text/ocr-text-tab";

const DetailsTabs = ({ file, isLoading }) => {
  const [activeTab, setActiveTab] = useState(null);

  const shortcutKey = useSelector((state) => state.shortcut.shortcutKey);

  // Define tabs dynamically and memoize to avoid recreation on every render
  const tabs = useMemo(
    () =>
      [
        {
          id: "1",
          label: "Insights",
          component: <InsightsTab file={file} />,
          show: !file?.is_bin,
        },
        {
          id: "2",
          label: "Text",
          component: <OcrTextTab file={file} />,
          show: !file?.is_bin,
        },
        {
          id: "3",
          label: "File Information",
          component: <FileInformationTab file={file} />,
          show: true,
        },
        {
          id: "4",
          label: "History",
          component: <HistoryTab file={file} />,
          show: true,
        },
      ].filter((tab) => tab.show),
    [file]
  );

  useEffect(() => {
    if (file && !activeTab) {
      // Set the first visible tab as active
      const firstVisibleTab = tabs[0]?.id || "1";
      setActiveTab(firstVisibleTab);
    }
  }, [file, activeTab, tabs]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (!file?.is_bin) {
      if (
        shortcutKey === SHORTCUTS.EDIT_TAGS ||
        shortcutKey === SHORTCUTS.EDIT_NOTE ||
        shortcutKey === SHORTCUTS.EDIT_REFERENCE_DATE ||
        shortcutKey === SHORTCUTS.EDIT_SOURCE
      ) {
        setActiveTab("1");
      }
    }
  }, [shortcutKey]);

  return (
    <div className="tabs-antstrap">
      <Nav tabs className="no-select">
        {tabs.map((tab) => (
          <NavItem key={tab.id}>
            <NavLink
              id={`tab-${tab.id}`}
              className={classnames({ active: activeTab === tab.id })}
              onClick={() => toggleTab(tab.id)}
            >
              {tab.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {isLoading ? (
          <Spinner color="primary" className="align-self-center mt-2" />
        ) : (
          tabs.map((tab) => (
            <TabPane key={tab.id} tabId={tab.id}>
              {tab.component}
            </TabPane>
          ))
        )}
      </TabContent>
    </div>
  );
};

export default DetailsTabs;
