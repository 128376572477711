import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { SHORTCUTS } from "../../constants/shortcuts";
import {
  setIsOcrReaderActive,
  setIsOcrResultWindowActive,
} from "../../redux/slices/ocr-reader-slice";
import {
  resetShortcutKey,
  setShortcutKey,
} from "../../redux/slices/shortcut-slice";
import ImageDetailsPanel from "./image-details-panel";
import ImageViewerPanel from "./image-viewer-panel";
import {
  fetchFileDetailsAction,
  getCurrentFileDetails,
  resetActiveFileDetails,
} from "../../redux/slices/active-folder-slice";

const ImageViewerModal = ({ isOpen, toggle, files, currentFileIndex }) => {
  const dispatch = useDispatch();

  // Caching files locally so that moving image to other folder will not remove the image from image viewer immediately
  const [initialFiles, setInitialFiles] = useState(files);
  const [currentIndex, setCurrentIndex] = useState(currentFileIndex);
  const [currentFile, setCurrentFile] = useState(files[currentFileIndex]);
  const [isLoading, setIsLoading] = useState(true);

  const fileDetails = useSelector(getCurrentFileDetails);
  const user = useSelector((state) => state.user);

  // useEffect(() => {
  //   setCurrentFile(files[currentIndex]);
  // }, [files]);

  useEffect(() => {
    setIsLoading(true);
    setCurrentFile(initialFiles[currentIndex]);
    dispatch(
      fetchFileDetailsAction({
        fileId: initialFiles[currentIndex]._id,
        orgId: initialFiles[currentIndex].org_id,
        userId: user.id,
        successHandler: () => {
          setIsLoading(false);
        },
      })
    );
    dispatch(resetShortcutKey()); // Reset shortcut when file changed
  }, [currentIndex]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = (e) => {
    const activeElement = document.activeElement;
    // Do not do anything when keyboard keys are pressed inside text areas
    if (
      activeElement.tagName === "INPUT" ||
      activeElement.tagName === "TEXTAREA"
    ) {
      return;
    }

    if (e.code === "KeyT") {
      e.preventDefault(); // Prevent the default behavior of typing 'T' into the input
      dispatch(setShortcutKey(SHORTCUTS.EDIT_TAGS));
    } else if (e.code === "KeyN") {
      e.preventDefault(); // Prevent the default behavior of typing 'N' into the input
      dispatch(setShortcutKey(SHORTCUTS.EDIT_NOTE));
    } else if (e.code === "KeyD") {
      e.preventDefault(); // Prevent the default behavior of typing 'R' into the input
      dispatch(setShortcutKey(SHORTCUTS.EDIT_REFERENCE_DATE));
    } else if (e.code === "KeyS") {
      e.preventDefault(); // Prevent the default behavior of typing 'S' into the input
      dispatch(setShortcutKey(SHORTCUTS.EDIT_SOURCE));
    } else if (e.key === "Escape") {
      // Close the modal manually
      toggle();
    } else if (e.key === "ArrowRight") {
      handleNext();
    } else if (e.key === "ArrowLeft") {
      handlePrevious();
    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex < initialFiles.length - 1) {
        return prevIndex + 1;
      }
      return prevIndex;
    });
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex > 0) {
        return prevIndex - 1;
      }
      return prevIndex;
    });
  };

  const handleModalClosed = () => {
    // Force close OCR reader if active
    dispatch(setIsOcrReaderActive(false));
    dispatch(setIsOcrResultWindowActive(false));
    // Reset active file details
    dispatch(resetActiveFileDetails());
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onExit={handleModalClosed}
      className="image-viewer-modal"
      fullscreen
      keyboard={false} // Disable default Escape key behavior
    >
      <ModalHeader
        className="d-flex justify-content-between align-items-center"
        toggle={toggle}
      >
        {currentFile.alias?.length > 0 ? currentFile.alias : currentFile.name}
      </ModalHeader>
      <ModalBody>
        {/* Left Panel */}
        <ImageViewerPanel
          files={initialFiles}
          currentFile={currentFile}
          fileDetails={fileDetails}
          currentIndex={currentIndex}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
        {/* Right Panel */}
        <ImageDetailsPanel fileDetails={fileDetails} isLoading={isLoading} />
      </ModalBody>
    </Modal>
  );
};

export default ImageViewerModal;
