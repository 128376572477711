import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "reactstrap";
import { API_BASE_URL } from "../../../redux/services/base-url";
import FileContextMenu from "../menu/file-context-menu";

const FileThumbnailItem = ({ file, onClick, onDoubleClick }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const selectedItems = useSelector((state) => state.multiSelect.selectedItems);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };
  return (
    <>
      <Card
        className={`file-thumbnail-item no-select${
          selectedItems?.some((item) => item._id === file._id)
            ? " selected"
            : ""
        }`}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        data-selectable-item // a unique attribute to prevent this item from triggering clearSelection
      >
        <CardBody className="d-flex flex-column align-items-center">
          <div className="d-flex align-items-center w-100">
            <i className="material-icons file-icon">image</i>
            <CardTitle className="mb-0 file-name">
              {file.alias?.length > 0 ? file.alias : file.name}
            </CardTitle>
            <FileContextMenu
              file={file}
              onFileOpen={onDoubleClick}
              disabled={selectedItems?.length > 1}
            />
          </div>
          <div className="image-preview mt-2">
            {!isImageLoaded && (
              <div className="image-placeholder">
                <i className="material-icons">image</i>
              </div>
            )}
            <img
              src={`${API_BASE_URL}/api/images/thumbnail/${file._id}`}
              alt={file.name}
              draggable="false"
              onLoad={handleImageLoad}
              style={{
                display: isImageLoaded ? "block" : "none",
              }}
            />
            {!file.is_OCR ? (
              <div className="image-processing-overlay">
                <div class="splash-animation"></div>Processing Image
              </div>
            ) : null}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default FileThumbnailItem;
