import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "./delete-user-modal";
import EditUser from "./edit-user-modal";
import MenuButton from "../../common/menu-button";
import noImage from "../../assets/images/no-photo.svg"
import { deleteUser } from "../../../../redux/services/users-service";

export const UserProfiles = ({
  user,refreshData
}) => {
  const [modal, setModal] = useState(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const navigate = useNavigate();

  const toggle = () => {
    navigate("/app/edit-user", {
      state: { user },
    });
  };
  const toggleConfirmationModal = () =>
    setIsConfirmationModalOpen(!isConfirmationModalOpen);

  const handleDelete = async() => {
    await deleteUser(user._id, ()=>{
      refreshData(user.org_id)
      toggleConfirmationModal()
    },()=>{
      toggleConfirmationModal()
    })
  };

  return (
    <Fragment>
      <tr>
        <td className="text-right">
          <img
            className="image-rounded ml-2 mr-1"
            src={noImage}
          />
        </td>
        <td>
          {" "}
          {user.first_name === "" && user.last_name === ""
            ? "No Name"
            : `${user.first_name} ${user.last_name}`}
        </td>
        <td>
          { user.mobile_no=== null
            ? "No Number"
            : `${user.mobile_no}`}
        </td>
        <td>{user.email}</td>

        <td>
          <MenuButton
            menuButtons={[
              {
                name: "Edit",
                onClick: () => {
                  toggle();
                },
              },
              {
                name: "Delete",
                onClick: () => {
                  toggleConfirmationModal();
                },
              },
            ]}
          />
        </td>
      </tr>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        toggle={toggleConfirmationModal}
        onConfirm={handleDelete}
      />
      <EditUser user={user} modal={modal} toggle={toggle} />
    </Fragment>
  );
};
