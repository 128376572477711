import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReaderModeModal from "../reader-mode/reader-mode-modal";
import { getActiveFolderId } from "../../redux/slices/active-folder-slice";
import { useLocation } from "react-router-dom";
import { getSearchResultFiles } from "../../redux/slices/search-slice";
import routes from "../../layout/routes/routes";
import TooltipCustom from "../common/tooltip-custom";

const ReaderModeButton = () => {
  const location = useLocation();

  const [readerModeModal, setReaderModeModal] = useState(false);
  const [currentRoute, setCurrentRoute] = useState(null);
  const [readerModeFiles, setReaderModeFiles] = useState([]);

  const activeFolder = useSelector((state) => state.activeFolder);
  const activeFolderId = useSelector(getActiveFolderId);
  const searchFiles = useSelector(getSearchResultFiles);

  const sharedStorageRoute = routes.find(
    (route) => route.id === "SHARED_STORAGE"
  );
  const searchRoute = routes.find((route) => route.id === "SEARCH");
  const homeRoute = routes.find((route) => route.id === "HOME");

  useEffect(() => {
    const currentPath = location.pathname;
    setCurrentRoute(
      routes.find((route) => route.layout + route.path === currentPath)
    );
  }, [location]);

  useEffect(() => {
    if (
      currentRoute === sharedStorageRoute &&
      activeFolderId != null &&
      activeFolder.files?.length > 0
    ) {
      setReaderModeFiles(activeFolder.files);
    } else if (
      (currentRoute === homeRoute || currentRoute === searchRoute) &&
      searchFiles?.length > 0
    ) {
      setReaderModeFiles(searchFiles);
    } else setReaderModeFiles([]);
  }, [currentRoute, activeFolderId, activeFolder, searchFiles]);

  return (
    <>
      {readerModeFiles?.length > 0 && (
        <>
          <div
            id="reader-mode-btn"
            className="reader-mode-button icon-button"
            onClick={() => setReaderModeModal(true)}
          >
            {/* Testing SVG icons */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 -960 960 960"
              width="20px"
              fill="#212529"
            >
              <path d="M240-80q-50 0-85-35t-35-85v-120h120v-560h600v680q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-600H320v480h360v120q0 17 11.5 28.5T720-160ZM360-600v-80h360v80H360Zm0 120v-80h360v80H360ZM240-160h360v-80H200v40q0 17 11.5 28.5T240-160Zm0 0h-40 400-360Z" />
            </svg>
          </div>
          <TooltipCustom
            text="Reader Mode"
            target={"reader-mode-btn"}
            placement="top"
          />
        </>
      )}
      <ReaderModeModal
        isOpen={readerModeModal}
        toggle={() => setReaderModeModal(!readerModeModal)}
        files={readerModeFiles}
      />
    </>
  );
};

export default ReaderModeButton;
