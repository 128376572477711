import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, FormGroup, Input } from "reactstrap";

import { UserProfiles } from "./user-profiles";

export const UserTable = ({ user,refreshData }) => {

  const [searchString, setSearchString] = useState("");


  const isPresent = (user) => {
    if (
      searchString.trim() === "" 
    ) {
      return true;
    } else {
      let result = true;
      if (searchString.trim() != "") {
        if (
          (user.first_name &&
            user.first_name
              .toLowerCase()
              .includes(searchString.toLowerCase())) ||
          (user.last_name &&
            user.last_name
              .toLowerCase()
              .includes(searchString.toLowerCase())) ||
          user.email.includes(searchString.toLowerCase()) ||
          user.mobile_no
            .replace("-", "")
            .includes(searchString.toLowerCase())
        ) {
          result = true;
        } else {
          result = false;
        }
      }
    

      return result;
    }
  };
  return (
    <Fragment>
      <div className="pt-3 row m-0">
        <Col sm="12" md="4" className="pr-0">
          <FormGroup className="form-inline float-right">
            <div className="input-group">
              <div className="input-group-prepend ">
                <i className="material-icons input-group-text bg-white h6">
                  search
                </i>
              </div>
              <Input
                type="text"
                className="form-control border-left-0"
                placeholder="Search User"
                value={searchString}
                onChange={(e) => {
                  setSearchString(e.target.value);
                }}
              />
            </div>
          </FormGroup>
        </Col>
      </div>
      <div>
        <table className="px-2 border table-antstrap table">
          <thead className="bg-gray">
            <tr>
              <th></th>
              <th>NAME</th>
              <th>PHONE NUMBER</th>
              <th>EMAIL</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {user.map(
              (user) =>
                
                isPresent(user) && (
                  <UserProfiles
                    user={user}
                    refreshData={refreshData}
                    // settings={settings}
                  />
                )
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};
