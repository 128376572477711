import React, { useEffect, useRef, useState } from "react";
import InputWithSuggestions from "../common/input-with-suggestions";
import SelectedUsers from "./selected-users";
import UserSuggestion from "./user-suggestion";
import { fetchOrganizationUsers } from "../../redux/services/user-service";
import { useSelector } from "react-redux";

// ToDo: Map with real user data
// const usersList = [
//   {
//     id: 1,
//     name: "John Doe",
//     email: "johndoe@example.com",
//     thumbnail: "path/to/thumbnail1",
//   },
//   {
//     id: 2,
//     name: "Jane Smith",
//     email: "janesmith@example.com",
//     thumbnail: "path/to/thumbnail2",
//   },
//   {
//     id: 3,
//     name: "Maintenance Department Group",
//   },
//   {
//     id: 4,
//     name: "Hitesh Sharma",
//     email: "hiteshsharma@example.com",
//     thumbnail: "path/to/thumbnail2",
//   },
//   {
//     id: 5,
//     name: "Jayavant Valkunje",
//     email: "jayavant9@example.com",
//     thumbnail: "path/to/thumbnail2",
//   },
//   {
//     id: 6,
//     name: "Dhiraj Patil",
//     email: "dhirajp@example.com",
//     thumbnail: "path/to/thumbnail2",
//   },
//   {
//     id: 7,
//     name: "Dhiraj More",
//     email: "mored@example.com",
//     thumbnail: "path/to/thumbnail2",
//   },
//   {
//     id: 8,
//     name: "Sushant Patil",
//     email: "sp2024@example.com",
//     thumbnail: "path/to/thumbnail2",
//   },
//   {
//     id: 9,
//     name: "Dhiraj Kolhapur",
//     email: "dkop@example.com",
//     thumbnail: "path/to/thumbnail2",
//   },
// ];

const UserSelectionComponent = ({ selectedUsers, setSelectedUsers }) => {
  const [currentInput, setCurrentInput] = useState("");
  const [userSuggestions, setUserSuggestions] = useState([]);
  const organization = useSelector(state=>state.organization)

  const [usersList, setUsersList] = useState([]);

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input field
    }
    fetchUsers();
  }, []);

  const fetchUsers = async()=>{
    await fetchOrganizationUsers(organization.id,(users)=>{
      setUsersList(users)
    },()=>{

    })
  }

  const handleInputChange = (e) => {
    const value = e.target.value;
    setCurrentInput(value);

    if (value.length > 0) {
      const filteredUsers = usersList.filter(
        (user) =>
          user.first_name?.toLowerCase().includes(value.toLowerCase()) ||
          user.last_name?.toLowerCase().includes(value.toLowerCase()) ||
          user.email?.toLowerCase().includes(value.toLowerCase())
      );

      const usersToDisplay = filteredUsers.map((user) => (
        <UserSuggestion key={user._id} user={user} />
      ));
      setUserSuggestions(usersToDisplay);
    } else {
      setUserSuggestions([]);
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.key === ",") {
      e.preventDefault();
      if (currentInput.trim().length > 0) {
        const matchedUser = usersList.find(
          (user) =>
            user.first_name?.toLowerCase() === currentInput.trim().toLowerCase() ||
            user.last_name?.toLowerCase() === currentInput.trim().toLowerCase() ||
            user.email?.toLowerCase() === currentInput.trim().toLowerCase()
        );

        if (matchedUser) {
          addUser(matchedUser);
        }
      }
    }
  };

  const handleSuggestionClick = (userSuggestionComponent) => {
    addUser(userSuggestionComponent.props.user);
  };

  const addUser = (user) => {
    // Prevent duplicate selection
    if (user && !selectedUsers.find((u) => u._id === user._id)) {
      setSelectedUsers([...selectedUsers, user]);
    }
    // Clear input and suggestions, then refocus
    setCurrentInput("");
    setUserSuggestions([]);
    inputRef.current.focus();
  };

  const handleRemoveUser = (userId) => {
    setSelectedUsers(selectedUsers.filter((user) => user._id !== userId));
  };

  return (
    <>
      <SelectedUsers
        selectedUsers={selectedUsers}
        onRemoveUser={handleRemoveUser}
      />
      <InputWithSuggestions
        ref={inputRef}
        placeholderText={"Add people and groups"}
        currentInput={currentInput}
        handleInputChange={handleInputChange}
        handleInputKeyPress={handleInputKeyPress}
        suggestions={userSuggestions}
        handleSuggestionClick={handleSuggestionClick}
      />
    </>
  );
};

export default UserSelectionComponent;
