import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadAllAction,
  downloadFileAction,
} from "../../../redux/slices/active-folder-slice";
import {
  addToFavoritesAction,
  removeFromFavoritesAction,
} from "../../../redux/slices/favorites-slice";
import {
  deleteForeverAction,
  moveToTrashAction,
  restoreFromTrashAction,
} from "../../../redux/slices/trash-slice";
import ConfirmationModal from "../../common/confirmation-modal";
import ContextMenu from "../../common/context-menu";
import FolderTreeSelectionModal, {
  FOLDER_TREE_SELECTION_MODES,
} from "../../folder-tree/folder-tree-selection-modal";
import ReaderModeModal from "../../reader-mode/reader-mode-modal";
import ShareModal from "../../share/share-modal";

const MultiSelectContextMenu = ({ folders, files }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [isAllAreFavorites, setIsAllAreFavorites] = useState(false);
  const [isAllAreTrashed, setIsAllAreTrashed] = useState(false);
  const [isAllAreFiles, setIsAllAreFiles] = useState(false);
  const [folderTreeSelectionModal, setFolderTreeSelectionModal] =
    useState(false);
  const [deleteForeverConfirmationModal, setdeleteForeverConfirmationModal] =
    useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [readerModeModal, setReaderModeModal] = useState(false);
  const toggleFolderTreeSelectionModal = () => {
    setFolderTreeSelectionModal(!folderTreeSelectionModal);
  };
  const toggleDeleteForeverConfirmationModal = () => {
    setdeleteForeverConfirmationModal(!deleteForeverConfirmationModal);
  };
  const toggleShareModal = () => setShareModal(!shareModal);

  useEffect(() => {
    const changedItemsList = [...folders, ...files];
    // Check if all items are favorites
    setIsAllAreFavorites(changedItemsList.every((item) => item.is_favorite));
    // Check if all items are in trash
    setIsAllAreTrashed(changedItemsList.every((item) => item.is_bin));
    // Check if all items are files
    setIsAllAreFiles(files?.length > 0 && (!folders || folders.length === 0));
  }, [folders, files]);

  const handleDownloadClick = () => {
    // In case of single file, dispatch single file download api
    const allItemsCount = (files?.length || 0) + (folders?.length || 0);
    if (allItemsCount === 1 && files?.length === 1) {
      dispatch(
        downloadFileAction({
          file: files[0],
        })
      );
    } else {
      dispatch(
        downloadAllAction({
          orgId:
            files.length > 0
              ? files[0].org_id
              : folders.length > 0
              ? folders[0].org_id
              : "",
          userId: user.id,
          folders: folders,
          files: files,
        })
      );
    }
  };

  const handleReaderModeClick = () => {
    setReaderModeModal(true);
  };

  const handleShareClick = () => {
    toggleShareModal();
  };

  const handleAddToFavoritesClick = () => {
    dispatch(
      addToFavoritesAction({
        orgId:
          files.length > 0
            ? files[0].org_id
            : folders.length > 0
            ? folders[0].org_id
            : "",
        userId: user.id,
        folderIds:
          folders.length > 0 ? folders.map((folder) => folder._id) : [],
        fileIds: files.length > 0 ? files.map((file) => file._id) : [],
      })
    );
  };

  const handleRemoveFromFavoritesClick = () => {
    dispatch(
      removeFromFavoritesAction({
        orgId:
          files.length > 0
            ? files[0].org_id
            : folders.length > 0
            ? folders[0].org_id
            : "",
        userId: user.id,
        folderIds:
          folders.length > 0 ? folders.map((folder) => folder._id) : [],
        fileIds: files.length > 0 ? files.map((file) => file._id) : [],
      })
    );
  };

  const handleMoveToClick = () => {
    setFolderTreeSelectionModal(true);
  };

  const handleMoveToTrashClick = () => {
    dispatch(
      moveToTrashAction({
        orgId:
          files.length > 0
            ? files[0].org_id
            : folders.length > 0
            ? folders[0].org_id
            : "",
        userId: user.id,
        folderIds:
          folders.length > 0 ? folders.map((folder) => folder._id) : [],
        fileIds: files.length > 0 ? files.map((file) => file._id) : [],
      })
    );
  };

  const handleRestoreFromTrashClick = () => {
    dispatch(
      restoreFromTrashAction({
        orgId:
          files.length > 0
            ? files[0].org_id
            : folders.length > 0
            ? folders[0].org_id
            : "",
        userId: user.id,
        folderIds:
          folders.length > 0 ? folders.map((folder) => folder._id) : [],
        fileIds: files.length > 0 ? files.map((file) => file._id) : [],
      })
    );
  };

  const handleDeleteForeverClick = () => {
    toggleDeleteForeverConfirmationModal();
  };

  const handleDeleteForever = () => {
    dispatch(
      deleteForeverAction({
        folderIds:
          folders.length > 0 ? folders.map((folder) => folder._id) : [],
        fileIds: files.length > 0 ? files.map((file) => file._id) : [],
        successHandler: () => {
          toggleDeleteForeverConfirmationModal();
        },
      })
    );
  };

  const renderDeleteForeverModalDescription = () => {
    const totalCount = (files?.length || 0) + (folders?.length || 0);

    const description = `${
      totalCount === 1 ? `${totalCount} item` : `${totalCount} items`
    } will be deleted permanently, and you won't be able to restore ${
      totalCount === 1 ? "it" : "them"
    } again.`;

    return description;
  };

  const menuItems = [
    {
      label: "Download",
      icon: "download",
      onClick: handleDownloadClick,
    },
    ...(isAllAreFiles
      ? [
          {
            label: "Open in reader mode",
            icon: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="19px"
                viewBox="0 -960 960 960"
                width="19px"
                style={{ marginTop: "-10px" }}
                fill="#212529"
              >
                <path d="M240-80q-50 0-85-35t-35-85v-120h120v-560h600v680q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-600H320v480h360v120q0 17 11.5 28.5T720-160ZM360-600v-80h360v80H360Zm0 120v-80h360v80H360ZM240-160h360v-80H200v40q0 17 11.5 28.5T240-160Zm0 0h-40 400-360Z" />
              </svg>
            ),
            onClick: handleReaderModeClick,
          },
        ]
      : []), // Add the item only if isAllAreFiles is true
    {
      divider: true, // This will add a divider
    },
    {
      label: "Move to...",
      icon: "drive_file_move",
      onClick: handleMoveToClick,
    },
    {
      divider: true, // This will add a divider
    },
    {
      label: "Share",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -860 960 960"
          width="20px"
          fill="#212529"
        >
          <path d="M680-80q-50 0-85-35t-35-85q0-6 3-28L282-392q-16 15-37 23.5t-45 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q24 0 45 8.5t37 23.5l281-164q-2-7-2.5-13.5T560-760q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-24 0-45-8.5T598-672L317-508q2 7 2.5 13.5t.5 14.5q0 8-.5 14.5T317-452l281 164q16-15 37-23.5t45-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T720-200q0-17-11.5-28.5T680-240q-17 0-28.5 11.5T640-200q0 17 11.5 28.5T680-160ZM200-440q17 0 28.5-11.5T240-480q0-17-11.5-28.5T200-520q-17 0-28.5 11.5T160-480q0 17 11.5 28.5T200-440Zm480-280q17 0 28.5-11.5T720-760q0-17-11.5-28.5T680-800q-17 0-28.5 11.5T640-760q0 17 11.5 28.5T680-720Zm0 520ZM200-480Zm480-280Z" />
        </svg>
      ),
      onClick: handleShareClick,
    },
    isAllAreFavorites
      ? {
          label: "Remove from favourites",
          icon: "star",
          filledIcon: true,
          onClick: handleRemoveFromFavoritesClick,
        }
      : {
          label: "Add to favourites",
          icon: "grade",
          onClick: handleAddToFavoritesClick,
        },
    {
      divider: true, // This will add a divider
    },
    {
      label: "Move to trash",
      icon: "delete",
      onClick: handleMoveToTrashClick,
    },
  ];

  const trashMenuItems = [
    {
      label: "Restore",
      icon: "restore",
      onClick: handleRestoreFromTrashClick,
    },
    {
      label: "Delete Forever",
      icon: "delete_forever",
      onClick: handleDeleteForeverClick,
    },
  ];

  return (
    <>
      <ContextMenu
        menuItems={isAllAreTrashed ? trashMenuItems : menuItems}
        type="more"
        disabled={false}
      />
      <ConfirmationModal
        isOpen={deleteForeverConfirmationModal}
        toggle={toggleDeleteForeverConfirmationModal}
        title="Delete items forever?"
        description={renderDeleteForeverModalDescription()}
        confirmButtonText="Delete forever"
        onConfirm={handleDeleteForever}
        isDanger={true}
      />
      <FolderTreeSelectionModal
        isOpen={folderTreeSelectionModal}
        toggle={toggleFolderTreeSelectionModal}
        type={FOLDER_TREE_SELECTION_MODES.MOVE}
        folders={folders.length > 0 ? folders : []}
        files={files.length > 0 ? files : []}
      />
      <ShareModal
        isOpen={shareModal}
        toggle={toggleShareModal}
        files={files}
        folders={folders}
      />
      <ReaderModeModal
        isOpen={readerModeModal}
        toggle={() => setReaderModeModal(!readerModeModal)}
        files={files}
      />
    </>
  );
};

export default MultiSelectContextMenu;
