import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Progress } from "reactstrap";
import { setIsOcrResultWindowActive } from "../../../redux/slices/ocr-reader-slice";

const OcrReaderResultWindow = ({
  isProcessing,
  progress,
  status,
  ocrText,
  isError,
  isProcessCompleted,
}) => {
  const dispatch = useDispatch();

  const [text, setText] = useState("");
  const [progressBarKey, setProgressBarKey] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showCopiedBubble, setShowCopiedBubble] = useState(false);

  useEffect(() => {
    setText(ocrText.trim());
  }, [ocrText]);

  useEffect(() => {
    if (progress == 0) {
      setProgressBarKey((prevKey) => prevKey + 1);
    }
  }, [progress]);

  useEffect(() => {
    if (isProcessCompleted && !isExpanded) setIsExpanded(true);
  }, [isProcessCompleted]);

  useEffect(() => {
    if (showCopiedBubble) {
      const timeoutId = setTimeout(() => setShowCopiedBubble(false), 1500);
      return () => clearTimeout(timeoutId); // Cleanup on re-renders
    }
  }, [showCopiedBubble]);

  const handleCopy = (event) => {
    navigator.clipboard.writeText(text);
    setShowCopiedBubble(true);

    // Remove focus from the button immediately after clicking
    event.target.blur();
  };

  const handleClose = () => {
    dispatch(setIsOcrResultWindowActive(false));
  };

  return (
    <div className="ocr-reader-result-window">
      <div className="rw-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="22px"
          viewBox="0 -960 960 960"
          width="22px"
          fill={"#FF4433"}
        >
          <path d="M80-720v-200h200v80H160v120H80Zm720 0v-120H680v-80h200v200h-80ZM80-40v-200h80v120h120v80H80Zm600 0v-80h120v-120h80v200H680ZM280-240h400v-480H280v480Zm0 80q-33 0-56.5-23.5T200-240v-480q0-33 23.5-56.5T280-800h400q33 0 56.5 23.5T760-720v480q0 33-23.5 56.5T680-160H280Zm80-400h240v-80H360v80Zm0 120h240v-80H360v80Zm0 120h240v-80H360v80Zm-80 80v-480 480Z" />
        </svg>
        <div className="title">OCR Reader</div>
      </div>
      <div className="rw-progress">
        <Progress
          key={progressBarKey} // Use key prop to force instant reset and avoid animation while resetting progress bar to 0
          color="primary"
          value={progress}
          style={{ height: "5px" }}
        />
        <div className={`progress-text ${isError ? "error" : "text-muted"}`}>
          {status}
        </div>
      </div>
      <div className={`${isExpanded ? "visible-item" : "collapsed-item"}`}>
        <Input
          className="rw-textarea"
          type="textarea"
          rows="5"
          value={text}
          onChange={(e) => setText(e.target.value)}
          style={{ resize: "none" }}
          readOnly={isProcessing ? true : false}
        />
      </div>
      <div className={`${isExpanded ? "visible-item" : "collapsed-item"}`}>
        <div className="rw-buttons-container">
          <div className="copy-button-container">
            <Button
              color="primary"
              onClick={(e) => handleCopy(e)}
              disabled={isProcessing ? true : false}
            >
              Copy
            </Button>
            {showCopiedBubble && <div className="copied-bubble">Copied!</div>}
          </div>
          <Button color="link" onClick={handleClose}>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OcrReaderResultWindow;
