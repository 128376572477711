import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  FormGroup,
  Input,
  Button,
  Form,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const EditUser = ({ modal, toggle, user }) => {
  const [currentUser, setCurrentUser] = useState({ ...user });
  const [currentUserAuth, setAuth] = useState({ ...user });
 

  const handleEdit = () => {
    // updateOrganizationUser({
    //   variables: {
    //     roles: currentUserAuth.roles,
    //     userId: currentUserAuth.userId,
    //     subOrganizations: currentUserAuth.subOrganizations,
    //     firstName: currentUser.firstName,
    //     lastName: currentUser.lastName,
    //     countryCode: currentUser.countryCode,
    //     mobileNumber: currentUser.mobileNumber,
    //   },
    // });

    toggle();
  };

  const handleInputChange = (phone) => {
    const [countryCode, mobileNumber] = phone.split(" ");
    setCurrentUser({
      ...currentUser,
      countryCode: countryCode,
      mobileNumber: mobileNumber,
    });
  };

  return (
    <Modal centered isOpen={modal} toggle={toggle} className="custom-modal">
      <ModalHeader toggle={toggle}>Update User</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstname">First Name</Label>
            <Input
              type="text"
              name="firstname"
              id="firstname"
              placeholder="Enter First name"
              value={currentUser.firstName}
              onChange={(e) =>
                setCurrentUser({ ...currentUser, firstName: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastname">Last Name</Label>
            <Input
              type="text"
              name="lastname"
              id="lastname"
              placeholder="Enter Last name"
              value={currentUser.lastName}
              onChange={(e) =>
                setCurrentUser({ ...currentUser, lastName: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="email address"
              value={currentUser.email}
              onChange={(e) =>
                setCurrentUser({ ...currentUser, email: e.target.value })
              }
            />
          </FormGroup>
         
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => handleEdit()}>
          Edit User
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditUser;
