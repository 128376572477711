import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import YooptaEditor, { createYooptaEditor } from "@yoopta/editor";
import Paragraph from "@yoopta/paragraph";
import Blockquote from "@yoopta/blockquote";
import { HeadingOne, HeadingTwo, HeadingThree } from "@yoopta/headings";
import Table from "@yoopta/table";
import Divider from "@yoopta/divider";
import { NumberedList, BulletedList, TodoList } from "@yoopta/lists";
import Accordion from "@yoopta/accordion";
import Code from "@yoopta/code";
import Embed from "@yoopta/embed";
import Image from "@yoopta/image";
import Link from "@yoopta/link";
import Callout from "@yoopta/callout";
import LinkTool, { DefaultLinkToolRender } from "@yoopta/link-tool";
import ActionMenuList, {
  DefaultActionMenuRender,
} from "@yoopta/action-menu-list";
import Toolbar, { DefaultToolbarRender } from "@yoopta/toolbar";
import {
  Bold,
  Italic,
  CodeMark,
  Underline,
  Strike,
  Highlight,
} from "@yoopta/marks";
import {
  createFolderNoteAction,
  getFolderNoteAction,
  updateFolderNoteAction,
} from "../../redux/slices/active-folder-slice";

const plugins = [
  Paragraph,
  Blockquote,
  Table,
  Divider.extend({
    elementProps: {
      divider: (props) => ({
        ...props,
        color: "#007aff",
      }),
    },
  }),
  Accordion,
  Code,
  Embed,
  Image,
  Link,
  Callout,
  NumberedList,
  BulletedList,
  TodoList,
  HeadingOne,
  HeadingTwo,
  HeadingThree,
];

const TOOLS = {
  Toolbar: {
    tool: Toolbar,
    render: DefaultToolbarRender,
  },
  ActionMenu: {
    tool: ActionMenuList,
    render: DefaultActionMenuRender,
  },
  LinkTool: {
    tool: LinkTool,
    render: DefaultLinkToolRender,
  },
};

const MARKS = [Bold, Italic, CodeMark, Underline, Strike, Highlight];

const ReaderNoteEditorPanel = ({ editorRef }) => {
  const dispatch = useDispatch();
  const activeFolderId = useSelector(
    (state) => state.activeFolder.activeFolderId
  );
  const orgId = useSelector((state) => state.user.org_id);
  const folderNote = useSelector((state) => state.activeFolder.folderNote);
  const [editorContent, setEditorContent] = useState({});
  const editor = useMemo(() => createYooptaEditor(), []);

  const transformApiDataToEditorFormat = (apiData) => {
    if (!apiData || !apiData.notes) return {};
    return apiData.notes;
  };

  useEffect(() => {
    if (activeFolderId) {
      dispatch(getFolderNoteAction({ folderId: activeFolderId, orgId }));
    }
  }, [activeFolderId, dispatch, orgId]);

  useEffect(() => {
    if (folderNote && folderNote.notes) {
      const transformedContent = transformApiDataToEditorFormat(folderNote);
      setEditorContent(transformedContent);
      editor.setEditorValue(transformedContent);
    }
  }, [folderNote]);

  const handleChange = useCallback((newContent) => {
    // setEditorContent(newContent);
    // editor.setEditorValue(newContent);
  }, []);

  useEffect(() => {
    console.log(editorContent);
  }, [editorContent]);

  const saveNote = useCallback(() => {
    if (!activeFolderId) return;

    const contentToSave = editor.getEditorValue();

    if (folderNote) {
      dispatch(
        updateFolderNoteAction({
          noteId: folderNote._id,
          notes: contentToSave,
          folderId: activeFolderId,
          orgId,
        })
      );
    } else {
      dispatch(
        createFolderNoteAction({
          notes: contentToSave,
          folderId: activeFolderId,
          orgId,
        })
      );
    }
  }, [activeFolderId, dispatch, editor, folderNote, orgId]);

  React.useImperativeHandle(editorRef, () => ({
    saveNote,
  }));

  return (
    <div className="notion-like-editor">
      <div className="editor-wrapper">
        <YooptaEditor
          editor={editor}
          placeholder="Type text.."
          plugins={plugins}
          tools={TOOLS}
          marks={MARKS}
          autoFocus
          style={{ width: "100%", marginLeft: "3%" }}
          value={editorContent}
          onChange={handleChange}
        />
      </div>
      <style jsx>{`
        .notion-like-editor {
          background-color: #ffffff;
          padding: 40px;
          height: 100%;
          width: 60%;
          margin: 0 auto;
          box-shadow: rgb(15 15 15 / 37%) 0px 0px 0px 1px,
            rgb(15 15 15 / 51%) 0px 3px 6px, rgb(15 15 15 / 9%) 0px 9px 24px;
          border-radius: 10px;
        }
        .editor-wrapper {
          width: 100%;
          margin-left: 3%;
        }
      `}</style>
    </div>
  );
};

export default ReaderNoteEditorPanel;
