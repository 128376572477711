import React from "react";
import {
  formatDateToReadableString,
  getRelativeTime,
} from "../../utils/date-utils";
import TooltipCustom from "../common/tooltip-custom";
import DetailsTabs from "./tabs/details-tabs";

const ImageDetailsPanel = ({ fileDetails, isLoading }) => {
  const renderDateTimeInfo = () => {
    if (!fileDetails) {
      return "";
    }
    return "Created " + getRelativeTime(fileDetails.created_at);
  };

  return (
    <>
      <div className="image-viewer-right">
        <div className="image-details">
          <strong>
            {fileDetails
              ? fileDetails?.user?.first_name +
                " " +
                fileDetails?.user?.last_name
              : "Unknown"}
          </strong>
          <p id="time-info">{renderDateTimeInfo()}</p>
        </div>
        <DetailsTabs file={fileDetails} isLoading={isLoading} />
      </div>
      <TooltipCustom
        text={formatDateToReadableString(fileDetails?.created_at)}
        target="time-info"
      />
    </>
  );
};

export default ImageDetailsPanel;
