import React from "react";
import { Button } from "reactstrap";
import noPhoto from "../../../../assets/images/no-photo.svg";

const SelectedUsers = ({ selectedUsers, onRemoveUser }) => (
  <div>
    {selectedUsers?.length > 0 ? (
      <div className="user-badge-container">
        {selectedUsers.map((user) => (
          <div key={user._id} className="user-badge">
            <img
              style={{ width: 20, borderRadius: "50%" }}
              draggable={false}
              src={noPhoto}
            />
            <div className="label">{user.first_name+" "+ user.last_name}</div>
            <Button close onClick={() => onRemoveUser(user._id)} />
          </div>
        ))}
      </div>
    ) : (
      <></>
    )}
  </div>
);

export default SelectedUsers;
