import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSelection } from "../../../redux/slices/multi-select-slice";
import MultiSelectContextMenu from "../../folders/menu/multi-select-context-menu";

const SelectedItemsBadge = () => {
  const dispatch = useDispatch();

  const selectedItems = useSelector((state) => state.multiSelect.selectedItems);

  const handleSelectionCloseClick = () => {
    dispatch(clearSelection());
  };
  return (
    <>
      {selectedItems?.length > 0 && (
        <div className="selected-items-container">
          <i
            className="material-icons icon-button"
            onClick={handleSelectionCloseClick}
          >
            {"close"}
          </i>
          <div className="selected-items-label">
            {selectedItems.length} selected
          </div>
          <MultiSelectContextMenu
            folders={selectedItems.filter((item) => item.type === "folder")}
            files={selectedItems.filter((item) => item.type === "file")}
          />
        </div>
      )}
    </>
  );
};

export default SelectedItemsBadge;
