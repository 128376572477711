import { createSlice } from "@reduxjs/toolkit";
import { updateFileDetailsAction } from "./active-folder-slice";
import {
  addToFavoritesAction,
  removeFromFavoritesAction,
} from "./favorites-slice";

const multiSelectSlice = createSlice({
  name: "multiSelect",
  initialState: {
    selectedItems: [],
    isSelectionMode: false,
    lastSelectedIndex: null, // Index of the last clicked item
  },
  reducers: {
    toggleSelection: (state, action) => {
      const { item, itemIndex, allItems, isControlPressed, isShiftPressed } =
        action.payload;

      // "allItems" has modified content. It has "type" field which "item" does not have.
      // So pushing item from allItems list instead of directly pushing the "item" from prop
      const itemToPush = allItems.find((allItem) => allItem._id === item._id);

      if (isShiftPressed && state.lastSelectedIndex !== null) {
        // Handle Shift-click: Select a range of items
        const start = Math.min(state.lastSelectedIndex, itemIndex);
        const end = Math.max(state.lastSelectedIndex, itemIndex);
        const range = allItems.slice(start, end + 1);

        // Add range to selected items (avoiding duplicates explicitly)
        const rangeWithoutDuplicates = range.filter(
          (rangeItem) =>
            !state.selectedItems.some(
              (selectedItem) => selectedItem._id === rangeItem._id
            )
        );

        state.selectedItems = [
          ...state.selectedItems,
          ...rangeWithoutDuplicates,
        ];
      } else if (isControlPressed) {
        // Handle Control-click: Toggle individual selection
        const index = state.selectedItems.findIndex(
          (selectedItem) => selectedItem._id === item._id
        );

        if (index > -1) {
          // Deselect the item
          state.selectedItems.splice(index, 1);
        } else {
          // Add item to selection
          state.selectedItems.push(itemToPush);
        }
      } else {
        // Handle normal click: Replace selection with the clicked item when Control/Shift is not pressed
        state.selectedItems = [itemToPush];
      }

      // Update selection mode
      state.isSelectionMode = state.selectedItems.length > 0;

      // Update last selected index
      state.lastSelectedIndex = itemIndex;
    },
    clearSelection: (state) => {
      state.selectedItems = [];
      state.isSelectionMode = false;
      state.lastSelectedIndex = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // ====================
      // Extra Reducers for favorites
      // ====================
      .addCase(addToFavoritesAction.fulfilled, (state, action) => {
        const { folderIds, fileIds } = action.payload;

        // Add to selectedItems
        state.selectedItems = state.selectedItems.map((item) =>
          fileIds.includes(item._id) || folderIds.includes(item._id)
            ? { ...item, is_favorite: true }
            : item
        );
      })
      .addCase(removeFromFavoritesAction.fulfilled, (state, action) => {
        const { folderIds, fileIds } = action.payload;

        // Remove from selectedItems
        state.selectedItems = state.selectedItems.map((item) =>
          fileIds.includes(item._id) || folderIds.includes(item._id)
            ? { ...item, is_favorite: false }
            : item
        );
      })
      // ====================
      // Extra Reducers for : updateFileDetailsAction
      // ====================
      .addCase(updateFileDetailsAction.fulfilled, (state, action) => {
        const fileToUpdate = state.selectedItems.find(
          (file) => file._id === action.payload.file._id
        );

        // Update details
        if (fileToUpdate) {
          action.payload.notes !== undefined &&
            (fileToUpdate.notes = action.payload.notes);
          action.payload.tags !== undefined &&
            (fileToUpdate.tags = action.payload.tags);
          if (action.payload.title != undefined) {
            fileToUpdate.title = action.payload.title;
            if (fileToUpdate?.alias && !fileToUpdate?.is_alias_custom) {
              fileToUpdate.alias = action.payload.title;
            }
          }
          action.payload.heading != undefined &&
            (fileToUpdate.heading = action.payload.heading);
          action.payload.marathi_text != undefined &&
            (fileToUpdate.marathi_text = action.payload.marathi_text);
          action.payload.person_names != undefined &&
            (fileToUpdate.person_names = action.payload.person_names);
          action.payload.reference_date != undefined &&
            (fileToUpdate.reference_date = action.payload.reference_date);
          action.payload.source != undefined &&
            (fileToUpdate.source = action.payload.source);
        }
      });
  },
});

export const { toggleSelection, clearSelection } = multiSelectSlice.actions;
export default multiSelectSlice.reducer;
