// userSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { downloadFile, downloadZip } from "../../utils/download-utils";
import { downloadAll, moveTo } from "../services/common-service";
import {
  fetchFileById,
  fetchFileFordownload,
  fetchFilesById,
  fetchUserDetailsById,
  updateFileDetails,
  updateImageName,
} from "../services/file-service";
import {
  fetchBreadcrumbForFolder,
  fetchFolderDetailsById,
  fetchFoldersById,
  updateFolderName,
} from "../services/folder-service";
import {
  createFolderNote,
  getFolderNote,
  updateFolderNote,
} from "../services/note-service";
import { createFolderAction } from "../slices/folders-slice";
import {
  addToFavoritesAction,
  removeFromFavoritesAction,
} from "./favorites-slice";
import { clearSelection } from "./multi-select-slice";
import { moveToTrashAction } from "./trash-slice";

const initialState = {
  activeFolderId: null,
  activeFolderDetails: null,
  isShowUploadSection: false,
  breadcrumbs: [],
  rootBreadcrumb: {},
  folders: [],
  files: [],
  isLoadingFetchFolders: true,
  isLoadingFetchFiles: true,
  currentFileUserDetails: null,
  activeFileDetails: null,
};

export const fetchFolderContents = createAsyncThunk(
  "folder/folders",
  async ({ orgId, folderId, userId, successHandler }, { dispatch }) => {
    const data = await fetchFoldersById(
      orgId,
      folderId,
      userId,
      successHandler
    );
    const folders = data.content.folders;
    return { folders };
  }
);

export const fetchFolderDetails = createAsyncThunk(
  "folder/details",
  async ({ orgId, folderId }, { dispatch }) => {
    const data = await fetchFolderDetailsById(orgId, folderId);
    return data; // Return user data for updating the state
  }
);

export const fetchFilesInFolder = createAsyncThunk(
  "folder/files",
  async ({ orgId, folderId, userId, successHandler }, { dispatch }) => {
    const data = await fetchFilesById(orgId, folderId, userId, successHandler);
    return data.images ? data.images.filter((file) => !file.is_bin) : [];
  }
);

export const fetchUserDetailsForFile = createAsyncThunk(
  "folder/userDetails",
  async ({ orgId, userId }, { dispatch }) => {
    const data = await fetchUserDetailsById(orgId, userId);
    return data;
  }
);

export const fetchFileDetailsAction = createAsyncThunk(
  "folder/fileDetails",
  async ({ orgId, fileId, userId, successHandler }, { dispatch }) => {
    const data = await fetchFileById(orgId, fileId, userId, successHandler);
    return data; // Return user data for updating the state
  }
);

export const updateFileDetailsAction = createAsyncThunk(
  "folder/updateFileDetails",
  async ({ file, userId, updates, successHandler }) => {
    const data = await updateFileDetails(file, userId, updates, successHandler);
    return {
      data: data,
      file: file,
      ...updates,
    };
  }
);

export const renameFile = createAsyncThunk(
  "folder/renameFile",
  async ({ file, newName, successHandler }, { dispatch }) => {
    const data = await updateImageName(
      file._id,
      newName,
      file.org_id,
      file.lang,
      file.created_by,
      successHandler
    );
    return { data: data, fileId: file._id, newName: newName };
  }
);

export const renameFolder = createAsyncThunk(
  "folder/renameFolder",
  async ({ folder, newName, successHandler }, { dispatch }) => {
    const data = await updateFolderName(
      folder._id,
      folder.user_id,
      newName,
      successHandler
    );
    return { data: data, folderId: folder._id, newName: newName };
  }
);

export const fetchActiveFolderBreadcrumb = createAsyncThunk(
  "folder/breadcrumb",
  async ({ folderId }, { dispatch }) => {
    const data = await fetchBreadcrumbForFolder(folderId);
    return data; // Return user data for updating the state
  }
);

export const downloadFileAction = createAsyncThunk(
  "folder/downloadFile",
  async ({ file, successHandler, errorHandler }) => {
    const data = await fetchFileFordownload(
      file.org_id,
      file._id,
      successHandler,
      errorHandler
    );
    return { data: data, file: file };
  }
);

export const downloadAllAction = createAsyncThunk(
  "folder/downloadAll",
  async ({ orgId, userId, folders, files, successHandler }, { dispatch }) => {
    const data = await downloadAll(
      orgId,
      userId,
      folders.length > 0 ? folders.map((folder) => folder._id) : [],
      files.length > 0 ? files.map((file) => file._id) : [],
      successHandler
    );
    return { data: data, folders: folders, files: files };
  }
);

export const moveToAction = createAsyncThunk(
  "folder/moveToAction",
  async (
    {
      orgId,
      userId,
      folderIds,
      fileIds,
      targetFolderId,
      successHandler,
      errorHandler,
    },
    { dispatch }
  ) => {
    const data = await moveTo(
      orgId,
      userId,
      folderIds,
      fileIds,
      targetFolderId,
      successHandler,
      errorHandler
    );
    dispatch(clearSelection()); // Clear multi selected items if any
    return {
      data: data,
      folderIds: folderIds,
      fileIds: fileIds,
      targetFolderId: targetFolderId,
    };
  }
);

export const createFolderNoteAction = createAsyncThunk(
  "folder/createNote",
  async ({ notes, folderId, orgId }) => {
    const data = await createFolderNote({ notes, folderId, orgId });
    return data;
  }
);

export const getFolderNoteAction = createAsyncThunk(
  "folder/getNote",
  async ({ folderId, orgId }) => {
    const data = await getFolderNote(folderId, orgId);
    return data;
  }
);

export const updateFolderNoteAction = createAsyncThunk(
  "folder/updateNote",
  async ({ noteId, notes, folderId, orgId }) => {
    const data = await updateFolderNote({ noteId, notes, folderId, orgId });
    return data;
  }
);

const activeFolderSlice = createSlice({
  name: "activeFolder",
  initialState,
  reducers: {
    showUploadSection: (state, action) => {
      return {
        ...state,
        isShowUploadSection: true,
      };
    },
    hideUploadSection: (state, action) => {
      return {
        ...state,
        isShowUploadSection: false,
      };
    },
    toggleUploadSection: (state, action) => {
      return {
        ...state,
        isShowUploadSection: !state.isShowUploadSection,
      };
    },

    setActiveFolderId: (state, action) => {
      if (action.payload === null) {
        return {
          ...state,
          activeFolderId: null,
          files: [], // Reset files to empty array
          folders: [], // Reset folders to empty array
        };
      }
      return {
        ...state,
        activeFolderId: action.payload,
      };
    },

    setBreadCrumb: (state, action) => {
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    },
    setRootBreadCrumb: (state, action) => {
      return {
        ...state,
        rootBreadcrumb: action.payload,
      };
    },
    updateFileLatestInformation: (state, action) => {
      let nState = { ...state };
      let files = nState.files.map((file) => {
        if (file._id == action.payload._id) {
          console.log("action");
          return action.payload;
        } else {
          return file;
        }
      });
      nState.status = "";
      nState.files = [...files];
      console.log(action);
      return nState;
    },
    resetActiveFileDetails: (state) => {
      return {
        ...state,
        activeFileDetails: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // ====================
      // Extra Reducers for : fetchFolderContents
      // ====================
      .addCase(fetchFolderContents.pending, (state) => {
        state.isLoadingFetchFolders = true;
      })
      .addCase(fetchFolderContents.fulfilled, (state, action) => {
        state.folders = action.payload.folders;
        state.isLoadingFetchFolders = false;
      })

      // ====================
      // Extra Reducers for : fetchFilesInFolder
      // ====================
      .addCase(fetchFilesInFolder.pending, (state) => {
        state.isLoadingFetchFiles = true;
      })
      .addCase(fetchFilesInFolder.fulfilled, (state, action) => {
        state.files = action.payload;
        state.isLoadingFetchFiles = false;
      })

      // ====================
      // Extra Reducers for : fetchActiveFolderBreadcrumb
      // ====================
      .addCase(fetchActiveFolderBreadcrumb.fulfilled, (state, action) => {
        // Update the state with user data
        state.breadcrumbs = action.payload.hierarchy
          ? [...action.payload.hierarchy]
          : [];
      })

      // ====================
      // Extra Reducers for : fetchUserDetailsForFile
      // ====================
      .addCase(fetchUserDetailsForFile.fulfilled, (state, action) => {
        state.currentFileUserDetails = action.payload;
      })

      // ====================
      // Extra Reducers for : fetchFileDetailsAction
      // ====================
      .addCase(fetchFileDetailsAction.fulfilled, (state, action) => {
        state.activeFileDetails = action.payload;
      })

      // ====================
      // Extra Reducers for : updateFileDetailsAction
      // ====================
      .addCase(updateFileDetailsAction.fulfilled, (state, action) => {
        const fileToUpdate = state.files?.find(
          (file) => file._id === action.payload.file._id
        );

        // Update details in all files
        if (fileToUpdate) {
          action.payload.notes !== undefined &&
            (fileToUpdate.notes = action.payload.notes);
          action.payload.tags !== undefined &&
            (fileToUpdate.tags = action.payload.tags);
          if (action.payload.title != undefined) {
            fileToUpdate.title = action.payload.title;
            if (fileToUpdate?.alias && !fileToUpdate?.is_alias_custom) {
              fileToUpdate.alias = action.payload.title;
            }
          }
          action.payload.heading != undefined &&
            (fileToUpdate.heading = action.payload.heading);
          action.payload.marathi_text != undefined &&
            (fileToUpdate.marathi_text = action.payload.marathi_text);
          action.payload.person_names != undefined &&
            (fileToUpdate.person_names = action.payload.person_names);
          action.payload.reference_date != undefined &&
            (fileToUpdate.reference_date = action.payload.reference_date);
          action.payload.source != undefined &&
            (fileToUpdate.source = action.payload.source);
        }

        // Also update current active file details
        if (state.activeFileDetails._id === action.payload.file._id) {
          action.payload.notes !== undefined &&
            (state.activeFileDetails.notes = action.payload.notes);
          action.payload.tags !== undefined &&
            (state.activeFileDetails.tags = action.payload.tags);
          if (action.payload.title != undefined) {
            state.activeFileDetails.title = action.payload.title;
            if (
              state.activeFileDetails?.alias &&
              !state.activeFileDetails?.is_alias_custom
            ) {
              state.activeFileDetails.alias = action.payload.title;
            }
          }
          action.payload.heading != undefined &&
            (state.activeFileDetails.heading = action.payload.heading);
          action.payload.marathi_text != undefined &&
            (state.activeFileDetails.marathi_text =
              action.payload.marathi_text);
          action.payload.person_names != undefined &&
            (state.activeFileDetails.person_names =
              action.payload.person_names);
          action.payload.reference_date != undefined &&
            (state.activeFileDetails.reference_date =
              action.payload.reference_date);
          action.payload.source != undefined &&
            (state.activeFileDetails.source = action.payload.source);
        }
      })

      // ====================
      // Extra Reducers for : moveToAction
      // ====================
      .addCase(moveToAction.fulfilled, (state, action) => {
        const { folderIds, fileIds, targetFolderId } = action.payload;

        if (targetFolderId !== state.activeFolderId) {
          state.folders = state.folders?.filter(
            (folder) => !folderIds.includes(folder._id)
          );

          state.files = state.files?.filter(
            (file) => !fileIds.includes(file._id)
          );
        }
      })

      // ====================
      // Extra Reducers for : renameFile
      // ====================
      .addCase(renameFile.fulfilled, (state, action) => {
        // Rename is success, change file name in state
        state.files = state.files?.map((file) =>
          file._id === action.payload.fileId
            ? { ...file, name: action.payload.newName }
            : file
        );
      })
      // ====================
      // Extra Reducers for : renameFolder
      // ====================
      .addCase(renameFolder.fulfilled, (state, action) => {
        // Rename is success, change folder name in state
        state.folders = state.folders?.map((folder) =>
          folder._id === action.payload.folderId
            ? { ...folder, folder_name: action.payload.newName }
            : folder
        );
      })

      // ====================
      // Extra Reducers for : downloadFileAction
      // ====================
      .addCase(downloadFileAction.fulfilled, (state, action) => {
        downloadFile(action.payload.data, action.payload.file);
      })

      // ====================
      // Extra Reducers for : downloadAllAction
      // ====================
      .addCase(downloadAllAction.fulfilled, (state, action) => {
        downloadZip(
          action.payload.data,
          action.payload.folders,
          action.payload.files
        );
      })

      // ====================
      // Extra Reducers for : createFolderAction from folders-slice
      // ====================
      .addCase(createFolderAction.fulfilled, (state, action) => {
        // After succes, add folder to folders list
        if (action.payload.folder != null) {
          state.folders = [
            ...state.folders,
            {
              ...action.payload.folder,
              _id: action.payload.data.folder_id,
            },
          ];
        }
      })

      // ====================
      // Extra Reducers for favorites
      // ====================
      .addCase(addToFavoritesAction.fulfilled, (state, action) => {
        const { folderIds, fileIds } = action.payload;

        // Add to files
        state.files = state.files?.map((file) =>
          fileIds.includes(file._id) ? { ...file, is_favorite: true } : file
        );
        // Add to folders
        state.folders = state.folders?.map((folder) =>
          folderIds.includes(folder._id)
            ? { ...folder, is_favorite: true }
            : folder
        );
        // Add to active file details
        if (fileIds.includes(state.activeFileDetails._id)) {
          state.activeFileDetails.is_favorite = true;
        }
      })
      .addCase(removeFromFavoritesAction.fulfilled, (state, action) => {
        const { folderIds, fileIds } = action.payload;

        // Remove from files
        state.files = state.files?.map((file) =>
          fileIds.includes(file._id) ? { ...file, is_favorite: false } : file
        );
        // Remove from folders
        state.folders = state.folders?.map((folder) =>
          folderIds.includes(folder._id)
            ? { ...folder, is_favorite: false }
            : folder
        );
        // Remove from active file details
        if (fileIds.includes(state.activeFileDetails._id)) {
          state.activeFileDetails.is_favorite = false;
        }
      })

      // ====================
      // Extra Reducers for Trash
      // ====================
      .addCase(moveToTrashAction.fulfilled, (state, action) => {
        const { folderIds, fileIds } = action.payload;
        // Remove from files
        state.files = state.files?.filter(
          (file) => !fileIds.includes(file._id)
        );
        // Remove from folders
        state.folders = state.folders?.filter(
          (folder) => !folderIds.includes(folder._id)
        );
      })

      // ====================
      // Extra Reducers for folder notes
      // ====================
      .addCase(createFolderNoteAction.fulfilled, (state, action) => {
        state.folderNote = action.payload;
      })
      .addCase(getFolderNoteAction.fulfilled, (state, action) => {
        state.folderNote = action.payload || null;
      })
      .addCase(updateFolderNoteAction.fulfilled, (state, action) => {
        state.folderNote = action.payload;
      });
  },
});

export const {
  showUploadSection,
  hideUploadSection,
  toggleUploadSection,
  setActiveFolderId,
  setBreadCrumb,
  setRootBreadCrumb,
  updateFileLatestInformation,
  resetActiveFileDetails,
} = activeFolderSlice.actions;

export const getBreadcrumbs = (state) => {
  return [state.activeFolder.rootBreadcrumb, ...state.activeFolder.breadcrumbs];
};
export const getActiveFolderId = (state) => state.activeFolder.activeFolderId;
export const getActiveFolderDEtails = (state) =>
  state.activeFolder.activeFolderDetails;
export const getCurrentFileUserDetails = (state) =>
  state.activeFolder.currentFileUserDetails;

export const getCurrentFileDetails = (state) =>
  state.activeFolder.activeFileDetails;

export default activeFolderSlice.reducer;
