import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  fetchActiveFolderBreadcrumb,
  fetchFilesInFolder,
  fetchFolderContents,
  setActiveFolderId,
} from "../../redux/slices/active-folder-slice";
import FolderContent from "./folder-content";
import EmptyState from "../common/empty-state";

const Folder = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [isFolderContentsLoading, setIsFolderContentsLoading] = useState(true);
  const [isFilesInFolderLoading, setIsFilesInFolderLoading] = useState(true);

  const user = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);
  const activeFolder = useSelector((state) => state.activeFolder);

  useEffect(() => {
    if (!isFilesInFolderLoading && !isFilesInFolderLoading) setIsLoading(false);
  }, [isFolderContentsLoading, isFilesInFolderLoading]);

  useEffect(() => {
    // Parse the search string to get query parameters
    const searchParams = new URLSearchParams(location.search);

    // Check if 'folderId' query parameter exists
    if (searchParams.has("folderId") && organization && organization.id) {
      // Get the value of 'folderId' query parameter
      const folderId = searchParams.get("folderId");

      setIsLoading(true);
      setIsFolderContentsLoading(true);
      setIsFilesInFolderLoading(true);

      dispatch(setActiveFolderId(folderId));
      dispatch(
        fetchFolderContents({
          orgId: organization.id,
          folderId,
          userId: user.id,
          successHandler: () => {
            setIsFolderContentsLoading(false);
          },
        })
      );
      dispatch(
        fetchFilesInFolder({
          orgId: organization.id,
          folderId,
          userId: user.id,
          successHandler: () => {
            setIsFilesInFolderLoading(false);
          },
        })
      );
      dispatch(fetchActiveFolderBreadcrumb({ folderId }));
    }
  }, [organization, location, dispatch]);

  // const checkAndFetchFiles = useCallback(() => {
  //   if (organization && organization.id && activeFolder.activeFolderId) {
  //     dispatch(
  //       fetchFilesInFolder({
  //         orgId: organization.id,
  //         folderId: activeFolder.activeFolderId,
  //       })
  //     );
  //   }
  // }, [dispatch, organization, activeFolder.activeFolderId]);
  //
  // useEffect(() => {
  //   if (activeFolder.files && activeFolder.files.length > 0) {
  //     const hasUnprocessedFiles = activeFolder.files.some(
  //       (file) => !file.is_OCR
  //     );

  //     if (hasUnprocessedFiles) {
  //       const timeoutId = setTimeout(() => {
  //         checkAndFetchFiles();
  //       }, 120000); // 2 minutes timeout
  //       return () => clearTimeout(timeoutId);
  //     }
  //   }
  // }, [activeFolder.files, checkAndFetchFiles]);

  return (
    <FolderContent
      folders={activeFolder.folders}
      files={activeFolder.files}
      isLoading={isLoading}
      emptyStateComponent={<EmptyState text="Folder is empty" />}
    />
  );
};

export default Folder;
