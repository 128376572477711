import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { format } from "date-fns";

function ProximaDropZone(props) {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    props.setSelectedFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const generateName = () => {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "yyyyMMdd_");
    return (
      formattedDate + Math.floor(1000000 + Math.random() * 9000000).toString()
    );
  };

  const handlePaste = useCallback(
    (event) => {
      const items = event.clipboardData.items;
      const fileItems = Array.from(items).filter(
        (item) => item.kind === 'file'
      );

      if (fileItems.length > 0) {
        const files = fileItems.map((item) => {
          const file = item.getAsFile();
          const randomName = generateName();
          const extension = file.name.split('.').pop() || file.type.split("/").pop();
          const newFileName = `${randomName}.${extension}`;
          return new File([file], newFileName, {
            type: file.type,
          });
        });
        props.setSelectedFiles(files);
      }
    },
    [props.setSelectedFiles]
  );

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [handlePaste]);

  return (
    <div className="dropzone" {...getRootProps()}>
      <input {...getInputProps()} />

      {isDragActive ? (
        <div class="dz-message">
          <span class="dz-message-text">
            <span>Drop </span>
            the file here
          </span>
        </div>
      ) : (
        <div class="dz-message">
          <span class="dz-message-text">
            <span>Drag and drop</span>or<span>paste</span>
            files here or
            <span>browse</span>
          </span>
        </div>
      )}
    </div>
  );
}

export default ProximaDropZone;
