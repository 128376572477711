import { Col, Row } from "reactstrap";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
// import ActiveFolders from "../components/folders/active-folder";
// import UploadSection from "../components/upload/upload-section";
// import { SET_USER } from "../redux/actions/types";
// import { checkIfSessionActive } from "../redux/services/auth-service";
import Navbar from "./navbar/main-navbar";
import routes from "./routes/routes";
import Sidebar from "./sidebar/main-sidebar";
import StorageApp from "../modules/proxima-storage/layout/storage-app";
import StorageAppContainer from "../modules/proxima-storage/layout/storage-app-container";
import { fetchOrganizationAction } from "../redux/slices/organization-slice";
const Dashboard = (props) => {
  const mainPanelRef = useRef(null);
  // const uploadViewer = useSelector((state) => state.uploadViewer);
  const user = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };

  useEffect(() => {
    if (user && user.token && user.token != "") {
     
      dispatch(
        fetchOrganizationAction({
          orgId: user.user.org_id,
          successHandler: () => {
            // alert("hello");
          },
          errorHandler: () => {
            navigate("/auth/login");
          },
        })
      );
    } 
    if(!user || !user.user ||!user.user.id) {
     // alert(JSON.stringify(user))
      navigate("/auth/login");
    }
  }, [user]);

  if(user&&user.user){
    return (
      <div className="main-wrapper">
        <Sidebar {...props} routes={routes} user={user?.user}></Sidebar>
        <div className="main-panel" ref={mainPanelRef}>
          <StorageAppContainer
            user={{ ...user.user, token: user.token }}
            organization={organization}
          />
        </div>
      </div>
    );
  }
  return <h1>Loading</h1>
 
};

export default Dashboard;
