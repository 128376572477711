import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import EmptyState from "../components/common/empty-state";
import NotificationItem from "../components/notifications/notification-item";
import StorageNavbar from "../components/storage-navbar/storage-navbar";
import {
  deleteGivenNotification,
  fetchAllNotifications,
  readGivenNotification
} from "../redux/slices/notification-slice";

const NotificationsView = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const organization = useSelector((state) => state.organization);
  const user = useSelector((state) => state.user);
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  useEffect(() => {
    if (organization?.id && user?.id) {
      fetchNotificationsDetails();
    }
  }, [organization, user]);

  const fetchNotificationsDetails = async () => {
    dispatch(
      fetchAllNotifications({
        userId: user.id,
        orgId: organization._id,
        successHandler: (notifications) => {
          setIsLoading(false);
        },
      })
    );
  };
  const handleDeleteNotification = (_id) => {
    dispatch(
      deleteGivenNotification({
        notificationId: _id,
      })
    );
  };

  const handleMarkAsRead = (_id) => {
    dispatch(
      readGivenNotification({
        notificationId: _id,
        userId: user.id,
      })
    );
  };

  return (
    <>
      <StorageNavbar />
      <div className="storage-content">
        {isLoading ? (
          <>
            <div className="loader-container">
              <Spinner color="primary" />
            </div>
          </>
        ) : notifications?.length > 0 ? (
          <div className="notifications-list">
            {notifications.map((notification) => (
              <NotificationItem
                key={notification._id}
                notification={notification}
                onDelete={handleDeleteNotification}
                onMarkAsRead={handleMarkAsRead}
              />
            ))}
          </div>
        ) : (
          <EmptyState text="Nothing here." />
        )}
      </div>
    </>
  );
};

export default NotificationsView;
