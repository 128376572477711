import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { clearSelection } from "../../../redux/slices/multi-select-slice";
import {
  setIsAscending,
  setSortBy,
} from "../../../redux/slices/user-preference-slice";
import { SORT_OPTIONS } from "../../../utils/sort-utils";

const SortDropdown = () => {
  const dispatch = useDispatch();

  const sortBy = useSelector((state) => state.userPreference.sortBy);
  const isAscending = useSelector((state) => state.userPreference.isAscending);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleSortOptionChange = (option) => {
    dispatch(setSortBy(option));
    dispatch(clearSelection());
  };

  const toggleSortOrder = () => {
    dispatch(setIsAscending(!isAscending));
    dispatch(clearSelection());
  };

  return (
    <>
      <i className="material-icons icon-button" onClick={toggleSortOrder}>
        {isAscending ? "arrow_upward" : "arrow_downward"}
      </i>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        className="sort-dropdown"
      >
        <DropdownToggle
          size="sm"
          className="dropdown-button text-dark bg-transparent"
        >
          {sortBy}
          <i
            className="material-icons"
            style={{ fontSize: 18, lineHeight: 0, verticalAlign: "middle" }}
          >
            {dropdownOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
          </i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>Sort by</DropdownItem>
          {Object.entries(SORT_OPTIONS).map(([key, label]) => (
            <DropdownItem
              key={key}
              onClick={() => handleSortOptionChange(label)}
            >
              {label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default SortDropdown;
