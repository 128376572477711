/**
 * TypeFilter Component
 *
 * This component renders a dropdown for filtering by "Type" (e.g., Folder, Image).
 * It optionally synchronizes with:
 *   - A `initialSelectedItem` prop from the parent, which controls the selected type.
 *   - The `onItemSelect` callback, if provided, to notify the parent of selection changes.
 *
 * If `initialSelectedItem` or `onItemSelect` is not provided, TypeFilter functions independently,
 * defaulting to using URL query parameters (`type`) to set or clear filters.
 */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../layout/routes/routes";
import FilterDropdown from "./filter-dropdown";

const TypeFilter = ({ onItemSelect, initialSelectedItem }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract 'type' parameter from the URL query string
  const queryParams = new URLSearchParams(location.search);
  const typeParam = queryParams.get("type");

  // Define the route for search navigation
  const searchRoute = routes.find((route) => route.id === "SEARCH");

  // List of available types for filtering
  const types = ["Folder", "Image"];

  // Internal state to manage the selected type, initializing with an empty string.
  // This state can be overridden by `initialSelectedItem` prop or URL parameter (`type`)
  const [selected, setSelected] = useState("");

  // Synchronize `selected` with the `initialSelectedItem` prop when it changes.
  // `initialSelectedItem` is an optional prop allowing the parent to control the selected type.
  // OR
  // If `typeParam` exists in the URL, set it as the initial selected type.
  // This effect will update `selected` whenever `typeParam` in the URL changes.
  useEffect(() => {
    // If `initialSelectedItem` is passed as prop give it high priority than typeParam
    if (initialSelectedItem !== undefined) {
      setSelected(
        types.includes(initialSelectedItem) ? initialSelectedItem : ""
      );
    } else {
      if (typeParam?.length > 0 && types.includes(typeParam)) {
        setSelected(typeParam);
        // Also udpate parent
        if (onItemSelect) onItemSelect(typeParam);
      } else {
        setSelected("");
        // Also udpate parent
        if (onItemSelect) onItemSelect("");
      }
    }
  }, [typeParam, initialSelectedItem]);

  // Handle the selection of a type in the dropdown
  const handleTypeSelect = (type) => {
    setSelected(type[0]); // Update the internal state to reflect the selected type

    if (onItemSelect) {
      // If a callback `onItemSelect` is provided by the parent, do not apply filter immediately
      // and notify the parent of the selection and let parent decide what to do with the selection
      onItemSelect(type[0]);
    } else {
      // By default, apply filter immediately on item select
      queryParams.set("type", type[0]); // Set the selected type as a query parameter
      navigate(
        `${searchRoute.layout}${searchRoute.path}?${queryParams.toString()}`
      );
      // In case if we decide to provide filtering inside the folder, use this -
      // navigate(`${location.pathname}?${queryParams.toString()}`);
    }
  };

  const handleClearTypes = () => {
    setSelected(""); // Clear the internal selected state
    // If a callback `onItemSelect` is provided, notify the parent that the selection is cleared.
    if (onItemSelect) {
      onItemSelect("");
    } else {
      // If no callback, remove the 'type' parameter from the URL
      queryParams.delete("type"); // Clear the type filter from query parameters
      navigate(`${location.pathname}?${queryParams.toString()}`);
    }
  };

  return (
    <>
      <FilterDropdown
        title="Type"
        items={types}
        isMultiSelect={false}
        showSearch={false}
        onItemSelect={handleTypeSelect}
        onClearSelection={handleClearTypes}
        initialSelectedItems={selected ? [selected] : []}
      />
    </>
  );
};

export default TypeFilter;
