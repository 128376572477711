import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "../../../../redux/services/base-url";
import { downloadFileAction } from "../../redux/slices/active-folder-slice";
import {
  addToFavoritesAction,
  removeFromFavoritesAction,
} from "../../redux/slices/favorites-slice";
import TooltipCustom from "../common/tooltip-custom";
import ImageEditor from "../image-editor/image-editor";
import ShareModal from "../share/share-modal";
import { toggleOcrReader } from "../../redux/slices/ocr-reader-slice";
import FolderTreeSelectionModal, {
  FOLDER_TREE_SELECTION_MODES,
} from "../folder-tree/folder-tree-selection-modal";

const ImageOptions = ({ file, zoomIn, zoomOut, resetZoom }) => {
  const dispatch = useDispatch();

  const [imageEditorModal, setImageEditorModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [folderTreeSelectionModal, setFolderTreeSelectionModal] =
    useState(false);

  const user = useSelector((state) => state.user);
  const isOcrReaderActive = useSelector(
    (state) => state.ocrReader.isOcrReaderActive
  );

  const toggleImageEditorModal = () => {
    setImageEditorModal(!imageEditorModal);
  };
  const toggleShareModal = () => setShareModal(!shareModal);
  const toggleFolderTreeSelectionModal = () => {
    setFolderTreeSelectionModal(!folderTreeSelectionModal);
  };

  const handleAnnotateClick = () => {
    setImageEditorModal(true);
  };

  const handleFavoriteClick = () => {
    if (file.is_favorite) {
      dispatch(
        removeFromFavoritesAction({
          orgId: file.org_id,
          userId: user.id,
          folderIds: [],
          fileIds: [file._id],
        })
      );
    } else {
      dispatch(
        addToFavoritesAction({
          orgId: file.org_id,
          userId: user.id,
          fileIds: [file._id],
          folderIds: [],
        })
      );
    }
  };

  const handleDownloadClick = () => {
    dispatch(
      downloadFileAction({
        file: file,
      })
    );
  };

  const handleMoveClick = () => {
    toggleFolderTreeSelectionModal();
  };

  const handleShareClick = () => {
    toggleShareModal();
  };

  const handleOcrReaderClick = () => {
    dispatch(toggleOcrReader());
  };

  const IconButton = ({ id, onClick, children, tooltipText }) => (
    <>
      <div
        id={id}
        className="material-icons-outlined icon-button"
        onClick={onClick}
      >
        {children}
      </div>
      <TooltipCustom text={tooltipText} target={id} />
    </>
  );

  const ZoomControls = ({ zoomOut, resetZoom, zoomIn }) => (
    <div className="zoom-controls">
      <IconButton id="id-zoom-out" onClick={zoomOut} tooltipText="Zoom out">
        zoom_out
      </IconButton>
      <IconButton
        id="id-zoom-reset"
        onClick={resetZoom}
        tooltipText="Reset zoom"
      >
        crop_free
      </IconButton>
      <IconButton id="id-zoom-in" onClick={zoomIn} tooltipText="Zoom in">
        zoom_in
      </IconButton>
    </div>
  );

  return (
    <>
      <div className="image-options-container">
        <ZoomControls zoomOut={zoomOut} resetZoom={resetZoom} zoomIn={zoomIn} />
        {!file?.is_bin && (
          <>
            <div className="divider"></div>
            <IconButton
              id="id-annotate"
              onClick={handleAnnotateClick}
              tooltipText="Annotate"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#212529"
              >
                <path d="m499-287 335-335-52-52-335 335 52 52Zm-261 87q-100-5-149-42T40-349q0-65 53.5-105.5T242-503q39-3 58.5-12.5T320-542q0-26-29.5-39T193-600l7-80q103 8 151.5 41.5T400-542q0 53-38.5 83T248-423q-64 5-96 23.5T120-349q0 35 28 50.5t94 18.5l-4 80Zm280 7L353-358l382-382q20-20 47.5-20t47.5 20l70 70q20 20 20 47.5T900-575L518-193Zm-159 33q-17 4-30-9t-9-30l33-159 165 165-159 33Z" />
              </svg>
            </IconButton>
            <IconButton
              id="id-ocr-reader"
              onClick={handleOcrReaderClick}
              tooltipText="OCR Reader"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="22px"
                viewBox="0 -960 960 960"
                width="22px"
                fill={isOcrReaderActive ? "#FF4433" : "#212529"}
              >
                <path d="M80-720v-200h200v80H160v120H80Zm720 0v-120H680v-80h200v200h-80ZM80-40v-200h80v120h120v80H80Zm600 0v-80h120v-120h80v200H680ZM280-240h400v-480H280v480Zm0 80q-33 0-56.5-23.5T200-240v-480q0-33 23.5-56.5T280-800h400q33 0 56.5 23.5T760-720v480q0 33-23.5 56.5T680-160H280Zm80-400h240v-80H360v80Zm0 120h240v-80H360v80Zm0 120h240v-80H360v80Zm-80 80v-480 480Z" />
              </svg>
            </IconButton>
            <div className="divider"></div>
            <IconButton
              id="id-favorite"
              onClick={handleFavoriteClick}
              tooltipText={
                file?.is_favorite
                  ? "Remove from favourites"
                  : "Add to favourites"
              }
            >
              {file?.is_favorite ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#212529"
                >
                  <path d="m233-120 65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#212529"
                >
                  <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
                </svg>
              )}
            </IconButton>
            <IconButton
              id="id-move"
              onClick={handleMoveClick}
              tooltipText="Move to..."
            >
              drive_file_move
            </IconButton>
            <IconButton
              id="id-share"
              onClick={handleShareClick}
              tooltipText="Share"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="22px"
                viewBox="0 -960 960 960"
                width="22px"
                fill="#212529"
              >
                <path d="M680-80q-50 0-85-35t-35-85q0-6 3-28L282-392q-16 15-37 23.5t-45 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q24 0 45 8.5t37 23.5l281-164q-2-7-2.5-13.5T560-760q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-24 0-45-8.5T598-672L317-508q2 7 2.5 13.5t.5 14.5q0 8-.5 14.5T317-452l281 164q16-15 37-23.5t45-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T720-200q0-17-11.5-28.5T680-240q-17 0-28.5 11.5T640-200q0 17 11.5 28.5T680-160ZM200-440q17 0 28.5-11.5T240-480q0-17-11.5-28.5T200-520q-17 0-28.5 11.5T160-480q0 17 11.5 28.5T200-440Zm480-280q17 0 28.5-11.5T720-760q0-17-11.5-28.5T680-800q-17 0-28.5 11.5T640-760q0 17 11.5 28.5T680-720Zm0 520ZM200-480Zm480-280Z" />
              </svg>
            </IconButton>
            <IconButton
              id="id-download"
              onClick={handleDownloadClick}
              tooltipText="Download"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#212529"
              >
                <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" />
              </svg>
            </IconButton>
          </>
        )}
      </div>
      {/* Image Editor Modal */}
      <ImageEditor
        isOpen={imageEditorModal}
        toggle={toggleImageEditorModal}
        imageUrl={`${API_BASE_URL}/api/images/image/${file?.name}/${file?.org_id}`}
        currentFile={file}
      />
      <FolderTreeSelectionModal
        isOpen={folderTreeSelectionModal}
        toggle={toggleFolderTreeSelectionModal}
        type={FOLDER_TREE_SELECTION_MODES.MOVE}
        folders={[]}
        files={file ? [file] : []}
      />
      <ShareModal
        isOpen={shareModal}
        toggle={toggleShareModal}
        files={file ? [file] : []}
      />
    </>
  );
};

export default ImageOptions;
