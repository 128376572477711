import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { setActiveFolderId } from "../redux/slices/active-folder-slice";
import { clearSelection } from "../redux/slices/multi-select-slice";
import { updateOrganization } from "../redux/slices/organization-slice";
import { updateUser } from "../redux/slices/user-slice";
import routes from "./routes/routes";
import SseService from "../redux/services/sse-service";
import { setNewUnreadNotification, setNewUnreadNotificationBySSE } from "../redux/slices/notification-slice";

const StorageApp = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let sseService =null;
  const user = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      if (prop.isAdmin && !user.isAdmin) {
        return null;
      }

      return (
        <Route
          path={prop.layout + prop.path}
          Component={prop.component}
          key={key}
        />
      );
    });
  };

  useEffect(() => {
    // Parse the search string to get query parameters
    const searchParams = new URLSearchParams(location.search);

    // Check if 'folderId' query parameter exists
    if (!searchParams.has("folderId")) {
      // If no active folder found, reset the active folder id
      setTimeout(() => {
        dispatch(setActiveFolderId(null));
      }, 0); // Delay the reset to prevent flickering empty state
    }
    dispatch(clearSelection()); // Clear selected items if any
  }, [location]);

  useEffect(() => {
    let userProps = props.user;
    let organizationProps = props.organization;

    if (userProps && userProps.id != "") {
      if (!user || user.id != userProps.id) {
        dispatch(updateUser(userProps));
      }
      if (
        organizationProps &&
        organizationProps.id &&
        organizationProps.id != "" &&
        (!organization || organization.id != organizationProps.id)
      ) {
        console.log("here dk");
        // alert(JSON.stringify(organization))
        dispatch(updateOrganization(organizationProps));
      } else {
        // navigate("/auth");
      }
    } else {
      navigate("/auth");
    }
  }, [props.user, props.organization, user, organization]);

  useEffect(() => {
    // Connect to notifications without specifying type
    if (user?.id ) {
       sseService = new SseService(user?.id);
      sseService.connect(
        (data) => {
          if(data.type =="notification"){
           dispatch(setNewUnreadNotificationBySSE())
          }
            
        },
        (error) => console.error("Error in notification stream:", error)
      );

      // Cleanup on component unmount
      return () => {
        sseService.disconnect();
      };
    }
  }, [user?.id]);

  return (
    <div className="storage-wrapper">
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/app/home" replace />} />
      </Routes>
    </div>
  );
};

export default StorageApp;
