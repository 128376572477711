import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Folder from "../components/folders/folder";
import RootFolder from "../components/folders/root-folder";
import StorageNavbar from "../components/storage-navbar/storage-navbar";
import routes from "../layout/routes/routes";
import { setRootBreadCrumb } from "../redux/slices/active-folder-slice";

const SharedStorageView = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.organization);
  const [isRoot, setIsRoot] = useState(true);
  const storageRoute = routes.find((route) => route.id === "SHARED_STORAGE");
  useEffect(() => {
    // ToDo: Need better logic to find whether it is root folder or other folder
    // Parse the search string to get query parameters
    const searchParams = new URLSearchParams(location.search);

    // Check if 'folderId' query parameter exists
    if (searchParams.has("folderId")) {
      setIsRoot(false);
    } else {
      setIsRoot(true);
    }
  }, [location]);

  useEffect(() => {
    if (organization && organization.id) {
      dispatch(
        setRootBreadCrumb({
          folder_id: "",
          folder_name: storageRoute.name,
          parent_folder_id: null,
          rootLink: storageRoute.layout + storageRoute.path,
        })
      );
    }
  }, [organization]);
  return (
    <>
      <StorageNavbar />
      {isRoot ? <RootFolder /> : <Folder />}
    </>
  );
};

export default SharedStorageView;
