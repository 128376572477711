import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../../redux/services/base-url";

const ImageContainer = ({ currentFile, zoomLevel }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [cursor, setCursor] = useState("default");

  useEffect(() => {
    if (zoomLevel == 1) {
      setPosition({ x: 0, y: 0 }); // Reset position when zoom is reset
    }
  }, [zoomLevel]);

  // Handle when dragging starts
  const handleMouseDown = (e) => {
    if (zoomLevel <= 1) return;
    setIsDragging(true);
    setStartPos({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
    setCursor("move");
  };

  // Handle the dragging
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setPosition({
      x: e.clientX - startPos.x,
      y: e.clientY - startPos.y,
    });
  };

  // Handle when dragging ends
  const handleMouseUp = () => {
    setIsDragging(false);
    setCursor("default");
  };

  return (
    <>
      <div
        className="image-container"
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <img
          src={`${API_BASE_URL}/api/images/image/${currentFile?.name}/${currentFile?.org_id}`}
          alt={currentFile.name}
          className="image-view"
          draggable={false}
          style={{
            transform: `scale(${zoomLevel})`,
            cursor: cursor,
            transition: "transform 0.2s ease-out",
            position: "relative",
            top: position.y,
            left: position.x,
          }}
          onMouseDown={handleMouseDown}
        />
      </div>
    </>
  );
};

export default ImageContainer;
