import { createHeaders, handleErrors, parseJSON } from "./api-middleware";
import { API_BASE_URL } from "./base-url";

export const fetchOrganizationUsers = async (
    orgId,
    successHandler,
    errorHandler
  ) => {
    try {
      // let dataFromCache = retriveFromCache(orgId);
      // if (dataFromCache) {
      //   return dataFromCache;
      // }
      const response = await fetch(`${API_BASE_URL}/api/users/${orgId}`, {
        method: "GET",
        headers: createHeaders(), // Use the headers function
      });
  
      // Apply middleware functions
      await handleErrors(response, errorHandler); // Check for HTTP errors
      const data = await parseJSON(response); // Parse JSON
  
      if (data && data.users) {
        if (successHandler) {
          successHandler(data.users);
          // localStorage.setItem("@antridge-organizaion/user", JSON.stringify(data.user));
        }
      }
      return data;
    } catch (error) {
      // Handle or log errors if needed
      throw error;
    }
  };