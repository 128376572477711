import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "reactstrap";
import { resetShortcutKey } from "../../redux/slices/shortcut-slice";
import PlusButton from "./plus-button";
import TooltipCustom from "./tooltip-custom";

const EditableTextCard = ({
  initialText,
  title,
  editable = true,
  onSave,
  placeholder = "Type your text here...",
  shortcutKey,
}) => {
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);

  const inputRef = useRef(null);

  const shortcutKeyPressed = useSelector((state) => state.shortcut.shortcutKey);

  const handleSave = () => {
    onSave(text);
    setIsEditing(false);
  };

  const handleEdit = () => {
    if (editable) {
      setIsEditing(true);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setText(initialText); // Reset text to the initial value on cancel
  };

  const focusInput = () => {
    inputRef.current.focus(); // Automatically focus the input field

    // Move the cursor to the end of the text
    const length = inputRef.current.value.length;
    inputRef.current.setSelectionRange(length, length);
  };

  const handleInputKeyPress = (e) => {
    if (e.ctrlKey && e.key === "Enter") {
      e.preventDefault();
      handleSave();
    } else if (e.key === "Escape") {
      e.stopPropagation();
      handleCancel();
    }
  };

  useEffect(() => {
    setText(initialText);
  }, [initialText]);

  // Focus the input field when editing starts
  useEffect(() => {
    if (isEditing && inputRef.current) {
      focusInput();
    }
  }, [isEditing]);

  useEffect(() => {
    if (shortcutKeyPressed === shortcutKey) {
      if (!isEditing) {
        setIsEditing(true);
      } else if (isEditing && inputRef.current) {
        focusInput();
      }
      dispatch(resetShortcutKey()); // Reset shortcut after handling
    }
  }, [shortcutKeyPressed]);

  return (
    <>
      <div className="editable-text-card">
        <div className="tab-section-header no-select">
          <div className="title">{title}</div>
          {!isEditing && editable && text?.length !== 0 && (
            <>
              <i
                id={"id_" + "edit-" + title.replace(/\s+/g, "-").toLowerCase()}
                className="material-icons icon-button"
                onClick={handleEdit}
              >
                edit
              </i>
              <TooltipCustom
                text={`Edit ${title} ${
                  shortcutKey ? "(" + shortcutKey + ")" : ""
                }`}
                target={
                  "id_" + "edit-" + title.replace(/\s+/g, "-").toLowerCase()
                }
              />
            </>
          )}
        </div>

        {isEditing ? (
          <div>
            <Input
              type="textarea"
              value={text}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={handleInputKeyPress}
              placeholder={placeholder}
              innerRef={inputRef}
            />
            <Button
              color="primary"
              size="sm"
              onClick={handleSave}
              className="mt-2"
            >
              Save
            </Button>
            <Button
              color="link"
              size="sm"
              onClick={handleCancel}
              className="mt-2"
            >
              Cancel
            </Button>
          </div>
        ) : (
          text &&
          text.length > 0 && (
            <div className="text-display">
              <React.Fragment>{text}</React.Fragment>
            </div>
          )
        )}

        {!text && !isEditing && editable && (
          <>
            <PlusButton label={"Add " + title} onClick={handleEdit} />
            <TooltipCustom
              text={`Add ${title} ${
                shortcutKey ? "(" + shortcutKey + ")" : ""
              }`}
              target={"id_" + "add-" + title.replace(/\s+/g, "-").toLowerCase()}
            />
          </>
        )}
      </div>
    </>
  );
};

export default EditableTextCard;
