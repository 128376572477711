/**
 * DateFilter Component
 *
 * This component renders a dropdown for filtering by "Date" (e.g., Today, Yesterday).
 * It optionally synchronizes with:
 *   - A `initialSelectedItem` prop from the parent, which controls the selected date.
 *   - The `onItemSelect` callback, if provided, to notify the parent of selection changes.
 *
 * If `initialSelectedItem` or `onItemSelect` is not provided, DateFilter functions independently,
 * defaulting to using URL query parameters (`date`) to set or clear filters.
 */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../layout/routes/routes";
import {
  FILTER_DATES,
  getDatesFromFilterLabel,
  mapFilterDatesToLabel,
} from "../../../utils/filter-date-utils";
import FilterDropdown from "./filter-dropdown";

const DateFilter = ({ onItemSelect, initialSelectedItem }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract 'after' and 'before' parameter from the URL query string
  const queryParams = new URLSearchParams(location.search);
  const afterParam = queryParams.get("after");
  const beforeParam = queryParams.get("before");

  // Define the route for search navigation
  const searchRoute = routes.find((route) => route.id === "SEARCH");

  // Internal state to manage the selected date, initializing with an empty string.
  // This state can be overridden by `initialSelectedItem` prop or URL parameter (`after` and `before`)
  const [selected, setSelected] = useState("");

  // Synchronize `selected` with the `initialSelectedItem` prop when it changes.
  // `initialSelectedItem` is an optional prop allowing the parent to control the selected date.
  // OR
  // If `afterParam` or `beforeParam` exists in the URL, set it as the initial selected date.
  // This effect will update `selected` whenever `after` or `before` in the URL changes.
  useEffect(() => {
    // If `initialSelectedItem` is passed as prop give it high priority than typeParam
    if (initialSelectedItem !== undefined) {
      setSelected(initialSelectedItem);
    } else {
      if (afterParam?.length > 0 || beforeParam?.length > 0) {
        const dateLabel = mapFilterDatesToLabel({
          after: afterParam,
          before: beforeParam,
        });
        setSelected(dateLabel);
        // Also udpate parent
        if (onItemSelect) onItemSelect(dateLabel);
      } else {
        setSelected("");
        // Also udpate parent
        if (onItemSelect) onItemSelect("");
      }
    }
  }, [afterParam, beforeParam, initialSelectedItem]);

  // Handle the selection of a date in the dropdown
  const handleDateSelect = (date) => {
    setSelected(date[0]); // Update the internal state to reflect the selected date

    if (onItemSelect) {
      // If a callback `onItemSelect` is provided by the parent, do not apply filter immediately
      // and notify the parent of the selection and let parent decide what to do with the selection
      onItemSelect(date[0]);
    } else {
      // By default, apply filter immediately on item select
      const dateValues = getDatesFromFilterLabel(date[0]);
      if (dateValues.after) {
        queryParams.set("after", dateValues.after);
      } else {
        queryParams.delete("after");
      }
      if (dateValues.before) {
        queryParams.set("before", dateValues.before);
      } else {
        queryParams.delete("before");
      }

      navigate(
        `${searchRoute.layout}${searchRoute.path}?${queryParams.toString()}`
      );
      // In case if we decide to provide filtering inside the folder, use this -
      // navigate(`${location.pathname}?${queryParams.toString()}`);
    }
  };

  const handleClearDate = () => {
    setSelected(""); // Clear the internal selected state
    // If a callback `onItemSelect` is provided, notify the parent that the selection is cleared.
    if (onItemSelect) {
      onItemSelect("");
    } else {
      // If no callback, remove the 'after' and 'before' parameter from the URL
      queryParams.delete("after"); // Clear the after date filter from query parameters
      queryParams.delete("before"); // Clear the before date filter from query parameters
      navigate(`${location.pathname}?${queryParams.toString()}`);
    }
  };

  return (
    <>
      <FilterDropdown
        title="Modified"
        items={FILTER_DATES}
        isMultiSelect={false}
        showSearch={false}
        onItemSelect={handleDateSelect}
        onClearSelection={handleClearDate}
        initialSelectedItems={selected ? [selected] : []}
      />
    </>
  );
};

export default DateFilter;
