import { createHeaders, handleErrors, parseJSON } from "./api-middleware";
import { API_BASE_URL } from "./base-url";

export const addToFavorites = async (
  orgId,
  userId,
  folderIds,
  fileIds,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/bulk-operations/add-to-favorites`,
      {
        method: "POST",
        headers: createHeaders(),
        body: JSON.stringify({
          orgId,
          userId,
          folderIds,
          imageIds: fileIds,
        }),
      }
    );
    console.log(response);
    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const removeFromFavorites = async (
  orgId,
  userId,
  folderIds,
  fileIds,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/bulk-operations/remove-from-favorites`,
      {
        method: "POST",
        headers: createHeaders(),
        body: JSON.stringify({
          orgId,
          userId,
          folderIds,
          imageIds: fileIds,
        }),
      }
    );

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const getAllFavorites = async (
  orgId,
  userId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/favorites/${orgId}/${userId}`,
      {
        method: "GET",
        headers: createHeaders(),
      }
    );

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};
