import React from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle } from "reactstrap";
import FolderContextMenu from "../menu/folder-context-menu";

const FolderThumbnailItem = ({ folder, onClick, onDoubleClick }) => {
  const selectedItems = useSelector((state) => state.multiSelect.selectedItems);

  return (
    <>
      <Card
        className={`folder-thumbnail-item no-select${
          selectedItems?.some((item) => item._id === folder._id)
            ? " selected"
            : ""
        }`}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        data-selectable-item // a unique attribute to prevent this item from triggering clearSelection
      >
        <CardBody className="d-flex align-items-center">
          <i className="material-icons folder-icon">folder</i>
          <CardTitle className="mb-0 folder-name">
            {folder.folder_name}
          </CardTitle>
          <FolderContextMenu
            folder={folder}
            onFolderOpen={onDoubleClick}
            disabled={selectedItems?.length > 1}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default FolderThumbnailItem;
