import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFileDetailsAction } from "../../../../redux/slices/active-folder-slice";
import NoteComponent from "./note-component";
import ReferenceDateComponent from "./reference-date-component";
import TagsComponent from "./tags-component";
import SourceComponent from "./source-component";

const InsightsTab = ({ file }) => {
  const [initialTags, setInitialTags] = useState(file.tags);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (file) {
      setInitialTags(file.tags || []);
    }
  }, [file]);

  const saveTags = (tags) => {
    setInitialTags([...file.tags]);
    updateImageInfo(tags);
  };

  const updateImageInfo = (tags) => {
    if (file && file._id) {
      dispatch(
        updateFileDetailsAction({
          file: file,
          userId: user.id,
          updates: { tags: tags },
          successHandler: () => {
            setInitialTags([...tags]);
          },
        })
      );
    }
  };

  return (
    <div className="tab-content">
      <NoteComponent file={file} />
      <TagsComponent
        initialTags={initialTags}
        onSave={saveTags}
        orgId={file?.org_id}
      />
      <ReferenceDateComponent file={file} />
      <SourceComponent file={file} />
    </div>
  );
};

export default InsightsTab;
