import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Input } from "reactstrap";
import routes from "../../layout/routes/routes";
import {
  getDatesFromFilterLabel,
  mapFilterDatesToLabel,
} from "../../utils/filter-date-utils";
import TooltipCustom from "../common/tooltip-custom";
import DateFilter from "../storage-navbar/filters/date-filter";
import TagsFilter from "../storage-navbar/filters/tags-filter";
import TypeFilter from "../storage-navbar/filters/type-filter";
import AdvancedSearchModal from "./advanced-search-modal";

const SearchBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchBarRef = useRef(null); // Ref for search bar

  const [isExpanded, setIsExpanded] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [selectedTypeFilter, setSelectedTypeFilter] = useState("");
  const [selectedTagsFilter, setSelectedTagsFilter] = useState([]);
  const [selectedDateFilter, setSelectedDateFilter] = useState("");

  const [selectedAfterDate, setSelectedAfterDate] = useState("");
  const [selectedBeforeDate, setSelectedBeforeDate] = useState("");

  const searchRoute = routes.find((route) => route.id === "SEARCH");

  const [advancedSearchModal, setAdvancedSearchModal] = useState(false);

  const toggleAdvancedSearch = () => {
    setAdvancedSearchModal(!advancedSearchModal);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSearchInput(params.get("q") || "");
    setSelectedTypeFilter(params.get("type") || "");
    setSelectedTagsFilter(params.get("tags")?.split(",") || []);
    setSelectedAfterDate(params.get("after") || "");
    setSelectedBeforeDate(params.get("before") || "");
    if (params.get("after") || params.get("before")) {
      setSelectedDateFilter(
        mapFilterDatesToLabel({
          after: params.get("after"),
          before: params.get("before"),
        }) || ""
      );
    } else {
      setSelectedDateFilter("");
    }
  }, [location.search]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Toggle the expanded state on input click
  const handleInputClick = () => {
    setIsExpanded(true);
  };

  // Collapse when clicking outside
  const handleClickOutside = (event) => {
    if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
      setIsExpanded(false); // Collapse if clicked outside the search bar entirely
    } else {
      focusInputField(true); // Refocus the input if clicked inside
    }
  };

  const focusInputField = (isFocus) => {
    if (isFocus) document.getElementById("id_search_input")?.focus();
    else document.getElementById("id_search_input")?.blur();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    } else if (e.key === "Escape") {
      focusInputField(false);
      setIsExpanded(false);
    }
  };

  const handleMouseDown = (e) => {
    // Adding this to always focus the input field.
    const target = e.target;

    // Check if the target is an input or textarea
    if (
      target.tagName === "TEXTAREA" ||
      (target.tagName === "INPUT" && target.type !== "checkbox") // Exclude checkbox as it is used in tags
    ) {
      return; // Allow default behavior
    }

    e.preventDefault(); // Prevent default for other elements so that the focus is always the search input field
  };

  const handleSearch = (overrideParams = {}) => {
    const {
      tags = selectedTagsFilter,
      input = searchInput,
      type = selectedTypeFilter,
      after = selectedAfterDate,
      before = selectedBeforeDate,
    } = overrideParams; // Overriding parameters. Can be passed optionally.

    const params = new URLSearchParams();

    // Update query parameters
    if (input.trim()) params.set("q", input.trim());
    if (type) params.set("type", type);
    if (after) params.set("after", after);
    if (before) params.set("before", before);
    if (tags.length) params.set("tags", tags.join(","));

    navigate(`${searchRoute.layout}${searchRoute.path}?${params.toString()}`);
    setIsExpanded(false); // Collapse after search
  };

  const handleClear = () => {
    setSearchInput("");
    setSelectedTypeFilter("");
    setSelectedTagsFilter([]);
    setSelectedDateFilter("");
    setSelectedAfterDate("");
    setSelectedBeforeDate("");
    focusInputField(true);

    navigate(`${location.pathname}`);
  };

  const onTypeFilterChange = (type) => {
    setSelectedTypeFilter(type);
  };

  const onTagsFilterChange = (tags) => {
    setSelectedTagsFilter(tags);
  };

  const onDateFilterChange = (date) => {
    setSelectedDateFilter(date);
  };

  const extractAfterAndBeforeDates = (date) => {
    let after = "";
    let before = "";
    if (date.length > 0) {
      const filterDates = getDatesFromFilterLabel(date);
      if (filterDates.after) {
        after = filterDates.after;
      }
      if (filterDates.before) {
        before = filterDates.before;
      }
    }
    setSelectedAfterDate(after);
    setSelectedBeforeDate(before);
  };

  const handleAdvancedSearchButtonClick = () => {
    setAdvancedSearchModal(true);
    setIsExpanded(false);
  };

  const onAdvancedSearch = (input, type, tags, after, before) => {
    setSearchInput(input);
    setSelectedTypeFilter(type);
    setSelectedTagsFilter(tags);
    setSelectedAfterDate(after);
    setSelectedBeforeDate(before);
    setSelectedDateFilter(
      mapFilterDatesToLabel({
        after: after,
        before: before,
      }) || ""
    );
    // These states don't update immediately, so overridden the handle search function and passing new values directly
    handleSearch({ input, type, tags, after, before });
  };

  return (
    <>
      <div ref={searchBarRef} className="search-bar-wrapper">
        <div className={`search-bar-container${isExpanded ? " expanded" : ""}`}>
          <div className={`search-bar${isExpanded ? " expanded" : ""}`}>
            <i className="material-icons search-icon">search</i>
            <Input
              type="text"
              id="id_search_input"
              className="search-input"
              value={searchInput}
              placeholder="Search files, folders"
              onChange={(e) => {
                setSearchInput(e.target.value);
                setIsExpanded(true);
              }}
              onKeyDown={handleKeyDown}
              onClick={handleInputClick}
            />
            <div className="d-flex">
              {(searchInput ||
                selectedTypeFilter.length ||
                selectedTagsFilter.length > 0 ||
                selectedDateFilter.length > 0) && (
                <i className="material-icons icon-button" onClick={handleClear}>
                  clear
                </i>
              )}
              <i
                id="advanced-search-button"
                className="material-icons icon-button"
                onClick={handleAdvancedSearchButtonClick}
              >
                tune
              </i>
            </div>
          </div>
          <div
            className={`search-options${isExpanded ? " expanded" : ""}`}
            onMouseDown={handleMouseDown}
          >
            <div className="filters-container">
              <TypeFilter
                onItemSelect={onTypeFilterChange}
                initialSelectedItem={selectedTypeFilter}
              />
              <TagsFilter
                onItemSelect={onTagsFilterChange}
                initialSelectedItem={selectedTagsFilter}
              />
              <DateFilter
                onItemSelect={(date) => {
                  onDateFilterChange(date);
                  extractAfterAndBeforeDates(date);
                }}
                initialSelectedItem={selectedDateFilter}
              />
            </div>
            <div className="footer-container">
              <Button
                size="sm"
                color="secondary"
                onClick={handleAdvancedSearchButtonClick}
              >
                Advanced Search
              </Button>
              <Button
                size="sm"
                color="primary"
                onClick={() => {
                  handleSearch();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="17px"
                  viewBox="0 -960 960 960"
                  width="17px"
                  fill="#FFFFFF"
                >
                  <path d="M360-240 120-480l240-240 56 56-144 144h488v-160h80v240H272l144 144-56 56Z" />
                </svg>{" "}
                Search
              </Button>
            </div>
          </div>
        </div>
      </div>
      <TooltipCustom text="Advanced Search" target={"advanced-search-button"} />
      {advancedSearchModal && (
        <AdvancedSearchModal
          isOpen={advancedSearchModal}
          toggle={toggleAdvancedSearch}
          onSearch={onAdvancedSearch}
          searchInput={searchInput}
          selectedTypeFilter={selectedTypeFilter}
          selectedTagsFilter={selectedTagsFilter}
          selectedAfterDate={selectedAfterDate}
          selectedBeforeDate={selectedBeforeDate}
        />
      )}
    </>
  );
};

export default SearchBar;
