import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import html2canvas from "html2canvas";
import MuktaRegularBase64 from "./Mukta-Regular-base64";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs["Mukta-Regular.ttf"] = MuktaRegularBase64;

const downloadPDF = async (
  pageRef,
  files,
  showIndex,
  footerText,
  selectedDate,
  setProgress
) => {
  const element = pageRef.current;
  const children = Array.from(element.children);
  const totalSteps = children.length + (showIndex ? 1 : 0);
  let currentStep = 0;

  pdfMake.fonts = {
    Mukta: {
      normal: "Mukta-Regular.ttf",
      bold: "Mukta-Regular.ttf",
      italics: "Mukta-Regular.ttf",
      bolditalics: "Mukta-Regular.ttf",
    },
  };

  let indexContent = [];
  let imageContent = [];
  let pageMapping = new Map();

  const updateProgress = () => {
    currentStep++;
    const progressPercentage = Math.round((currentStep / totalSteps) * 100);
    setProgress(progressPercentage);
  };

  const calculateIndexPages = () => {
    if (!showIndex) return 0;

    const rowsPerPage = 20; 
    let totalRows = 0;
    
    files.forEach(file => {
      let rowHeight = 1;
      if (file.heading && file.heading.trim() !== '') {
        rowHeight += 0.5;
      }
      totalRows += rowHeight;
    });

    return Math.ceil(totalRows / rowsPerPage);
  };

  const indexPagesCount = calculateIndexPages();

  files.forEach((_, index) => {
    const imagePageNumber = index + 1;
    pageMapping.set(index, imagePageNumber);
  });

  if (showIndex) {
    indexContent = [
      {
        text: "Index",
        style: "header",
        fontSize: 28,
        alignment: "center",
        margin: [0, 10, 0, 0],
      },
      {
        text: `Date: ${selectedDate}`,
        alignment: "right",
        fontSize: 12,
        margin: [0, 0, 0, 2],
      },
      {
        table: {
          headerRows: 1,
          widths: [60, "*", 60],
          body: [
            [
              { text: "Sr. No.", style: "tableHeader", alignment: "center" },
              { text: "Image", style: "tableHeader", alignment: "center" },
              { text: "Page No.", style: "tableHeader", alignment: "center" },
            ],
            ...files.map((file, index) => [
              { text: (index + 1).toString(), alignment: "center" },
              {
                text: [
                  {
                    text: file.alias && file.alias.trim() !== ""
                      ? file.alias.trim().slice(0, 200)
                      : file.name,
                    color: "blue",
                    linkToPage: index + indexPagesCount + 1,
                  },
                  file.heading && file.heading.trim() !== "" 
                    ? { 
                        text: `\n${file.heading.trim().slice(0, 150)}`, 
                        fontSize: 10, 
                        color: "black" 
                      }
                    : ""
                ],
              },
              {
                text: pageMapping.get(index).toString(),
                alignment: "center",
              },
            ]),
          ],
        },
        layout: {
          fillColor: function (rowIndex) {
            return rowIndex === 0 ? "#DCDCDC" : null;
          },
        },
        margin: [40, 10, 40, 0],
      },
    ];

    indexContent.push({ text: "", pageBreak: "after" });
    updateProgress();
  }

  const addImagePages = async () => {
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      const canvas = await html2canvas(child, {
        scale: 2,
        useCORS: true,
      });

      const imageDataUrl = canvas.toDataURL("image/jpeg", 1.0);

      const availableWidth = 500;
      const availableHeight = 700;

      const scaleFactor = Math.min(
        availableWidth / canvas.width,
        availableHeight / canvas.height
      );

      const width = canvas.width * scaleFactor;
      const height = canvas.height * scaleFactor;

      imageContent.push({
        image: imageDataUrl,
        width: width,
        height: height,
        alignment: "center",
      });

      if (i < children.length - 1) {
        imageContent.push({ text: "", pageBreak: "after" });
      }
      updateProgress();
    }
  };

  await addImagePages();

  const docDefinition = {
    pageSize: "A4",
    pageMargins: [40, 40, 40, 60],
    defaultStyle: {
      font: "Mukta",
    },
    footer: function (currentPage, pageCount) {
      if (showIndex && currentPage <= indexPagesCount) {
        return {
          text: `Index Page ${currentPage} of ${indexPagesCount}${
            footerText ? " | " + footerText : ""
          }`,
          alignment: "right",
          margin: [0, 10, 20, 0],
        };
      }

      const imagePage = currentPage - indexPagesCount;
      const totalImagePages = files.length;
      return {
        text: `Page ${imagePage} of ${totalImagePages}${
          footerText ? " | " + footerText : ""
        }`,
        alignment: "right",
        margin: [0, 10, 20, 0],
    };
    },
    content: [...indexContent, ...imageContent],
  };

  return new Promise((resolve) => {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBuffer((buffer) => {
      const blob = new Blob([buffer], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "download.pdf";
      link.click();
      resolve();
    });
  });
};

export default downloadPDF;
