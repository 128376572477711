import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";

const FilterDropdown = ({
  title, // Title of the filter like "Type", "Tags"
  items, // List of items to display in dropdown
  isMultiSelect = false, // Flag to determine if it's multi-select or single-select
  showSearch = false, // Flag to show search bar
  onItemSelect, // Callback for when an item is selected
  onClearSelection, // Callback for when the selection is cleared
  placeholder = "Search...", // Placeholder for the search input
  initialSelectedItems = [], // List of items to display as selected by default
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filteredItems, setFilteredItems] = useState(items); // Filtered items to display
  const [orderedItems, setOrderedItems] = useState(items); // Ordered items to show selected items at top in multiselect dropdown
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems); // List of selected items
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setSelectedItems(initialSelectedItems);
  }, [initialSelectedItems]);

  useEffect(() => {
    if (searchTerm) {
      setFilteredItems(
        orderedItems.filter((item) =>
          item.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredItems(orderedItems);
    }
  }, [searchTerm, orderedItems]);

  const toggleDropdown = () => {
    if (!dropdownOpen && isMultiSelect) {
      // Reorder items to show selected items at the top only when opening the dropdown
      const reorderedList = [
        ...items.filter((item) => selectedItems.includes(item)),
        ...items.filter((item) => !selectedItems.includes(item)),
      ];
      setFilteredItems(reorderedList);
      setOrderedItems(reorderedList);
    }
    setDropdownOpen((prevState) => !prevState);
  };

  const handleItemSelect = (item) => {
    if (isMultiSelect) {
      if (selectedItems.includes(item)) {
        const updatedItems = selectedItems.filter((i) => i !== item);
        setSelectedItems(updatedItems);
        onItemSelect && onItemSelect(updatedItems);
      } else {
        const updatedItems = [...selectedItems, item];
        setSelectedItems(updatedItems);
        onItemSelect && onItemSelect(updatedItems);
      }
    } else {
      setSelectedItems([item]);
      onItemSelect && onItemSelect([item]);
      setDropdownOpen(false); // Close dropdown on single select
    }
    // onItemSelect && onItemSelect(item, isMultiSelect ? selectedItems : []);
  };

  const handleClearSelection = () => {
    setSelectedItems([]);
    setSearchTerm("");
    setFilteredItems(items);
    setOrderedItems(items);
    onClearSelection && onClearSelection();
  };

  const formatSelectedItemsForDisplay = (items) => {
    const itemCount = items.length;
    const maxItemsToShow = 2; // Maximum number of items to display fully
    const maxCharPerItem = 15; // Maximum characters allowed per item

    // Helper function to trim an item
    const trimItem = (item) => {
      return item.length > maxCharPerItem
        ? `${item.substring(0, maxCharPerItem)}...`
        : item;
    };

    if (itemCount <= maxItemsToShow) {
      return items.map(trimItem).join(", ");
    } else {
      const remainingCount = itemCount - maxItemsToShow;
      return `${trimItem(items[0])}, ${trimItem(items[1])} +${remainingCount}`;
    }
  };

  return (
    <div className="filter-dropdown-container">
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        className="filter-dropdown"
      >
        <DropdownToggle
          className={`dropdown-button text-dark ${
            selectedItems.length > 0 ? "selected" : "bg-transparent"
          }`}
          size={"sm"}
        >
          {selectedItems.length === 0
            ? title
            : formatSelectedItemsForDisplay(selectedItems)}{" "}
          <i
            className="material-icons"
            style={{ fontSize: 18, lineHeight: 0, verticalAlign: "middle" }}
          >
            {dropdownOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
          </i>
        </DropdownToggle>
        <DropdownMenu>
          {showSearch && (
            <>
              <div className="dropdown-search-container">
                <i className="material-icons" style={{ color: "darkgray" }}>
                  search
                </i>
                <Input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder={placeholder}
                  className="dropdown-search"
                />
              </div>
              <DropdownItem divider />
            </>
          )}
          {filteredItems.map((item, index) => (
            <DropdownItem
              key={index}
              toggle={false}
              className="dropdown-item"
              onClick={() => handleItemSelect(item)}
            >
              {(isMultiSelect || selectedItems.length > 0) && (
                <div className="dropdown-icons-container">
                  {isMultiSelect ? (
                    <Input
                      type="checkbox"
                      checked={selectedItems.includes(item)}
                      readOnly
                      className="dropdown-checkbox"
                    />
                  ) : (
                    selectedItems.includes(item) && (
                      <i className="material-icons dropdown-selected-icon">
                        check
                      </i>
                    )
                  )}
                </div>
              )}
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      {selectedItems.length > 0 && (
        <div className="dropdown-clear-button" onClick={handleClearSelection}>
          <i className="material-icons">clear</i>
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
