import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "reactstrap";
import { SHORTCUTS } from "../../../../constants/shortcuts";
import { updateFileDetailsAction } from "../../../../redux/slices/active-folder-slice";
import { resetShortcutKey } from "../../../../redux/slices/shortcut-slice";
import {
  formatDateForInput,
  formatDateToReadableString,
} from "../../../../utils/date-utils";
import PlusButton from "../../../common/plus-button";
import TooltipCustom from "../../../common/tooltip-custom";

const ReferenceDateComponent = ({ file }) => {
  const dispatch = useDispatch();

  const inputRef = useRef(null);

  const [initialDate, setInitialDate] = useState(file?.reference_date || "");
  const [currentDate, setCurrentDate] = useState(
    formatDateForInput(initialDate)
  );
  const [isEditing, setIsEditing] = useState(false);

  const user = useSelector((state) => state.user);
  const shortcutKey = useSelector((state) => state.shortcut.shortcutKey);

  useEffect(() => {
    if (file) {
      const date = file.reference_date;
      setInitialDate(date);
      setCurrentDate(formatDateForInput(date));
    }
  }, [file]);

  // Focus the input field when editing starts
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    if (shortcutKey === SHORTCUTS.EDIT_REFERENCE_DATE) {
      if (!isEditing) {
        setIsEditing(true);
      } else if (isEditing && inputRef.current) {
        inputRef.current.focus(); // Focus the input field
      }
      dispatch(resetShortcutKey()); // Reset shortcut after handling
    }
  }, [shortcutKey]);

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSave();
    } else if (e.key === "Escape") {
      e.stopPropagation(); // Stop propagation to parent
      handleCancel();
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setCurrentDate(formatDateForInput(initialDate));
  };

  const handleClear = () => {
    setCurrentDate("");
  };

  const handleSave = () => {
    if (file && file._id) {
      dispatch(
        updateFileDetailsAction({
          file: file,
          userId: user.id,
          updates: { reference_date: currentDate },
          successHandler: () => {
            setIsEditing(false);
          },
        })
      );
    }
  };

  return (
    <>
      <div className="reference-date-component editable-text-card">
        <div className="tab-section-header no-select">
          <div className="title">Reference Date</div>
          {!isEditing && initialDate && (
            <>
              <i
                id={"id-edit-ref-date"}
                className="material-icons icon-button"
                onClick={handleEdit}
              >
                edit
              </i>
              <TooltipCustom
                text={"Edit Reference Date"}
                target="id-edit-ref-date"
              />
            </>
          )}
        </div>

        {isEditing ? (
          <div>
            <Input
              innerRef={inputRef}
              type="date"
              value={currentDate || ""}
              onKeyDown={handleInputKeyPress}
              onChange={(e) => setCurrentDate(e.target.value)}
            />
            <div className="d-flex mt-2 justify-content-between">
              <div>
                <Button color="primary" size="sm" onClick={handleSave}>
                  Save
                </Button>
                <Button color="link" size="sm" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
              {currentDate?.length > 0 && (
                <Button color="link" size="sm" onClick={handleClear}>
                  Clear
                </Button>
              )}
            </div>
          </div>
        ) : (
          currentDate && (
            <div className="text-display">
              <React.Fragment>
                {formatDateToReadableString(currentDate, false)}
              </React.Fragment>
            </div>
          )
        )}

        {!currentDate && !isEditing && (
          <>
            <PlusButton label={"Add Reference Date"} onClick={handleEdit} />
            <TooltipCustom
              text={
                "Add Reference Date (" + SHORTCUTS.EDIT_REFERENCE_DATE + ")"
              }
              target="id_add-reference-date"
            />
          </>
        )}
      </div>
    </>
  );
};

export default ReferenceDateComponent;
