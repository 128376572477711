import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardTitle } from "reactstrap";
import routes from "../../layout/routes/routes";
import ImageViewerModal from "../image-viewer/image-viewer-modal";

const NotificationAttachmentsList = ({ folders, files }) => {
  const navigate = useNavigate();

  const [selectedFileIndex, setSelectedFileIndex] = useState(null);
  const [imageViewerModal, setImageViewerModal] = useState(false);

  const sharedStorageRoute = routes.find(
    (route) => route.id === "SHARED_STORAGE"
  );

  const handleFolderClick = (folder) => {
    if (!folder.is_bin) {
      navigate(
        `${sharedStorageRoute.layout + sharedStorageRoute.path}?folderId=${
          folder._id
        }`
      );
    }
  };

  const handleFileClick = (index) => {
    setImageViewerModal(true);
    setSelectedFileIndex(index);
  };

  return (
    <>
      {files?.length + folders?.length > 0 && (
        <div className="attachments-list">
          <div className="grid">
            {folders.map((folder) => (
              <Card
                className="notification-attachment-item no-select"
                onClick={() => handleFolderClick(folder)}
              >
                <CardBody className="d-flex align-items-center">
                  <i className="material-icons folder-icon">folder</i>
                  <CardTitle className="mb-0 name">
                    {folder.folder_name}
                  </CardTitle>
                </CardBody>
              </Card>
            ))}
            {files.map((file, index) => (
              <Card
                className="notification-attachment-item no-select"
                onClick={() => handleFileClick(index)}
              >
                <CardBody className="d-flex align-items-center">
                  <i className="material-icons file-icon">image</i>
                  <CardTitle className="mb-0 name">
                    {file.alias?.length > 0 ? file.alias : file.name}
                  </CardTitle>
                </CardBody>
              </Card>
            ))}
          </div>
        </div>
      )}
      {imageViewerModal && (
        <ImageViewerModal
          isOpen={imageViewerModal}
          toggle={() => setImageViewerModal(!imageViewerModal)}
          files={files}
          currentFileIndex={selectedFileIndex}
        />
      )}
    </>
  );
};

export default NotificationAttachmentsList;
