import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner } from "reactstrap";
import { VIEW_MODES } from "../../constants/view-modes";
import {
  clearSelection,
  toggleSelection,
} from "../../redux/slices/multi-select-slice";
import { sortItems } from "../../utils/sort-utils";
import EmptyState from "../common/empty-state";
import FileThumbnails from "./file-thumbnails";
import FolderThumbnails from "./folder-thumbnails";

const FolderContent = ({
  folders,
  files,
  isLoading,
  emptyStateComponent,
  pagination = false,
  currentCount,
  totalCount,
  onLoadMore,
  isLoadingMore,
}) => {
  const dispatch = useDispatch();

  const [sortedFolders, setSortedFolders] = useState([]);
  const [sortedFiles, setSortedFiles] = useState([]);

  const viewMode = useSelector((state) => state.userPreference.viewMode);
  const sortBy = useSelector((state) => state.userPreference.sortBy);
  const isAscending = useSelector((state) => state.userPreference.isAscending);

  useEffect(() => {
    if (pagination) {
      // Do not sort locally in case of pagination
      setSortedFolders(folders);
    } else {
      // Sort folders
      setSortedFolders(
        sortItems(folders, sortBy, isAscending, "folder_name", "updated_at")
      );
    }
  }, [folders, sortBy, isAscending, pagination]);

  useEffect(() => {
    if (pagination) {
      // Do not sort locally in case of pagination
      setSortedFiles(files);
    } else {
      // Sort files
      setSortedFiles(
        sortItems(files, sortBy, isAscending, "name", "updated_at")
      );
    }
  }, [files, sortBy, isAscending, pagination]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideItems);
    return () => {
      document.removeEventListener("click", handleClickOutsideItems);
    };
  }, []);

  const handleClickOutsideItems = (event) => {
    // Check if the click is on a selectable item
    const isItemClicked = event.target.closest("[data-selectable-item]");

    const isInsideStorageContent = event.target.closest(".storage-content");

    // Check if the click is on the modal or its children
    const isModalClicked = event.target.closest(".modal");

    if (
      !isItemClicked &&
      !isModalClicked &&
      !event.ctrlKey &&
      !event.shiftKey &&
      isInsideStorageContent
    ) {
      dispatch(clearSelection());
    }
  };

  const handleToggle = (item, index, event) => {
    // Combine folders and files into a single unified list
    const allItems = [
      ...sortedFolders.map((folder) => ({ ...folder, type: "folder" })),
      ...sortedFiles.map((file) => ({ ...file, type: "file" })),
    ];

    dispatch(
      toggleSelection({
        item,
        itemIndex: index,
        allItems: allItems,
        isControlPressed: event.ctrlKey,
        isShiftPressed: event.shiftKey,
      })
    );
  };

  return (
    <div className="storage-content">
      {isLoading ? (
        <>
          <div className="loader-container">
            <Spinner color="primary" />
          </div>
        </>
      ) : (folders && folders.length > 0) || (files && files.length > 0) ? (
        viewMode === VIEW_MODES.THUMBNAILS ? (
          <>
            <FolderThumbnails
              folders={sortedFolders}
              onSelect={(folder, index, event) =>
                handleToggle(folder, index, event)
              }
            />
            <FileThumbnails
              files={sortedFiles}
              onSelect={(file, index, event) =>
                handleToggle(file, index + sortedFolders.length, event)
              }
            />
            {pagination && currentCount < totalCount && !isLoadingMore && (
              <div className="pagination-container">
                <Button color="secondary" onClick={onLoadMore}>
                  Load more
                </Button>
              </div>
            )}
            {isLoadingMore && (
              <div className="pagination-container">
                <Spinner color="primary" />
              </div>
            )}
          </>
        ) : (
          <EmptyState text="Coming Soon. Please check back later." />
        )
      ) : emptyStateComponent ? (
        emptyStateComponent
      ) : (
        <EmptyState text="Nothing found" />
      )}
    </div>
  );
};

export default FolderContent;
