import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addToFavorites,
  getAllFavorites,
  removeFromFavorites,
} from "../services/favorite-service";
import { renameFile, renameFolder } from "./active-folder-slice";
import { clearSelection } from "./multi-select-slice";
import { moveToTrashAction } from "./trash-slice";

const initialState = {
  favorites: {
    folders: [],
    files: [],
  },
  isLoadingFetchFavorites: true,
};

export const addToFavoritesAction = createAsyncThunk(
  "folder/addToFavorites",
  async ({ orgId, userId, folderIds, fileIds }, { dispatch }) => {
    const data = await addToFavorites(orgId, userId, folderIds, fileIds);
    return { data: data, fileIds: fileIds, folderIds: folderIds };
  }
);

export const removeFromFavoritesAction = createAsyncThunk(
  "folder/removeFromFavorites",
  async ({ orgId, userId, folderIds, fileIds }, { dispatch }) => {
    const data = await removeFromFavorites(orgId, userId, folderIds, fileIds);
    dispatch(clearSelection());
    return { ...data, folderIds, fileIds };
  }
);

export const getAllFavoritesAction = createAsyncThunk(
  "folder/getAllFavorites",
  async ({ orgId, userId }, { dispatch }) => {
    const data = await getAllFavorites(orgId, userId);
    return data;
  }
);

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(removeFromFavoritesAction.fulfilled, (state, action) => {
        const { folderIds, fileIds } = action.payload;

        // Ensure favorites is defined
        if (!state.favorites) {
          return; // if favorites is undefined, no need to proceed
        }

        // Remove from files
        state.favorites.files = state.favorites.files?.filter(
          (file) => !fileIds.includes(file._id)
        );
        // Remove from folders
        state.favorites.folders = state.favorites.folders?.filter(
          (folder) => !folderIds.includes(folder._id)
        );
      })
      .addCase(getAllFavoritesAction.pending, (state) => {
        state.isLoadingFetchFavorites = true;
      })
      .addCase(getAllFavoritesAction.fulfilled, (state, action) => {
        state.favorites.files = action.payload.files;
        state.favorites.folders = action.payload.folders;
        state.isLoadingFetchFavorites = false;
      })

      // ====================
      // Extra Reducers for : Rename
      // ====================
      .addCase(renameFolder.fulfilled, (state, action) => {
        if (state.favorites) {
          state.favorites.folders = state.favorites.folders?.map((folder) =>
            folder._id === action.payload.folderId
              ? { ...folder, folder_name: action.payload.newName }
              : folder
          );
        }
      })
      .addCase(renameFile.fulfilled, (state, action) => {
        if (state.favorites) {
          state.favorites.files = state.favorites.files?.map((file) =>
            file._id === action.payload.fileId
              ? { ...file, name: action.payload.newName }
              : file
          );
        }
      })

      // ====================
      // Extra Reducers for Trash
      // ====================
      .addCase(moveToTrashAction.fulfilled, (state, action) => {
        const { folderIds, fileIds } = action.payload;
        if (state.favorites) {
          // Remove from files
          state.favorites.files = state.favorites.files?.filter(
            (file) => !fileIds.includes(file._id)
          );
          // Remove from folders
          state.favorites.folders = state.favorites.folders?.filter(
            (folder) => !folderIds.includes(folder._id)
          );
        }
      });
  },
});

export const {} = favoritesSlice.actions;

export const getFavorites = (state) => state.favorites.favorites;

export default favoritesSlice.reducer;
