import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchTags, searchFiles } from "../services/search-service";
import {
  renameFile,
  renameFolder,
  updateFileDetailsAction,
} from "./active-folder-slice";
import {
  addToFavoritesAction,
  removeFromFavoritesAction,
} from "./favorites-slice";
import { moveToTrashAction } from "./trash-slice";

const initialState = {
  files: [],
  folders: [],
  totalFiles: 0,
  totalFolders: 0,
  notes: [],
};

export const searchFilesAction = createAsyncThunk(
  "searchs/search-file",
  async ({ filters, userId, successHandler }, { dispatch }) => {
    const data = await searchFiles(filters, userId, successHandler);
    return { data, page: filters.page };
  }
);

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setResult: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // ====================
      // Extra Reducers for : searchFilesAction
      // ====================
      .addCase(searchFilesAction.fulfilled, (state, action) => {
        // Update the state
        const { data, page } = action.payload;
        if (page > 1) {
          // Append new files and folders
          state.files = [...state.files, ...data.files];
        } else {
          // Replace existing data on initial load
          state.files = data.files;
          state.folders = data.folders;
          state.totalFiles = data.totalFiles;
          state.totalFolders = data.totalFolders;
        }
      })

      // ====================
      // Extra Reducers for : Rename
      // ====================
      .addCase(renameFolder.fulfilled, (state, action) => {
        state.folders = state.folders.map((folder) =>
          folder._id === action.payload.folderId
            ? { ...folder, folder_name: action.payload.newName }
            : folder
        );
      })
      .addCase(renameFile.fulfilled, (state, action) => {
        state.files = state.files.map((file) =>
          file._id === action.payload.fileId
            ? { ...file, name: action.payload.newName }
            : file
        );
      })

      // ====================
      // Extra Reducers for favorites
      // ====================
      .addCase(addToFavoritesAction.fulfilled, (state, action) => {
        const { folderIds, fileIds } = action.payload;

        // Add to files
        state.files = state.files.map((file) =>
          fileIds.includes(file._id) ? { ...file, is_favorite: true } : file
        );
        // Add to folders
        state.folders = state.folders.map((folder) =>
          folderIds.includes(folder._id)
            ? { ...folder, is_favorite: true }
            : folder
        );
      })
      .addCase(removeFromFavoritesAction.fulfilled, (state, action) => {
        const { folderIds, fileIds } = action.payload;

        // Remove from files
        state.files = state.files.map((file) =>
          fileIds.includes(file._id) ? { ...file, is_favorite: false } : file
        );
        // Remove from folders
        state.folders = state.folders.map((folder) =>
          folderIds.includes(folder._id)
            ? { ...folder, is_favorite: false }
            : folder
        );
      })

      // ====================
      // Extra Reducers for Trash
      // ====================
      .addCase(moveToTrashAction.fulfilled, (state, action) => {
        const { folderIds, fileIds } = action.payload;
        // Remove from files
        state.files = state.files.filter((file) => !fileIds.includes(file._id));
        // Remove from folders
        state.folders = state.folders.filter(
          (folder) => !folderIds.includes(folder._id)
        );
      })

      // ====================
      // Extra Reducers for : updateFileDetailsAction
      // ====================
      .addCase(updateFileDetailsAction.fulfilled, (state, action) => {
        // ToDo: handle all update operations.

        const fileToUpdate = state.files.find(
          (file) => file._id === action.payload.file._id
        );

        // Update details
        if (fileToUpdate) {
          action.payload.notes !== undefined &&
            (fileToUpdate.notes = action.payload.notes);
          action.payload.tags !== undefined &&
            (fileToUpdate.tags = action.payload.tags);
          if (action.payload.title != undefined) {
            fileToUpdate.title = action.payload.title;
            if (fileToUpdate?.alias && !fileToUpdate?.is_alias_custom) {
              fileToUpdate.alias = action.payload.title;
            }
          }
          action.payload.heading != undefined &&
            (fileToUpdate.heading = action.payload.heading);
          action.payload.marathi_text != undefined &&
            (fileToUpdate.marathi_text = action.payload.marathi_text);
          action.payload.person_names != undefined &&
            (fileToUpdate.person_names = action.payload.person_names);
          action.payload.reference_date != undefined &&
            (fileToUpdate.reference_date = action.payload.reference_date);
          action.payload.source != undefined &&
            (fileToUpdate.source = action.payload.source);
        }
      });
  },
});

export const { setResult } = searchSlice.actions;

export const getSearchResultFiles = (state) => state.search.files;
export const getSearchResultfolders = (state) => state.search.folders;
export const getSearchResultCount = (state) =>
  state.search.totalFiles + state.search.totalFolders;
export const getSearchFetchedResultCount = (state) =>
  state.search.files.length + state.search.folders.length;

export default searchSlice.reducer;
