import html2canvas from "html2canvas";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tesseract from "tesseract.js";
import {
  setIsOcrReaderActive,
  setIsOcrResultWindowActive,
} from "../../../redux/slices/ocr-reader-slice";
import OcrReaderResultWindow from "./ocr-reader-result-window";
import OcrRegionCursor from "./ocr-region-cursor";
import OcrRegionSelectionBox from "./ocr-region-selection-box";

const useOcrReader = () => {
  const dispatch = useDispatch();

  const [isSelecting, setIsSelecting] = useState(false);
  const [startPos, setStartPos] = useState(null);
  const [rectangleStyle, setRectangleStyle] = useState({});
  const ocrTargetRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("Initializing...");
  const [ocrText, setOcrText] = useState("");
  const [isError, setIsError] = useState(false);
  const [isProcessCompleted, setIsProcessCompleted] = useState(false);

  const isOcrReaderActive = useSelector(
    (state) => state.ocrReader.isOcrReaderActive
  );
  const isOcrResultWindowActive = useSelector(
    (state) => state.ocrReader.isOcrResultWindowActive
  );

  const handleOcrMouseDown = (e) => {
    if (!ocrTargetRef.current || !isOcrReaderActive) return;

    setIsSelecting(true);
    setRectangleStyle({});
    const rect = ocrTargetRef.current.getBoundingClientRect();
    setStartPos({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  const handleOcrMouseMove = (e) => {
    if (!ocrTargetRef.current || !isOcrReaderActive) return;

    const rect = ocrTargetRef.current.getBoundingClientRect();
    const currentPos = { x: e.clientX - rect.left, y: e.clientY - rect.top };

    // Update mouse position for cursor display
    setMousePosition(currentPos);

    if (!isSelecting || !startPos) return;

    setRectangleStyle({
      left: `${Math.min(startPos.x, currentPos.x)}px`,
      top: `${Math.min(startPos.y, currentPos.y)}px`,
      width: `${Math.abs(currentPos.x - startPos.x)}px`,
      height: `${Math.abs(currentPos.y - startPos.y)}px`,
    });
  };

  const handleOcrMouseUp = () => {
    if (!isSelecting) return;

    setIsSelecting(false);
    dispatch(setIsOcrReaderActive(false));

    // Perform OCR only if rectangle is valid
    if (
      parseInt(rectangleStyle.width) > 5 &&
      parseInt(rectangleStyle.height) > 5
    ) {
      // Make sure isSelecting is false to avoid rectangle black shadow visible issue. So delaying perform ocr.
      setTimeout(() => {
        performOCR();
      }, 0); // Important
    }
  };

  const performOCR = async () => {
    if (!ocrTargetRef.current) return;

    setIsProcessing(true);
    setProgress(0);
    setStatus("Initializing...");
    setIsError(false);
    setOcrText("");
    setIsProcessCompleted(false);
    // Make sure all states above reflect their changes inside result window before opening the window.
    // This will avoid UI glitches.
    setTimeout(() => {
      dispatch(setIsOcrResultWindowActive(true));
    }, 100); // Important

    const canvas = await html2canvas(ocrTargetRef.current, {
      x: parseInt(rectangleStyle.left),
      y: parseInt(rectangleStyle.top),
      width: parseInt(rectangleStyle.width),
      height: parseInt(rectangleStyle.height),
      useCORS: true,
    });

    const croppedImage = canvas.toDataURL("image/png");

    // Download the cropped image for debugging
    // const downloadLink = document.createElement("a");
    // downloadLink.href = croppedImage;
    // downloadLink.download = "selected-region.png";
    // downloadLink.click();

    // Use both Marathi and English languages
    Tesseract.recognize(croppedImage, "eng+mar", {
      logger: (m) => {
        if (
          m.status === "loading tesseract core" ||
          m.status === "initializing tesseract"
        ) {
          setStatus("Initializing...");
        } else if (m.status === "loading language traineddata") {
          setStatus("Loading Language Model...");
        } else if (m.status === "initializing api") {
          setStatus("Preprocessing...");
        } else if (m.status === "recognizing text") {
          setProgress(m.progress * 100);
          setStatus("Recognizing Text...");
        }
      },
    })
      .then(({ data: { text } }) => {
        setStatus("OCR completed");
        setOcrText(text);
      })
      .catch((err) => {
        console.error("OCR Error:", err);
        setStatus("OCR failed");
        setIsError(true);
      })
      .finally(() => {
        setIsProcessing(false);
        setIsProcessCompleted(true); // Process completed. Either success or error.
      });
  };

  const ocrCursorComponent = (
    <>
      {isOcrReaderActive && <OcrRegionCursor mousePosition={mousePosition} />}
    </>
  );

  const ocrSelectionBoxComponent = (
    <>
      {isOcrReaderActive && isSelecting && (
        <OcrRegionSelectionBox rectangleStyle={rectangleStyle} />
      )}
    </>
  );

  const ocrResultWindowComponent = (
    <>
      {isOcrResultWindowActive && (
        <OcrReaderResultWindow
          isProcessing={isProcessing}
          progress={progress}
          status={status}
          ocrText={ocrText}
          isError={isError}
          isProcessCompleted={isProcessCompleted}
        />
      )}
    </>
  );

  return {
    ocrTargetRef,
    handleOcrMouseDown,
    handleOcrMouseMove,
    handleOcrMouseUp,
    ocrCursorComponent,
    ocrSelectionBoxComponent,
    ocrResultWindowComponent,
  };
};

export default useOcrReader;
