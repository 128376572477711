import React from "react";
import noPhoto from "../../../../assets/images/no-photo.svg";

const UserSuggestion = ({ user }) => (
  <div
    className="d-flex align-items-center gap-2"
    style={{ cursor: "pointer" }}
  >
    <div className="d-flex align-items-center">
      <img style={{ width: 35, borderRadius: "50%" }} src={noPhoto} />
    </div>
    <div>
      <strong>{user.first_name + " "+user.last_name}</strong>
      <br />
      <span>{user.email}</span>
    </div>
  </div>
);

export default UserSuggestion;
