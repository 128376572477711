import React from "react";
import ItemsCountView from "./items-count-view";
import SelectedItemsBadge from "./selected-items-badge";
import SortDropdown from "./sort-dropdown";

const SelectAndSortToolbar = () => {
  return (
    <>
      <div className="select-and-sort-toolbar no-select">
        <div className="select-options-container">
          <ItemsCountView />
          <SelectedItemsBadge />
        </div>
        <div className="sort-options-container">
          <SortDropdown />
        </div>
      </div>
    </>
  );
};

export default SelectAndSortToolbar;
