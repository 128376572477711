import React from "react";

import classnames from "classnames";
import { Navbar } from "reactstrap";
import SearchBar from "../../components/search/search-bar";
import NotificationsButton from "./notifications-button";
import ProfileDropdown from "./profile-dropdown";

const MainNavbar = (props) => {
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };

  return (
    <>
      <Navbar
        color="white"
        light
        expand="lg"
        className="navbar-plain no-select"
      >
        <div className="navbar-wrapper">
          <div
            className={classnames("navbar-toggle", {
              toggled: props.sidebarOpen,
            })}
          >
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleSidebar}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
        </div>

        <div className="navbar-content">
          <SearchBar />
          <div className="d-flex gap-3">
            <NotificationsButton />
            <ProfileDropdown />
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default MainNavbar;
