import { createHeaders, handleErrors, parseJSON } from "./api-middleware";
import { API_BASE_URL } from "./base-url";

export const fetchTrashContents = async (orgId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/trash/${orgId}`, {
      method: "GET",
      headers: createHeaders(),
    });

    await handleErrors(response);
    const data = await parseJSON(response);

    const sanitizeDate = (dateString) => {
      if (!dateString) return null;
      const date = new Date(dateString);
      return isNaN(date.getTime()) ? null : date.toISOString();
    };

    const transformedData = {
      ...data,
      trashed_images: data.trashedImages.map((image) => ({
        ...image,
        folder_id: image.folder_id || "trash",
        _id: image.image_id,
        created_at: sanitizeDate(image.created_at),
        updated_at: sanitizeDate(image.updated_at),
        deleted_at: sanitizeDate(image.deleted_at),
        lang: image.language,
      })),
      trashed_folders: data.trashedFolders.map((folder) => ({
        ...folder,
        folder_name: folder.name,
        _id: folder.folder_id,
        is_bin: folder.is_bin,
        created_at: sanitizeDate(folder.created_at),
        updated_at: sanitizeDate(folder.updated_at),
        deleted_at: sanitizeDate(folder.deleted_at),
      })),
    };

    return transformedData;
  } catch (error) {
    console.error("Error fetching trash contents:", error);
    throw error;
  }
};

export const moveToTrashService = async (
  orgId,
  userId,
  folderIds,
  fileIds,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/bulk-operations/move-to-bin`,
      {
        method: "POST",
        headers: createHeaders(),
        body: JSON.stringify({
          orgId,
          userId,
          folderIds,
          imageIds: fileIds,
        }),
      }
    );

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const restoreFromTrashService = async (
  orgId,
  userId,
  folderIds,
  fileIds,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/bulk-operations/restore`,
      {
        method: "POST",
        headers: createHeaders(),
        body: JSON.stringify({
          orgId,
          userId,
          folderIds,
          imageIds: fileIds,
        }),
      }
    );

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const emptyTrashService = async (
  orgId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/trash/empty/${orgId}`, {
      method: "POST",
      headers: createHeaders(),
    });

    await handleErrors(response);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    console.error("Error emptying trash:", error);
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const deleteForeverService = async (
  folderIds,
  fileIds,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/bulk-operations/delete`, {
      method: "DELETE",
      headers: createHeaders(),
      body: JSON.stringify({
        folderIds,
        imageIds: fileIds,
      }),
    });

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};
