import React from "react";

const OcrRegionSelectionBox = ({ rectangleStyle }) => {
  return (
    <>
      <div className="ocr-reader-region-wrapper">
        <div
          style={{
            ...rectangleStyle,
            position: "absolute",
            border: "1px dotted black",
            background: "transparent",
            boxShadow:
              "0 0 0 9999px rgba(0, 0, 0, 0.2)" /* Creates the cutout effect */,
          }}
        ></div>
      </div>
    </>
  );
};

export default OcrRegionSelectionBox;
