import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadAllAction } from "../../../redux/slices/active-folder-slice";
import {
  addToFavoritesAction,
  removeFromFavoritesAction,
} from "../../../redux/slices/favorites-slice";
import {
  deleteForeverAction,
  moveToTrashAction,
  restoreFromTrashAction,
} from "../../../redux/slices/trash-slice";
import ConfirmationModal from "../../common/confirmation-modal";
import ContextMenu from "../../common/context-menu";
import FolderTreeSelectionModal, {
  FOLDER_TREE_SELECTION_MODES,
} from "../../folder-tree/folder-tree-selection-modal";
import ShareModal from "../../share/share-modal";
import RenameModal from "../modals/rename-modal";

const FolderContextMenu = ({ folder, onFolderOpen, disabled }) => {
  const dispatch = useDispatch();

  const [renameModal, setRenameModal] = useState(false);
  const [folderTreeSelectionModal, setFolderTreeSelectionModal] =
    useState(false);
  const [folderTreeSelectionType, setFolderTreeSelectionType] = useState("");
  const [deleteForeverConfirmationModal, setdeleteForeverConfirmationModal] =
    useState(false);
  const [shareModal, setShareModal] = useState(false);

  const user = useSelector((state) => state.user);

  const toggleRenameModal = () => {
    setRenameModal(!renameModal);
  };
  const toggleFolderTreeSelectionModal = () => {
    setFolderTreeSelectionModal(!folderTreeSelectionModal);
  };
  const toggleDeleteForeverConfirmationModal = () => {
    setdeleteForeverConfirmationModal(!deleteForeverConfirmationModal);
  };
  const toggleShareModal = () => setShareModal(!shareModal);

  const handleClicks = () => {
    /* Dummy method. ToDo: Handle all clicks */
  };

  const handleRenameClick = () => {
    toggleRenameModal();
  };

  const handleDownloadClick = () => {
    dispatch(
      downloadAllAction({
        orgId: folder.org_id,
        userId: user.id,
        folders: [folder],
        files: [],
      })
    );
  };

  const handleCopyToClick = () => {
    setFolderTreeSelectionType(FOLDER_TREE_SELECTION_MODES.COPY);
    toggleFolderTreeSelectionModal();
  };

  const handleMoveToClick = () => {
    setFolderTreeSelectionType(FOLDER_TREE_SELECTION_MODES.MOVE);
    toggleFolderTreeSelectionModal();
  };

  const handleShareClick = () => {
    toggleShareModal();
  };

  const handleAddToFavoritesClick = () => {
    dispatch(
      addToFavoritesAction({
        orgId: folder.org_id,
        userId: user.id,
        folderIds: [folder._id],
        fileIds: [],
      })
    );
  };

  const handleRemoveFromFavoritesClick = () => {
    dispatch(
      removeFromFavoritesAction({
        orgId: folder.org_id,
        userId: user.id,
        folderIds: [folder._id],
        fileIds: [],
      })
    );
  };

  const handleMoveToTrashClick = () => {
    dispatch(
      moveToTrashAction({
        orgId: folder.org_id,
        userId: user.id,
        fileIds: [],
        folderIds: [folder._id],
      })
    );
  };

  const handleRestoreFromTrashClick = () => {
    dispatch(
      restoreFromTrashAction({
        orgId: folder.org_id,
        userId: user.id,
        fileIds: [],
        folderIds: [folder._id],
      })
    );
  };

  const handleDeleteForeverClick = () => {
    toggleDeleteForeverConfirmationModal();
  };

  const handleDeleteForever = () => {
    dispatch(
      deleteForeverAction({
        folderIds: [folder._id],
        fileIds: [],
        successHandler: () => {
          toggleDeleteForeverConfirmationModal();
        },
      })
    );
  };

  const menuItems = [
    {
      label: "Open",
      icon: "visibility",
      onClick: onFolderOpen,
    },
    {
      divider: true, // This will add a divider
    },
    {
      label: "Download",
      icon: "download",
      onClick: handleDownloadClick,
    },
    {
      label: "Rename",
      icon: "edit",
      onClick: handleRenameClick,
    },
    {
      divider: true, // This will add a divider
    },
    // {
    //   label: "Copy to...",
    //   icon: "copy",
    //   onClick: handleCopyToClick,
    // },
    {
      label: "Move to...",
      icon: "drive_file_move",
      onClick: handleMoveToClick,
    },
    {
      divider: true, // This will add a divider
    },
    {
      label: "Share",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -860 960 960"
          width="20px"
          fill="#212529"
        >
          <path d="M680-80q-50 0-85-35t-35-85q0-6 3-28L282-392q-16 15-37 23.5t-45 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q24 0 45 8.5t37 23.5l281-164q-2-7-2.5-13.5T560-760q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-24 0-45-8.5T598-672L317-508q2 7 2.5 13.5t.5 14.5q0 8-.5 14.5T317-452l281 164q16-15 37-23.5t45-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T720-200q0-17-11.5-28.5T680-240q-17 0-28.5 11.5T640-200q0 17 11.5 28.5T680-160ZM200-440q17 0 28.5-11.5T240-480q0-17-11.5-28.5T200-520q-17 0-28.5 11.5T160-480q0 17 11.5 28.5T200-440Zm480-280q17 0 28.5-11.5T720-760q0-17-11.5-28.5T680-800q-17 0-28.5 11.5T640-760q0 17 11.5 28.5T680-720Zm0 520ZM200-480Zm480-280Z" />
        </svg>
      ),
      onClick: handleShareClick,
    },
    folder.is_favorite
      ? {
          label: "Remove from favourites",
          icon: "star",
          filledIcon: true,
          onClick: handleRemoveFromFavoritesClick,
        }
      : {
          label: "Add to favourites",
          icon: "grade",
          onClick: handleAddToFavoritesClick,
        },
    {
      divider: true, // This will add a divider
    },
    {
      label: "Move to trash",
      icon: "delete",
      onClick: handleMoveToTrashClick,
    },
  ];

  const trashMenuItems = [
    {
      label: "Restore",
      icon: "restore",
      onClick: handleRestoreFromTrashClick,
    },
    {
      label: "Delete Forever",
      icon: "delete_forever",
      onClick: handleDeleteForeverClick,
    },
  ];

  return (
    <>
      <ContextMenu
        menuText="New"
        menuItems={folder.is_bin ? trashMenuItems : menuItems}
        type="more"
        disabled={disabled}
      />
      <RenameModal
        isOpen={renameModal}
        toggle={toggleRenameModal}
        item={folder}
        isFile={false}
      />
      <FolderTreeSelectionModal
        isOpen={folderTreeSelectionModal}
        toggle={toggleFolderTreeSelectionModal}
        type={folderTreeSelectionType}
        folders={[folder]}
        files={[]}
      />
      <ConfirmationModal
        isOpen={deleteForeverConfirmationModal}
        toggle={toggleDeleteForeverConfirmationModal}
        title="Delete folder forever?"
        description={`"${folder.name}" and all its contents will be deleted permanently and you won't be able to restore it again.`}
        confirmButtonText="Delete forever"
        onConfirm={handleDeleteForever}
        isDanger={true}
      />
      <ShareModal
        isOpen={shareModal}
        toggle={toggleShareModal}
        folders={[folder]}
      />
    </>
  );
};

export default FolderContextMenu;
