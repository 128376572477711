import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFileDetailsAction } from "../../../../redux/slices/active-folder-slice";
import EditableTextCard from "../../../common/editable-text-card";
import EmptyState from "../../../common/empty-state";

const OcrTextComponent = ({ file }) => {
  const dispatch = useDispatch();

  const [version, setVersion] = useState(0); // Version counter to force re-render in case saving fails

  const user = useSelector((state) => state.user);

  const hasContent =
    file?.title ||
    file?.heading ||
    file?.marathi_text ||
    file?.person_names?.length > 0;

  useEffect(() => {
    setVersion((prevVersion) => prevVersion + 1); // Trigger re-render on initialNote change
  }, [file]);

  const handleDispatchUpdate = (updatedFields) => {
    dispatch(
      updateFileDetailsAction({
        file: file,
        userId: user.id,
        updates: updatedFields,
      })
    );
    setVersion((prevVersion) => prevVersion + 1);
  };

  const handleSaveHeading1 = (newValue) => {
    handleDispatchUpdate({ title: cleanText(newValue) });
  };

  const handleSaveHeading2 = (newValue) => {
    handleDispatchUpdate({ heading: cleanText(newValue) });
  };

  const handleSaveText = (newValue) => {
    handleDispatchUpdate({ marathi_text: newValue });
  };

  const handleSavePeople = (newValue) => {
    handleDispatchUpdate({
      person_names: newValue.split(",").map((name) => name.trim()),
    });
  };

  const cleanText = (input) => {
    return input
      .trim() // Trim the text to remove leading/trailing spaces
      .replace(/(\r\n|\r|\n)/g, " ") // Replace all line breaks with a single space
      .replace(/\s+/g, " "); // Replace multiple spaces with a single space
  };

  return (
    <>
      <div
        className="ocr-text-component"
        key={version} // Use version to force component recreation
      >
        {hasContent ? (
          <>
            <div className="ocr-disclaimer">
              <strong>Disclaimer:</strong> This text was extracted using Optical
              Character Recognition (OCR) and may not always be 100% accurate,
              especially for complex or unclear images.
            </div>

            <EditableTextCard
              title="Heading 1"
              initialText={file.title}
              editable={true}
              onSave={handleSaveHeading1}
              placeholder="Type heading 1 here..."
            />

            <EditableTextCard
              title="Heading 2"
              initialText={file.heading}
              editable={true}
              onSave={handleSaveHeading2}
              placeholder="Type heading 2 here..."
            />

            <EditableTextCard
              title="Text"
              initialText={file.marathi_text}
              editable={true}
              onSave={handleSaveText}
              placeholder="Type text here..."
            />

            {/* {file?.person_names?.length > 0 ? (
              <EditableTextCard
                title="People"
                initialText={file.person_names.join(", ")}
                editable={true}
                onSave={handleSavePeople}
                placeholder="Type people here..."
              />
            ) : (
              <></>
            )} */}
          </>
        ) : (
          <EmptyState text="No data available. The text extraction process may not have detected any content in the image. If the image contains text, please ensure it is clear and readable for better results." />
        )}
      </div>
    </>
  );
};

export default OcrTextComponent;
