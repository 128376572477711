import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createFolder,
  fetchFolderHierarchy,
  fetchFoldersApi,
} from "../services/folder-service";
import { moveToAction, renameFolder } from "./active-folder-slice";
import {
  addToFavoritesAction,
  removeFromFavoritesAction,
} from "./favorites-slice";
import { moveToTrashAction } from "./trash-slice";

const initialState = {
  folders: [],
  isLoadingFetchFolders: true,
  sharedStorageFolderHierarchy: null,
  myStorageFolderHierarchy: null,
};

export const fetchFolders = createAsyncThunk(
  "folders/parent",
  async ({ orgId, successHandler }, { dispatch }) => {
    const data = await fetchFoldersApi(orgId, successHandler);
    return data; // Return user data for updating the state
  }
);

export const fetchSharedStorageFolderHierarchy = createAsyncThunk(
  "folders/sharedStorageFolderHierarchy",
  async (orgId, { dispatch }) => {
    const data = await fetchFolderHierarchy(orgId, "shared_storage");
    return data.hierarchy; // Return user data for updating the state
  }
);

export const fetchMyStorageFolderHierarchy = createAsyncThunk(
  "folders/myStorageFolderHierarchy",
  async (orgId, { dispatch }) => {
    const data = await fetchFolderHierarchy(orgId, "my_storage");
    return data; // Return user data for updating the state
  }
);

export const createFolderAction = createAsyncThunk(
  "folders/create",
  async ({ folder, successHandler }, { dispatch }) => {
    const data = await createFolder(folder, successHandler);
    return { data: data, folder: folder }; // Return user data for updating the state
  }
);

const foldersSlice = createSlice({
  name: "folders",
  initialState,
  reducers: {
    setFolders: (state, action) => {
      return {
        ...state,
        folders: [...action.payload],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // ====================
      // Extra Reducers for : fetchFolders
      // ====================
      .addCase(fetchFolders.pending, (state) => {
        state.isLoadingFetchFolders = true;
      })
      .addCase(fetchFolders.fulfilled, (state, action) => {
        // Update the state with user data
        state.folders = [...action.payload.folders];
        state.isLoadingFetchFolders = false;
      })

      // ====================
      // Extra Reducers for : fetchSharedStorageFolderHierarchy
      // ====================
      .addCase(fetchSharedStorageFolderHierarchy.fulfilled, (state, action) => {
        state.sharedStorageFolderHierarchy = action.payload;
      })

      // ====================
      // Extra Reducers for : createFolderAction
      // ====================
      .addCase(createFolderAction.fulfilled, (state, action) => {
        // After succes, add folder to folders list
        if (action.payload.folder != null) {
          state.folders = [
            ...state.folders,
            {
              ...action.payload.folder,
              _id: action.payload.data.folder_id,
            },
          ];
        }
      })

      // ====================
      // Extra Reducers for : moveToAction from active-folder-slice
      // ====================
      .addCase(moveToAction.fulfilled, (state, action) => {
        // After succes, remove folder from folders list
        const { folderIds, targetFolderId } = action.payload;
        if (targetFolderId != null) {
          state.folders = state.folders?.filter(
            (folder) => !folderIds.includes(folder._id)
          );
        }
      })

      // ====================
      // Extra Reducers for : renameFolder from active-folder-slice
      // ====================
      .addCase(renameFolder.fulfilled, (state, action) => {
        // Rename is success, change folder name in state
        state.folders = state.folders?.map((folder) =>
          folder._id === action.payload.folderId
            ? { ...folder, folder_name: action.payload.newName }
            : folder
        );
      })

      // ====================
      // Extra Reducers for : Favorites
      // ====================
      .addCase(addToFavoritesAction.fulfilled, (state, action) => {
        const { folderIds } = action.payload;
        // Add to folders
        state.folders = state.folders?.map((folder) =>
          folderIds.includes(folder._id)
            ? { ...folder, is_favorite: true }
            : folder
        );
      })
      .addCase(removeFromFavoritesAction.fulfilled, (state, action) => {
        const { folderIds } = action.payload;
        // Remove from folders
        state.folders = state.folders?.map((folder) =>
          folderIds.includes(folder._id)
            ? { ...folder, is_favorite: false }
            : folder
        );
      })

      // ====================
      // Extra Reducers for : Trash
      // ====================
      .addCase(moveToTrashAction.fulfilled, (state, action) => {
        const { folderIds } = action.payload;
        // Remove from folders
        state.folders = state.folders?.filter(
          (folder) => !folderIds.includes(folder._id)
        );
      });
  },
});

export const { setFolders } = foldersSlice.actions;

export const getSharedStorageFolderHierarchy = (state) =>
  state.folders.sharedStorageFolderHierarchy;
export const getMyStorageFolderHierarchy = (state) =>
  state.folders.myStorageFolderHierarchy;

export default foldersSlice.reducer;
