import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { SHORTCUTS } from "../../../../constants/shortcuts";
import { updateFileDetailsAction } from "../../../../redux/slices/active-folder-slice";
import {
  fetchSourcesAction,
  getSources,
} from "../../../../redux/slices/organization-master-slice";
import { resetShortcutKey } from "../../../../redux/slices/shortcut-slice";
import InputWithSuggestions from "../../../common/input-with-suggestions";
import PlusButton from "../../../common/plus-button";
import TooltipCustom from "../../../common/tooltip-custom";

const SourceComponent = ({ file }) => {
  const dispatch = useDispatch();

  const inputRef = useRef(null);

  const [initialInput, setInitialInput] = useState(file?.source || "");
  const [currentInput, setCurrentInput] = useState(initialInput);
  const [isEditing, setIsEditing] = useState(false);
  const [sourceSuggestions, setSourceSuggestions] = useState([]);

  const organization = useSelector((state) => state.organization);
  const user = useSelector((state) => state.user);
  const sourcesList = useSelector(getSources);
  const shortcutKey = useSelector((state) => state.shortcut.shortcutKey);

  useEffect(() => {
    if (file) {
      const source = file?.source || "";
      setInitialInput(source);
      setCurrentInput(source);
    }
  }, [file]);

  useEffect(() => {
    dispatch(fetchSourcesAction(organization.id));
  }, []);

  // Focus the input field when editing starts
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    if (currentInput.length > 0) {
      const filteredSources = sourcesList.filter((source) =>
        source.toLowerCase().includes(currentInput.toLowerCase())
      );
      setSourceSuggestions(filteredSources);
    } else {
      setSourceSuggestions(sourcesList);
    }
  }, [currentInput, sourcesList]);

  useEffect(() => {
    if (shortcutKey === SHORTCUTS.EDIT_SOURCE) {
      if (!isEditing) {
        setIsEditing(true);
      } else if (isEditing && inputRef.current) {
        inputRef.current.focus(); // Focus the input field
      }
      dispatch(resetShortcutKey()); // Reset shortcut after handling
    }
  }, [shortcutKey]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setCurrentInput(value);
  };

  const handleSuggestionClick = (suggestion) => {
    setCurrentInput(suggestion);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSave();
    } else if (e.key === "Escape") {
      e.stopPropagation(); // Stop propagation to parent
      handleCancel();
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setCurrentInput(initialInput);
  };

  const handleClear = () => {
    setCurrentInput("");
  };

  const handleSave = () => {
    if (
      file &&
      file._id &&
      (currentInput.trim().length == 0 ||
        sourcesList.some(
          (source) =>
            source.trim().toLowerCase() === currentInput.trim().toLowerCase()
        )) // Saving only in 2 cases 1. Found in sourcesList 2. Wants to remove source
    ) {
      dispatch(
        updateFileDetailsAction({
          file: file,
          userId: user.id,
          updates: { source: currentInput.trim() },
          successHandler: () => {
            setIsEditing(false);
          },
        })
      );
    }
  };

  return (
    <>
      <div className="source-component editable-text-card">
        <div className="tab-section-header no-select">
          <div className="title">Source</div>
          {!isEditing && initialInput && (
            <>
              <i
                id={"id-edit-source"}
                className="material-icons icon-button"
                onClick={handleEdit}
              >
                edit
              </i>
              <TooltipCustom text={"Edit Source"} target="id-edit-source" />
            </>
          )}
        </div>

        {isEditing ? (
          <div>
            <InputWithSuggestions
              ref={inputRef}
              placeholderText={"Enter source name"}
              currentInput={currentInput}
              handleInputChange={handleInputChange}
              handleInputKeyPress={handleInputKeyPress}
              suggestions={sourceSuggestions}
              handleSuggestionClick={handleSuggestionClick}
            />
            <div className="d-flex mt-2 justify-content-between">
              <div>
                <Button color="primary" size="sm" onClick={handleSave}>
                  Save
                </Button>
                <Button color="link" size="sm" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
              {currentInput?.length > 0 && (
                <Button color="link" size="sm" onClick={handleClear}>
                  Clear
                </Button>
              )}
            </div>
          </div>
        ) : (
          currentInput && <div className="text-display">{currentInput}</div>
        )}

        {!currentInput && !isEditing && (
          <>
            <PlusButton label={"Add Source"} onClick={handleEdit} />
            <TooltipCustom
              text={"Add Source (" + SHORTCUTS.EDIT_SOURCE + ")"}
              target="id_add-source"
            />
          </>
        )}
      </div>
    </>
  );
};

export default SourceComponent;
