import React from "react";
import noPhoto from "../../../../assets/images/no-photo.svg";
import { NOTIFICATION_TYPES } from "../../constants/notification-types";
import { getRelativeTime } from "../../utils/date-utils";
import NotificationAttachmentsList from "./notification-attachments-list";

const NotificationItem = ({ notification, onDelete, onMarkAsRead }) => {
  const {
    _id,
    type,
    sender,
    title,
    description,
    message,
    createdAt,
    thumbnail,
    is_read,
    files,
    folders,
  } = notification;

  const renderThumbnail = () => {
    if (type === NOTIFICATION_TYPES.SHARED_WITH_YOU) {
      // ToDo: Sender thumbnail
      return (
        <img
          style={{ width: 40, borderRadius: "50%" }}
          src={noPhoto}
          draggable={false}
        />
      );
    } else if (
      type === NOTIFICATION_TYPES.SYSTEM ||
      type === NOTIFICATION_TYPES.OTHER
    ) {
      return (
        <div className="default-notification-thumbnail">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#000000"
          >
            <path d="M720-440v-80h160v80H720Zm48 280-128-96 48-64 128 96-48 64Zm-80-480-48-64 128-96 48 64-128 96ZM200-200v-160h-40q-33 0-56.5-23.5T80-440v-80q0-33 23.5-56.5T160-600h160l200-120v480L320-360h-40v160h-80Zm240-182v-196l-98 58H160v80h182l98 58Zm120 36v-268q27 24 43.5 58.5T620-480q0 41-16.5 75.5T560-346ZM300-480Z" />
          </svg>
        </div>
      );
    }
    return null;
  };

  const renderTitle = () => {
    if (type === NOTIFICATION_TYPES.SHARED_WITH_YOU) {
      return (
        <>
          <strong>{sender?.first_name + " " + sender?.last_name}</strong> shared{" "}
          {folders?.length > 0 && files?.length > 0
            ? `${folders.length} folder${folders.length > 1 ? "s" : ""} and ${
                files.length
              } file${files.length > 1 ? "s" : ""}`
            : folders?.length > 0
            ? `${folders.length} folder${folders.length > 1 ? "s" : ""}`
            : files?.length > 0
            ? `${files.length} file${files.length > 1 ? "s" : ""}`
            : ""}{" "}
          with you
        </>
      );
    }
    return <strong>{title}</strong>;
  };

  const renderBody = () => {
    if (type === NOTIFICATION_TYPES.SHARED_WITH_YOU) {
      return (
        <div className="notification-body">
          {message?.length > 0 && (
            <div className="d-flex">
              <div className="message">"{message}"</div>
            </div>
          )}
          <NotificationAttachmentsList folders={folders} files={files} />
        </div>
      );
    } else if (description?.length > 0) {
      return (
        <div className="notification-body">
          <div className="description">{description}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div
        className="notification-item"
        onClick={() => {
          if (!notification?.is_read) {
            onMarkAsRead(_id);
          }
        }}
      >
        <div className="no-select">{renderThumbnail()}</div>
        <div className="notification-content">
          <div>
            <div className="title">{renderTitle()}</div>
            <div className="time-info text-muted">
              {getRelativeTime(createdAt)}
            </div>
          </div>
          {renderBody()}
        </div>
        <div className="notification-actions">
          {!is_read && <div className="unread-dot"></div>}
          <div
            className="notification-delete-button icon-button"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(_id);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#212529"
            >
              <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationItem;
