import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import noImage from "../../assets/images/no-photo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { editUser } from "../../../../redux/services/users-service";

const EditUser = (props) => {
  const [currentUser, setCurrentUser] = useState({});
  const [currentUserAuth, setAuth] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setCurrentUser(location.state.user);
  }, [location]);
  const handleUpdate = async() => {
    if(currentUser.email!="" && currentUser.mobile_no!=""){
      setLoading(true)
      
  
      await  editUser({
        _id:currentUser._id,
        first_name:currentUser.first_name,
        last_name:currentUser.last_name,
        email:currentUser.email,
        mobile_no:currentUser.mobile_no,
        isAdmin:currentUser.isAdmin,
      },()=>{
        setLoading(false)
        navigate(-1)
      },()=>{
        setLoading(false)
      })
    }
  };

  const handleInputChange = (phone) => {
    const [countryCode, mobileNumber] = phone.split(" ");
    setCurrentUser({
      ...currentUser,
      countryCode: countryCode,
      mobileNumber: mobileNumber,
    });
  };

  return (
    <Row className="storage-content">
      <Col md={12} className="border-right">
        <Row className="border-bottom mb-3 helpdesk-header bg-white">
          <Button
            className="btn-light border-0 px-4 bg-white"
            onClick={() => navigate(-1)}
          >
            <i className="material-icons">arrow_back</i>
          </Button>
          <h5 className="py-3 pl-4 mb-0">Edit User</h5>
          <div className="user-info-toggler"></div>
        </Row>
        <Container fluid>
          <Form>
            <Row>
              <Col sm="12">
                <p className={"text-uppercase section-header"}>
                  {/* <FormattedMessage id="raised_by" /> */}
                  Personal
                </p>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col sm="12">
                <div className="d-flex py-2">
                  <img
                    className="image-rounded-lg  align-self-center"
                    src={noImage}
                  />
                  <div className="d-flex flex-column justify-content-center">
                    {/* <FormattedMessage id="assigned_to" /> */}
                    <p className="mb-0 font-weight-bold">Profile Photo</p>
                    <Button color="link" className={"px-0 py-0"}>
                      Upload
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                {" "}
                <FormGroup>
                  <Label for="firstname">First Name</Label>
                  <Input
                    type="text"
                    name="firstname"
                    id="firstname"
                    placeholder="Enter First name"
                    value={currentUser.first_name}
                    onChange={(e) =>
                      setCurrentUser({
                        ...currentUser,
                        first_name: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                {" "}
                <FormGroup>
                  <Label for="lastname">Last Name</Label>
                  <Input
                    type="text"
                    name="lastname"
                    id="lastname"
                    placeholder="Enter Last name"
                    value={currentUser.last_name}
                    onChange={(e) =>
                      setCurrentUser({
                        ...currentUser,
                        last_name: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="email address"
                    value={currentUser.email}
                    readOnly
                    onChange={(e) =>
                      setCurrentUser({ ...currentUser, email: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="usernumber">Phone Number</Label>
                  <Input
                    type="number"
                    name="number"
                    id="number"
                    placeholder="Mobile Number"
                    value={currentUser.mobile_no}
                    onChange={(e) =>
                      setCurrentUser({
                        ...currentUser,
                        mobile_no: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="usernumber">Admin User</Label>
                  <Input
                                                    type="select"
                                                    name="selectMulti"
                                                    id="exampleSelectMulti"
                                                    value={currentUser.isAdmin}
                                                    onChange={(e) =>
                                                      setCurrentUser({
                                                        ...currentUser,
                                                        isAdmin: e.target.value,
                                                      })
                                                    }

                                                >
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </Input>
                 
                
                </FormGroup>
              </Col>
            </Row>
            {/* <div className="border-bottom my-3"></div> */}

            <div className="border-bottom my-3"></div>
            <Row className="my-3">
              <Col sm="12">
                <Button color="primary" onClick={() => handleUpdate()}>
                  Save
                </Button>{" "}
              </Col>
            </Row>
          </Form>
        </Container>
      </Col>
    </Row>
  );
};

export default EditUser;
