import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../../layout/routes/routes";
import FolderThumbnailItem from "./cards/folder-thumbnail-item";
import SectionHeader from "./header/section-header";

const FolderThumbnails = ({ folders, onSelect }) => {
  const navigate = useNavigate();
  const storageRoute = routes.find((route) => route.id === "SHARED_STORAGE");

  // Single click handler
  const handleClick = (folder, index, event) => {
    if (onSelect) onSelect(folder, index, event);
  };

  // Double click handler
  const handleDoubleClick = (folder) => {
    if (!folder.is_bin) {
      navigate(
        `${storageRoute.layout + storageRoute.path}?folderId=${folder._id}`
      );
    }
  };

  return folders && folders.length > 0 ? (
    <div className="folder-thumbnails">
      <SectionHeader title="Folders" />
      <div className="grid">
        {folders.map((folder, index) => (
          <FolderThumbnailItem
            folder={folder}
            key={folder._id}
            onClick={(event) => handleClick(folder, index, event)}
            onDoubleClick={() => handleDoubleClick(folder)}
          />
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default FolderThumbnails;
