import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchSources } from "../services/organization-master-service";
import { fetchTags } from "../services/search-service";

const initialState = {
  sources: [],
  tags: [],
};

export const fetchTagsAction = createAsyncThunk(
  "organizationMasterData/fetch-tags",
  async (orgId, { dispatch }) => {
    const tags = await fetchTags(orgId);
    return tags;
  }
);

export const fetchSourcesAction = createAsyncThunk(
  "organizationMasterData/fetch-sources",
  async (orgId, { dispatch }) => {
    const sources = await fetchSources(orgId);
    return sources;
  }
);

const organizationMasterSlice = createSlice({
  name: "organizationMasterData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // ====================
      // Extra Reducers for : fetchTagsAction
      // ====================
      .addCase(fetchTagsAction.fulfilled, (state, action) => {
        state.tags = action.payload;
      })

      // ====================
      // Extra Reducers for : fetchSourcesAction
      // ====================
      .addCase(fetchSourcesAction.fulfilled, (state, action) => {
        state.sources = action.payload;
      });
  },
});

export const getTags = (state) => state.organizationMasterData.tags;
export const getSources = (state) => state.organizationMasterData.sources;
export default organizationMasterSlice.reducer;
