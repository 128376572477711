import React from "react";

const OcrRegionCursor = ({ mousePosition }) => {
  return (
    <>
      <div className="ocr-reader-region-wrapper">
        <div
          className="cursor-line vertical"
          style={{ left: `${mousePosition.x}px` }}
        ></div>
        <div
          className="cursor-line horizontal"
          style={{ top: `${mousePosition.y}px` }}
        ></div>
      </div>
    </>
  );
};

export default OcrRegionCursor;
