import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyState from "../components/common/empty-state";
import FolderContent from "../components/folders/folder-content";
import StorageNavbar from "../components/storage-navbar/storage-navbar";
import {
  fetchTrashContentsAction,
  getTrashFiles,
  getTrashFolders,
} from "../redux/slices/trash-slice";

const TrashView = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const organization = useSelector((state) => state.organization);

  const trashFiles = useSelector(getTrashFiles);
  const trashFolders = useSelector(getTrashFolders);

  useEffect(() => {
    if (organization && organization.id) {
      setIsLoading(true);
      dispatch(fetchTrashContentsAction({ orgId: organization.id }))
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          // ToDo: Error handling
        });
    }
  }, [organization.id]);

  return (
    <>
      <StorageNavbar />
      <FolderContent
        folders={trashFolders}
        files={trashFiles}
        isLoading={isLoading}
        emptyStateComponent={<EmptyState text="Nothing in trash" />}
      />
    </>
  );
};

export default TrashView;
