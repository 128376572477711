import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import noImage from "../../assets/images/no-photo.svg";
import { addUser, editUser } from "../../../../redux/services/users-service";

const NewUser = (props) => {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_no, setPhoneNumber] = useState("");
  const organization = useSelector(state=>state.organization)
  const user = useSelector(state=>state.user)
  const navigate = useNavigate()
  useEffect(() => {}, []);
  const handleAdd = async() => {
    if(email!="" && mobile_no!=""){
      setLoading(true)
      let userData = {
        first_name:firstName,
        last_name:lastName,
        email:email,
        mobile_no:mobile_no,
        password:"123456",
        org_id:organization.id,
        created_by:user.id
      }
  
      await  addUser(userData,()=>{
        setLoading(false)
        navigate(-1)
      },()=>{
        setLoading(false)
      })
    }
    
  };

  return (
    <Row className="storage-content">
      <Col md={12} className="border-right">
        <Row className="border-bottom mb-3 helpdesk-header bg-white">
          <Button
            className="btn-light border-0 px-4 bg-white"
            onClick={() => navigate(-1)}
          >
            <i className="material-icons">arrow_back</i>
          </Button>
          <h5 className="py-3 pl-4 mb-0">Add User</h5>
          <div className="user-info-toggler"></div>
        </Row>
        <Container fluid>
          <Form>
            <Row>
              <Col sm="12 mb-2">
                <span className={"text-uppercase section-header"}>
                  {/* <FormattedMessage id="raised_by" /> */}
                  Personal
                </span>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col sm="12">
                <div className="d-flex py-2">
                  <img
                    className="image-rounded-lg  align-self-center"
                    src={noImage}
                  />
                  <div className="d-flex flex-column justify-content-center">
                    {/* <FormattedMessage id="assigned_to" /> */}
                    <p className="mb-0 font-weight-bold">Profile Photo</p>
                    <Button color="link" className={"px-0 py-0"}>
                      Upload
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                {" "}
                <FormGroup>
                  <Label for="firstname">First Name</Label>
                  <Input
                    type="text"
                    name="firstname"
                    id="firstname"
                    placeholder="Enter First name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                {" "}
                <FormGroup>
                  <Label for="lastname">Last Name</Label>
                  <Input
                    type="text"
                    name="lastname"
                    id="lastname"
                    placeholder="Enter Last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="usernumber">
                    {/* <FormattedMessage id="phone_number" /> */}
                    Mobile No
                  </Label>
                  <Input
                    type="number"
                    name="number"
                    id="number"
                    placeholder="Mobile Number"
                    value={mobile_no}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <div className="border-bottom my-3"></div>
            <Row className="my-3">
              <Col sm="12">
                <Button color="primary" onClick={() => handleAdd()}>
                  Save
                </Button>{" "}
              </Col>
            </Row>
          </Form>
        </Container>
      </Col>
    </Row>
  );
};

export default NewUser;
