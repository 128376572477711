import { createHeaders, handleErrors, parseJSON } from "./api-middleware";
import { API_BASE_URL } from "./base-url";

export const createFolderNote = async (noteData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/notes/create`, {
      method: "POST",
      headers: createHeaders(),
      body: JSON.stringify({
        notes: noteData.notes,
        folder_id: noteData.folderId,
        org_id: noteData.orgId,
      }),
    });

    await handleErrors(response);
    return await parseJSON(response);
  } catch (error) {
    throw error;
  }
};

export const getFolderNote = async (folderId, orgId) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/notes/folder/${folderId}?org_id=${orgId}`,
      {
        method: "GET",
        headers: createHeaders(),
      }
    );

    if (response.status === 404) {
      return null;
    }

    await handleErrors(response);
    return await parseJSON(response);
  } catch (error) {
    throw error;
  }
};

export const updateFolderNote = async (noteData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/notes/update`, {
      method: "PUT",
      headers: createHeaders(),
      body: JSON.stringify({
        note_id: noteData.noteId,
        notes: noteData.notes,
        folder_id: noteData.folderId,
        org_id: noteData.orgId,
      }),
    });

    await handleErrors(response);
    return await parseJSON(response);
  } catch (error) {
    throw error;
  }
};
