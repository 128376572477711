import { createSlice } from "@reduxjs/toolkit";

const ocrReaderSlice = createSlice({
  name: "ocrReader",
  initialState: {
    isOcrReaderActive: false,
    isOcrResultWindowActive: false,
  },
  reducers: {
    toggleOcrReader: (state) => {
      state.isOcrReaderActive = !state.isOcrReaderActive;
    },
    setIsOcrReaderActive: (state, action) => {
      state.isOcrReaderActive = action.payload;
    },
    setIsOcrResultWindowActive: (state, action) => {
      state.isOcrResultWindowActive = action.payload;
    },
  },
});

export const {
  toggleOcrReader,
  setIsOcrReaderActive,
  setIsOcrResultWindowActive,
} = ocrReaderSlice.actions;
export default ocrReaderSlice.reducer;
