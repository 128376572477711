import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { hasNewUnreadNotification } from "../../redux/slices/notification-slice";

const NotificationsButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const organization = useSelector((state) => state.organization);
  const user = useSelector((state) => state.user);
  const hasUnread = useSelector((state) => state.notifications.hasUnread);

  const handleNavigation = () => {
    if (location.pathname !== "/app/notifications") {
      navigate("/app/notifications");
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      dispatch(
        hasNewUnreadNotification({
          orgId: organization.id,
          userId: user.id,
        })
      );
    }, 300000); // 5 Mins

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (organization?.id && user?.id) {
      dispatch(
        hasNewUnreadNotification({
          orgId: organization.id,
          userId: user.id,
        })
      );
    }
  }, [organization?.id, user?.id]);

  return (
    <div
      className="notifications-button icon-button"
      style={{ position: "relative" }}
      onClick={handleNavigation}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="22px"
        viewBox="0 -960 960 960"
        width="22px"
        fill="#212529"
      >
        <path d="M160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z" />
      </svg>

      {hasUnread && (
        <span
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            width: "12px",
            height: "12px",
            backgroundColor: "red",
            borderRadius: "50%",
            border: "2px solid white",
          }}
        ></span>
      )}
    </div>
  );
};

export default NotificationsButton;
